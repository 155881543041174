@charset "UTF-8";
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #f37602;
  --yellow: #facf5a;
  --green: #59cca8;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #23515e;
  --secondary: #f5dc7a;
  --success: #c5edbe;
  --info: #17a2b8;
  --warning: #facf5a;
  --danger: #ff6e7d;
  --light: #f8f9fa;
  --dark: #000000;
  --primary-dark: #1c414b;
  --primary-light: #2a6171;
  --tertiary: #dc917f;
  --notice: #ffe398;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: Barlow;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #2b2b2b;
  text-align: left;
  background-color: #ffffff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #23515e;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #388196;
  text-decoration: none;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: rgba(35, 81, 94, 0.6);
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2rem;
}

h2, .h2 {
  font-size: 1.85rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 3px;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 768px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 992px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 1200px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1400px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xxl,
.col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xxl-first {
    order: -1;
  }

  .order-xxl-last {
    order: 13;
  }

  .order-xxl-0 {
    order: 0;
  }

  .order-xxl-1 {
    order: 1;
  }

  .order-xxl-2 {
    order: 2;
  }

  .order-xxl-3 {
    order: 3;
  }

  .order-xxl-4 {
    order: 4;
  }

  .order-xxl-5 {
    order: 5;
  }

  .order-xxl-6 {
    order: 6;
  }

  .order-xxl-7 {
    order: 7;
  }

  .order-xxl-8 {
    order: 8;
  }

  .order-xxl-9 {
    order: 9;
  }

  .order-xxl-10 {
    order: 10;
  }

  .order-xxl-11 {
    order: 11;
  }

  .order-xxl-12 {
    order: 12;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #2b2b2b;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #2b2b2b;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c1ced2;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #8da5ab;
}

.table-hover .table-primary:hover {
  background-color: #b2c2c7;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b2c2c7;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #fcf5da;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #faedba;
}

.table-hover .table-secondary:hover {
  background-color: #faefc2;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #faefc2;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #effaed;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #e1f6dd;
}

.table-hover .table-success:hover {
  background-color: #ddf4d9;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #ddf4d9;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fef2d1;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fce6a9;
}

.table-hover .table-warning:hover {
  background-color: #fdebb8;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fdebb8;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ffd6db;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ffb4bb;
}

.table-hover .table-danger:hover {
  background-color: #ffbdc5;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #ffbdc5;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #b8b8b8;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #7a7a7a;
}

.table-hover .table-dark:hover {
  background-color: #ababab;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #ababab;
}

.table-primary-dark,
.table-primary-dark > th,
.table-primary-dark > td {
  background-color: #bfcacd;
}
.table-primary-dark th,
.table-primary-dark td,
.table-primary-dark thead th,
.table-primary-dark tbody + tbody {
  border-color: #899ca1;
}

.table-hover .table-primary-dark:hover {
  background-color: #b1bec2;
}
.table-hover .table-primary-dark:hover > td,
.table-hover .table-primary-dark:hover > th {
  background-color: #b1bec2;
}

.table-primary-light,
.table-primary-light > th,
.table-primary-light > td {
  background-color: #c3d3d7;
}
.table-primary-light th,
.table-primary-light td,
.table-primary-light thead th,
.table-primary-light tbody + tbody {
  border-color: #90adb5;
}

.table-hover .table-primary-light:hover {
  background-color: #b4c8cd;
}
.table-hover .table-primary-light:hover > td,
.table-hover .table-primary-light:hover > th {
  background-color: #b4c8cd;
}

.table-tertiary,
.table-tertiary > th,
.table-tertiary > td {
  background-color: #f5e0db;
}
.table-tertiary th,
.table-tertiary td,
.table-tertiary thead th,
.table-tertiary tbody + tbody {
  border-color: #edc6bc;
}

.table-hover .table-tertiary:hover {
  background-color: #efcfc7;
}
.table-hover .table-tertiary:hover > td,
.table-hover .table-tertiary:hover > th {
  background-color: #efcfc7;
}

.table-notice,
.table-notice > th,
.table-notice > td {
  background-color: #fff7e2;
}
.table-notice th,
.table-notice td,
.table-notice thead th,
.table-notice tbody + tbody {
  border-color: #fff0c9;
}

.table-hover .table-notice:hover {
  background-color: #fff0c9;
}
.table-hover .table-notice:hover > td,
.table-hover .table-notice:hover > th {
  background-color: #fff0c9;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #ffffff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #ffffff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: 50px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #2b2b2b;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(35, 81, 94, 0.6);
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #2b2b2b;
}
.form-control:focus {
  color: #2b2b2b;
  background-color: #ffffff;
  border-color: #46a1ba;
  outline: 0;
  box-shadow: none;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #2b2b2b;
  background-color: #ffffff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.6;
  color: #2b2b2b;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 3px;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: rgba(35, 81, 94, 0.6);
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #c5edbe;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.5rem;
  line-height: 1.6;
  color: #212529;
  background-color: rgba(197, 237, 190, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #c5edbe;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23c5edbe' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #c5edbe;
  box-shadow: 0 0 0 0.2rem rgba(197, 237, 190, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #c5edbe;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23c5edbe' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.8em + 0.375rem) calc(0.8em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #c5edbe;
  box-shadow: 0 0 0 0.2rem rgba(197, 237, 190, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #c5edbe;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #c5edbe;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #c5edbe;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #e9f8e6;
  background-color: #e9f8e6;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(197, 237, 190, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #c5edbe;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #c5edbe;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #c5edbe;
  box-shadow: 0 0 0 0.2rem rgba(197, 237, 190, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ff6e7d;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.5rem;
  line-height: 1.6;
  color: #212529;
  background-color: rgba(255, 110, 125, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ff6e7d;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ff6e7d' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff6e7d' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #ff6e7d;
  box-shadow: 0 0 0 0.2rem rgba(255, 110, 125, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #ff6e7d;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ff6e7d' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff6e7d' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.8em + 0.375rem) calc(0.8em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #ff6e7d;
  box-shadow: 0 0 0 0.2rem rgba(255, 110, 125, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ff6e7d;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ff6e7d;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #ff6e7d;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ffa1ab;
  background-color: #ffa1ab;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(255, 110, 125, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ff6e7d;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #ff6e7d;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #ff6e7d;
  box-shadow: 0 0 0 0.2rem rgba(255, 110, 125, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #2b2b2b;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.6;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #2b2b2b;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(35, 81, 94, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #ffffff;
  background-color: #23515e;
  border-color: #23515e;
}
.btn-primary:hover {
  color: #ffffff;
  background-color: #193942;
  border-color: #153139;
}
.btn-primary:focus, .btn-primary.focus {
  color: #ffffff;
  background-color: #193942;
  border-color: #153139;
  box-shadow: 0 0 0 0.2rem rgba(68, 107, 118, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #ffffff;
  background-color: #23515e;
  border-color: #23515e;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #153139;
  border-color: #122930;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 107, 118, 0.5);
}

.btn-secondary {
  color: #212529;
  background-color: #f5dc7a;
  border-color: #f5dc7a;
}
.btn-secondary:hover {
  color: #212529;
  background-color: #f2d356;
  border-color: #f1d04b;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #212529;
  background-color: #f2d356;
  border-color: #f1d04b;
  box-shadow: 0 0 0 0.2rem rgba(213, 193, 110, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #212529;
  background-color: #f5dc7a;
  border-color: #f5dc7a;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #212529;
  background-color: #f1d04b;
  border-color: #f1cc3f;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 193, 110, 0.5);
}

.btn-success {
  color: #212529;
  background-color: #c5edbe;
  border-color: #c5edbe;
}
.btn-success:hover {
  color: #212529;
  background-color: #aae5a0;
  border-color: #a1e296;
}
.btn-success:focus, .btn-success.focus {
  color: #212529;
  background-color: #aae5a0;
  border-color: #a1e296;
  box-shadow: 0 0 0 0.2rem rgba(172, 207, 168, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #212529;
  background-color: #c5edbe;
  border-color: #c5edbe;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #212529;
  background-color: #a1e296;
  border-color: #98df8c;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(172, 207, 168, 0.5);
}

.btn-info {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #ffffff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #ffffff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #ffffff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #facf5a;
  border-color: #facf5a;
}
.btn-warning:hover {
  color: #212529;
  background-color: #f9c435;
  border-color: #f9c129;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #f9c435;
  border-color: #f9c129;
  box-shadow: 0 0 0 0.2rem rgba(217, 182, 83, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #facf5a;
  border-color: #facf5a;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #f9c129;
  border-color: #f8bd1c;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 182, 83, 0.5);
}

.btn-danger {
  color: #212529;
  background-color: #ff6e7d;
  border-color: #ff6e7d;
}
.btn-danger:hover {
  color: #ffffff;
  background-color: #ff485b;
  border-color: #ff3b4f;
}
.btn-danger:focus, .btn-danger.focus {
  color: #ffffff;
  background-color: #ff485b;
  border-color: #ff3b4f;
  box-shadow: 0 0 0 0.2rem rgba(222, 99, 112, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #212529;
  background-color: #ff6e7d;
  border-color: #ff6e7d;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #ff3b4f;
  border-color: #ff2e44;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 99, 112, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-dark:hover {
  color: #ffffff;
  background-color: black;
  border-color: black;
}
.btn-dark:focus, .btn-dark.focus {
  color: #ffffff;
  background-color: black;
  border-color: black;
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: black;
  border-color: black;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-primary-dark {
  color: #ffffff;
  background-color: #1c414b;
  border-color: #1c414b;
}
.btn-primary-dark:hover {
  color: #ffffff;
  background-color: #122930;
  border-color: #0e2126;
}
.btn-primary-dark:focus, .btn-primary-dark.focus {
  color: #ffffff;
  background-color: #122930;
  border-color: #0e2126;
  box-shadow: 0 0 0 0.2rem rgba(62, 94, 102, 0.5);
}
.btn-primary-dark.disabled, .btn-primary-dark:disabled {
  color: #ffffff;
  background-color: #1c414b;
  border-color: #1c414b;
}
.btn-primary-dark:not(:disabled):not(.disabled):active, .btn-primary-dark:not(:disabled):not(.disabled).active, .show > .btn-primary-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #0e2126;
  border-color: #0b191d;
}
.btn-primary-dark:not(:disabled):not(.disabled):active:focus, .btn-primary-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-primary-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(62, 94, 102, 0.5);
}

.btn-primary-light {
  color: #ffffff;
  background-color: #2a6171;
  border-color: #2a6171;
}
.btn-primary-light:hover {
  color: #ffffff;
  background-color: #204955;
  border-color: #1c414b;
}
.btn-primary-light:focus, .btn-primary-light.focus {
  color: #ffffff;
  background-color: #204955;
  border-color: #1c414b;
  box-shadow: 0 0 0 0.2rem rgba(74, 121, 134, 0.5);
}
.btn-primary-light.disabled, .btn-primary-light:disabled {
  color: #ffffff;
  background-color: #2a6171;
  border-color: #2a6171;
}
.btn-primary-light:not(:disabled):not(.disabled):active, .btn-primary-light:not(:disabled):not(.disabled).active, .show > .btn-primary-light.dropdown-toggle {
  color: #ffffff;
  background-color: #1c414b;
  border-color: #193942;
}
.btn-primary-light:not(:disabled):not(.disabled):active:focus, .btn-primary-light:not(:disabled):not(.disabled).active:focus, .show > .btn-primary-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(74, 121, 134, 0.5);
}

.btn-tertiary {
  color: #212529;
  background-color: #dc917f;
  border-color: #dc917f;
}
.btn-tertiary:hover {
  color: #ffffff;
  background-color: #d47761;
  border-color: #d16f57;
}
.btn-tertiary:focus, .btn-tertiary.focus {
  color: #ffffff;
  background-color: #d47761;
  border-color: #d16f57;
  box-shadow: 0 0 0 0.2rem rgba(192, 129, 114, 0.5);
}
.btn-tertiary.disabled, .btn-tertiary:disabled {
  color: #212529;
  background-color: #dc917f;
  border-color: #dc917f;
}
.btn-tertiary:not(:disabled):not(.disabled):active, .btn-tertiary:not(:disabled):not(.disabled).active, .show > .btn-tertiary.dropdown-toggle {
  color: #ffffff;
  background-color: #d16f57;
  border-color: #ce664d;
}
.btn-tertiary:not(:disabled):not(.disabled):active:focus, .btn-tertiary:not(:disabled):not(.disabled).active:focus, .show > .btn-tertiary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(192, 129, 114, 0.5);
}

.btn-notice {
  color: #212529;
  background-color: #ffe398;
  border-color: #ffe398;
}
.btn-notice:hover {
  color: #212529;
  background-color: #ffd972;
  border-color: #ffd565;
}
.btn-notice:focus, .btn-notice.focus {
  color: #212529;
  background-color: #ffd972;
  border-color: #ffd565;
  box-shadow: 0 0 0 0.2rem rgba(222, 199, 135, 0.5);
}
.btn-notice.disabled, .btn-notice:disabled {
  color: #212529;
  background-color: #ffe398;
  border-color: #ffe398;
}
.btn-notice:not(:disabled):not(.disabled):active, .btn-notice:not(:disabled):not(.disabled).active, .show > .btn-notice.dropdown-toggle {
  color: #212529;
  background-color: #ffd565;
  border-color: #ffd258;
}
.btn-notice:not(:disabled):not(.disabled):active:focus, .btn-notice:not(:disabled):not(.disabled).active:focus, .show > .btn-notice.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 199, 135, 0.5);
}

.btn-outline-primary {
  color: #23515e;
  border-color: #23515e;
}
.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #23515e;
  border-color: #23515e;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(35, 81, 94, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #23515e;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #23515e;
  border-color: #23515e;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(35, 81, 94, 0.5);
}

.btn-outline-secondary {
  color: #f5dc7a;
  border-color: #f5dc7a;
}
.btn-outline-secondary:hover {
  color: #212529;
  background-color: #f5dc7a;
  border-color: #f5dc7a;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 220, 122, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #f5dc7a;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #212529;
  background-color: #f5dc7a;
  border-color: #f5dc7a;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 220, 122, 0.5);
}

.btn-outline-success {
  color: #c5edbe;
  border-color: #c5edbe;
}
.btn-outline-success:hover {
  color: #212529;
  background-color: #c5edbe;
  border-color: #c5edbe;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(197, 237, 190, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #c5edbe;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #212529;
  background-color: #c5edbe;
  border-color: #c5edbe;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(197, 237, 190, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #facf5a;
  border-color: #facf5a;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #facf5a;
  border-color: #facf5a;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 207, 90, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #facf5a;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #facf5a;
  border-color: #facf5a;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 207, 90, 0.5);
}

.btn-outline-danger {
  color: #ff6e7d;
  border-color: #ff6e7d;
}
.btn-outline-danger:hover {
  color: #212529;
  background-color: #ff6e7d;
  border-color: #ff6e7d;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 110, 125, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #ff6e7d;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #212529;
  background-color: #ff6e7d;
  border-color: #ff6e7d;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 110, 125, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #000000;
  border-color: #000000;
}
.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #000000;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-primary-dark {
  color: #1c414b;
  border-color: #1c414b;
}
.btn-outline-primary-dark:hover {
  color: #ffffff;
  background-color: #1c414b;
  border-color: #1c414b;
}
.btn-outline-primary-dark:focus, .btn-outline-primary-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 65, 75, 0.5);
}
.btn-outline-primary-dark.disabled, .btn-outline-primary-dark:disabled {
  color: #1c414b;
  background-color: transparent;
}
.btn-outline-primary-dark:not(:disabled):not(.disabled):active, .btn-outline-primary-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-primary-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #1c414b;
  border-color: #1c414b;
}
.btn-outline-primary-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 65, 75, 0.5);
}

.btn-outline-primary-light {
  color: #2a6171;
  border-color: #2a6171;
}
.btn-outline-primary-light:hover {
  color: #ffffff;
  background-color: #2a6171;
  border-color: #2a6171;
}
.btn-outline-primary-light:focus, .btn-outline-primary-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(42, 97, 113, 0.5);
}
.btn-outline-primary-light.disabled, .btn-outline-primary-light:disabled {
  color: #2a6171;
  background-color: transparent;
}
.btn-outline-primary-light:not(:disabled):not(.disabled):active, .btn-outline-primary-light:not(:disabled):not(.disabled).active, .show > .btn-outline-primary-light.dropdown-toggle {
  color: #ffffff;
  background-color: #2a6171;
  border-color: #2a6171;
}
.btn-outline-primary-light:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(42, 97, 113, 0.5);
}

.btn-outline-tertiary {
  color: #dc917f;
  border-color: #dc917f;
}
.btn-outline-tertiary:hover {
  color: #212529;
  background-color: #dc917f;
  border-color: #dc917f;
}
.btn-outline-tertiary:focus, .btn-outline-tertiary.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 145, 127, 0.5);
}
.btn-outline-tertiary.disabled, .btn-outline-tertiary:disabled {
  color: #dc917f;
  background-color: transparent;
}
.btn-outline-tertiary:not(:disabled):not(.disabled):active, .btn-outline-tertiary:not(:disabled):not(.disabled).active, .show > .btn-outline-tertiary.dropdown-toggle {
  color: #212529;
  background-color: #dc917f;
  border-color: #dc917f;
}
.btn-outline-tertiary:not(:disabled):not(.disabled):active:focus, .btn-outline-tertiary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-tertiary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 145, 127, 0.5);
}

.btn-outline-notice {
  color: #ffe398;
  border-color: #ffe398;
}
.btn-outline-notice:hover {
  color: #212529;
  background-color: #ffe398;
  border-color: #ffe398;
}
.btn-outline-notice:focus, .btn-outline-notice.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 227, 152, 0.5);
}
.btn-outline-notice.disabled, .btn-outline-notice:disabled {
  color: #ffe398;
  background-color: transparent;
}
.btn-outline-notice:not(:disabled):not(.disabled):active, .btn-outline-notice:not(:disabled):not(.disabled).active, .show > .btn-outline-notice.dropdown-toggle {
  color: #212529;
  background-color: #ffe398;
  border-color: #ffe398;
}
.btn-outline-notice:not(:disabled):not(.disabled):active:focus, .btn-outline-notice:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-notice.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 227, 152, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #23515e;
  text-decoration: none;
}
.btn-link:hover {
  color: #388196;
  text-decoration: none;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #2b2b2b;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #23515e;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #2b2b2b;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid rgba(35, 81, 94, 0.6);
  border-radius: 3px;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 3px;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.6rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.3rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #23515e;
  background-color: #23515e;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #46a1ba;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #6bb4c8;
  border-color: #6bb4c8;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.3rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #ffffff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.3rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #23515e;
  background-color: #23515e;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(35, 81, 94, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(35, 81, 94, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(35, 81, 94, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.3rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffffff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(35, 81, 94, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: 50px;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #2b2b2b;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid rgba(35, 81, 94, 0.6);
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #46a1ba;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(35, 81, 94, 0.25);
}
.custom-select:focus::-ms-value {
  color: #2b2b2b;
  background-color: #ffffff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #2b2b2b;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 50px;
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 50px;
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #46a1ba;
  box-shadow: none;
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 50px;
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.6;
  color: #2b2b2b;
  background-color: #ffffff;
  border: 1px solid rgba(35, 81, 94, 0.6);
  border-radius: 3px;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.6em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.6;
  color: #2b2b2b;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 3px 3px 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, none;
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, none;
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #ffffff, none;
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #23515e;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #6bb4c8;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #23515e;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #6bb4c8;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #23515e;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #6bb4c8;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ffffff;
  border-color: #dee2e6 #dee2e6 #ffffff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #23515e;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 3px;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1399.98px) {
  .navbar-expand-xxl > .container,
.navbar-expand-xxl > .container-fluid,
.navbar-expand-xxl > .container-sm,
.navbar-expand-xxl > .container-md,
.navbar-expand-xxl > .container-lg,
.navbar-expand-xxl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl > .container,
.navbar-expand-xxl > .container-fluid,
.navbar-expand-xxl > .container-sm,
.navbar-expand-xxl > .container-md,
.navbar-expand-xxl > .container-lg,
.navbar-expand-xxl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: none;
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #ffffff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: none;
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #ffffff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 3px;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.5rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 0.5rem;
}

.card-header {
  padding: 0.5rem 0.5rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: 2px 2px 0 0;
}

.card-footer {
  padding: 0.5rem 0.5rem;
  background-color: transparent;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 2px 2px;
}

.card-header-tabs {
  margin-right: -0.25rem;
  margin-bottom: -0.5rem;
  margin-left: -0.25rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.25rem;
  margin-left: -0.25rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 2px;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.card-deck .card {
  margin-bottom: 10px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 10px;
    margin-bottom: 0;
    margin-left: 10px;
  }
}

.card-group > .card {
  margin-bottom: 10px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.5rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #23515e;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #388196;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(35, 81, 94, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #23515e;
  border-color: #23515e;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #ffffff;
  background-color: #23515e;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #ffffff;
  background-color: #153139;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(35, 81, 94, 0.5);
}

.badge-secondary {
  color: #212529;
  background-color: #f5dc7a;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #212529;
  background-color: #f1d04b;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(245, 220, 122, 0.5);
}

.badge-success {
  color: #212529;
  background-color: #c5edbe;
}
a.badge-success:hover, a.badge-success:focus {
  color: #212529;
  background-color: #a1e296;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(197, 237, 190, 0.5);
}

.badge-info {
  color: #ffffff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #ffffff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #facf5a;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #f9c129;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(250, 207, 90, 0.5);
}

.badge-danger {
  color: #212529;
  background-color: #ff6e7d;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #212529;
  background-color: #ff3b4f;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 110, 125, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #ffffff;
  background-color: #000000;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #ffffff;
  background-color: black;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.badge-primary-dark {
  color: #ffffff;
  background-color: #1c414b;
}
a.badge-primary-dark:hover, a.badge-primary-dark:focus {
  color: #ffffff;
  background-color: #0e2126;
}
a.badge-primary-dark:focus, a.badge-primary-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(28, 65, 75, 0.5);
}

.badge-primary-light {
  color: #ffffff;
  background-color: #2a6171;
}
a.badge-primary-light:hover, a.badge-primary-light:focus {
  color: #ffffff;
  background-color: #1c414b;
}
a.badge-primary-light:focus, a.badge-primary-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(42, 97, 113, 0.5);
}

.badge-tertiary {
  color: #212529;
  background-color: #dc917f;
}
a.badge-tertiary:hover, a.badge-tertiary:focus {
  color: #212529;
  background-color: #d16f57;
}
a.badge-tertiary:focus, a.badge-tertiary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 145, 127, 0.5);
}

.badge-notice {
  color: #212529;
  background-color: #ffe398;
}
a.badge-notice:hover, a.badge-notice:focus {
  color: #212529;
  background-color: #ffd565;
}
a.badge-notice:focus, a.badge-notice.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 227, 152, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #122a31;
  background-color: #d3dcdf;
  border-color: #c1ced2;
}
.alert-primary hr {
  border-top-color: #b2c2c7;
}
.alert-primary .alert-link {
  color: #040a0c;
}

.alert-secondary {
  color: #7f723f;
  background-color: #fdf8e4;
  border-color: #fcf5da;
}
.alert-secondary hr {
  border-top-color: #faefc2;
}
.alert-secondary .alert-link {
  color: #5d532e;
}

.alert-success {
  color: #667b63;
  background-color: #f3fbf2;
  border-color: #effaed;
}
.alert-success hr {
  border-top-color: #ddf4d9;
}
.alert-success .alert-link {
  color: #4f5f4c;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #826c2f;
  background-color: #fef5de;
  border-color: #fef2d1;
}
.alert-warning hr {
  border-top-color: #fdebb8;
}
.alert-warning .alert-link {
  color: #5d4d21;
}

.alert-danger {
  color: #853941;
  background-color: #ffe2e5;
  border-color: #ffd6db;
}
.alert-danger hr {
  border-top-color: #ffbdc5;
}
.alert-danger .alert-link {
  color: #612a30;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: black;
  background-color: #cccccc;
  border-color: #b8b8b8;
}
.alert-dark hr {
  border-top-color: #ababab;
}
.alert-dark .alert-link {
  color: black;
}

.alert-primary-dark {
  color: #0f2227;
  background-color: #d2d9db;
  border-color: #bfcacd;
}
.alert-primary-dark hr {
  border-top-color: #b1bec2;
}
.alert-primary-dark .alert-link {
  color: #010202;
}

.alert-primary-light {
  color: #16323b;
  background-color: #d4dfe3;
  border-color: #c3d3d7;
}
.alert-primary-light hr {
  border-top-color: #b4c8cd;
}
.alert-primary-light .alert-link {
  color: #081316;
}

.alert-tertiary {
  color: #724b42;
  background-color: #f8e9e5;
  border-color: #f5e0db;
}
.alert-tertiary hr {
  border-top-color: #efcfc7;
}
.alert-tertiary .alert-link {
  color: #52362f;
}

.alert-notice {
  color: #85764f;
  background-color: #fff9ea;
  border-color: #fff7e2;
}
.alert-notice hr {
  border-top-color: #fff0c9;
}
.alert-notice .alert-link {
  color: #655a3c;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #23515e;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #2b2b2b;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
}
.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #23515e;
  border-color: #23515e;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #122a31;
  background-color: #c1ced2;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #122a31;
  background-color: #b2c2c7;
}
.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #122a31;
  border-color: #122a31;
}

.list-group-item-secondary {
  color: #7f723f;
  background-color: #fcf5da;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #7f723f;
  background-color: #faefc2;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #7f723f;
  border-color: #7f723f;
}

.list-group-item-success {
  color: #667b63;
  background-color: #effaed;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #667b63;
  background-color: #ddf4d9;
}
.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #667b63;
  border-color: #667b63;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #826c2f;
  background-color: #fef2d1;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #826c2f;
  background-color: #fdebb8;
}
.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #826c2f;
  border-color: #826c2f;
}

.list-group-item-danger {
  color: #853941;
  background-color: #ffd6db;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #853941;
  background-color: #ffbdc5;
}
.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #853941;
  border-color: #853941;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: black;
  background-color: #b8b8b8;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: black;
  background-color: #ababab;
}
.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: black;
  border-color: black;
}

.list-group-item-primary-dark {
  color: #0f2227;
  background-color: #bfcacd;
}
.list-group-item-primary-dark.list-group-item-action:hover, .list-group-item-primary-dark.list-group-item-action:focus {
  color: #0f2227;
  background-color: #b1bec2;
}
.list-group-item-primary-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #0f2227;
  border-color: #0f2227;
}

.list-group-item-primary-light {
  color: #16323b;
  background-color: #c3d3d7;
}
.list-group-item-primary-light.list-group-item-action:hover, .list-group-item-primary-light.list-group-item-action:focus {
  color: #16323b;
  background-color: #b4c8cd;
}
.list-group-item-primary-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #16323b;
  border-color: #16323b;
}

.list-group-item-tertiary {
  color: #724b42;
  background-color: #f5e0db;
}
.list-group-item-tertiary.list-group-item-action:hover, .list-group-item-tertiary.list-group-item-action:focus {
  color: #724b42;
  background-color: #efcfc7;
}
.list-group-item-tertiary.list-group-item-action.active {
  color: #ffffff;
  background-color: #724b42;
  border-color: #724b42;
}

.list-group-item-notice {
  color: #85764f;
  background-color: #fff7e2;
}
.list-group-item-notice.list-group-item-action:hover, .list-group-item-notice.list-group-item-action:focus {
  color: #85764f;
  background-color: #fff0c9;
}
.list-group-item-notice.list-group-item-action.active {
  color: #ffffff;
  background-color: #85764f;
  border-color: #85764f;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.5;
}
.close:hover {
  color: #000000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.6;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: Barlow;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.5rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: Barlow;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #2b2b2b;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #23515e !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #153139 !important;
}

.bg-secondary {
  background-color: #f5dc7a !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #f1d04b !important;
}

.bg-success {
  background-color: #c5edbe !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #a1e296 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #facf5a !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #f9c129 !important;
}

.bg-danger {
  background-color: #ff6e7d !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ff3b4f !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #000000 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: black !important;
}

.bg-primary-dark {
  background-color: #1c414b !important;
}

a.bg-primary-dark:hover, a.bg-primary-dark:focus,
button.bg-primary-dark:hover,
button.bg-primary-dark:focus {
  background-color: #0e2126 !important;
}

.bg-primary-light {
  background-color: #2a6171 !important;
}

a.bg-primary-light:hover, a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
  background-color: #1c414b !important;
}

.bg-tertiary {
  background-color: #dc917f !important;
}

a.bg-tertiary:hover, a.bg-tertiary:focus,
button.bg-tertiary:hover,
button.bg-tertiary:focus {
  background-color: #d16f57 !important;
}

.bg-notice {
  background-color: #ffe398 !important;
}

a.bg-notice:hover, a.bg-notice:focus,
button.bg-notice:hover,
button.bg-notice:focus {
  background-color: #ffd565 !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #23515e !important;
}

.border-secondary {
  border-color: #f5dc7a !important;
}

.border-success {
  border-color: #c5edbe !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #facf5a !important;
}

.border-danger {
  border-color: #ff6e7d !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #000000 !important;
}

.border-primary-dark {
  border-color: #1c414b !important;
}

.border-primary-light {
  border-color: #2a6171 !important;
}

.border-tertiary {
  border-color: #dc917f !important;
}

.border-notice {
  border-color: #ffe398 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded-sm {
  border-radius: 3px !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0 !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-none {
    display: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1400px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-left {
    float: left !important;
  }

  .float-xxl-right {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1400px) {
  .m-xxl-0 {
    margin: 0 !important;
  }

  .mt-xxl-0,
.my-xxl-0 {
    margin-top: 0 !important;
  }

  .mr-xxl-0,
.mx-xxl-0 {
    margin-right: 0 !important;
  }

  .mb-xxl-0,
.my-xxl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xxl-0,
.mx-xxl-0 {
    margin-left: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .mt-xxl-1,
.my-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xxl-1,
.mx-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xxl-1,
.my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xxl-1,
.mx-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .mt-xxl-2,
.my-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xxl-2,
.mx-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xxl-2,
.my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xxl-2,
.mx-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .mt-xxl-3,
.my-xxl-3 {
    margin-top: 1rem !important;
  }

  .mr-xxl-3,
.mx-xxl-3 {
    margin-right: 1rem !important;
  }

  .mb-xxl-3,
.my-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xxl-3,
.mx-xxl-3 {
    margin-left: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .mt-xxl-4,
.my-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xxl-4,
.mx-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xxl-4,
.my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xxl-4,
.mx-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .mt-xxl-5,
.my-xxl-5 {
    margin-top: 3rem !important;
  }

  .mr-xxl-5,
.mx-xxl-5 {
    margin-right: 3rem !important;
  }

  .mb-xxl-5,
.my-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xxl-5,
.mx-xxl-5 {
    margin-left: 3rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .pt-xxl-0,
.py-xxl-0 {
    padding-top: 0 !important;
  }

  .pr-xxl-0,
.px-xxl-0 {
    padding-right: 0 !important;
  }

  .pb-xxl-0,
.py-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xxl-0,
.px-xxl-0 {
    padding-left: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .pt-xxl-1,
.py-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xxl-1,
.px-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xxl-1,
.py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xxl-1,
.px-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .pt-xxl-2,
.py-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xxl-2,
.px-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xxl-2,
.py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xxl-2,
.px-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .pt-xxl-3,
.py-xxl-3 {
    padding-top: 1rem !important;
  }

  .pr-xxl-3,
.px-xxl-3 {
    padding-right: 1rem !important;
  }

  .pb-xxl-3,
.py-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xxl-3,
.px-xxl-3 {
    padding-left: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .pt-xxl-4,
.py-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xxl-4,
.px-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xxl-4,
.py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xxl-4,
.px-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .pt-xxl-5,
.py-xxl-5 {
    padding-top: 3rem !important;
  }

  .pr-xxl-5,
.px-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-5,
.py-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xxl-5,
.px-xxl-5 {
    padding-left: 3rem !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xxl-n1,
.my-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xxl-n1,
.mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xxl-n1,
.my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xxl-n1,
.mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xxl-n2,
.my-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xxl-n2,
.mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xxl-n2,
.my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xxl-n2,
.mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .mt-xxl-n3,
.my-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xxl-n3,
.mx-xxl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xxl-n3,
.my-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xxl-n3,
.mx-xxl-n3 {
    margin-left: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xxl-n4,
.my-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xxl-n4,
.mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xxl-n4,
.my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xxl-n4,
.mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .mt-xxl-n5,
.my-xxl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xxl-n5,
.mx-xxl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xxl-n5,
.my-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xxl-n5,
.mx-xxl-n5 {
    margin-left: -3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mt-xxl-auto,
.my-xxl-auto {
    margin-top: auto !important;
  }

  .mr-xxl-auto,
.mx-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-auto,
.my-xxl-auto {
    margin-bottom: auto !important;
  }

  .ml-xxl-auto,
.mx-xxl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .text-xxl-left {
    text-align: left !important;
  }

  .text-xxl-right {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #23515e !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0e2126 !important;
}

.text-secondary {
  color: #f5dc7a !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #f0c933 !important;
}

.text-success {
  color: #c5edbe !important;
}

a.text-success:hover, a.text-success:focus {
  color: #90dc82 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #facf5a !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #f8b910 !important;
}

.text-danger {
  color: #ff6e7d !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #ff2238 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #000000 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: black !important;
}

.text-primary-dark {
  color: #1c414b !important;
}

a.text-primary-dark:hover, a.text-primary-dark:focus {
  color: #071114 !important;
}

.text-primary-light {
  color: #2a6171 !important;
}

a.text-primary-light:hover, a.text-primary-light:focus {
  color: #153139 !important;
}

.text-tertiary {
  color: #dc917f !important;
}

a.text-tertiary:hover, a.text-tertiary:focus {
  color: #cc5d43 !important;
}

.text-notice {
  color: #ffe398 !important;
}

a.text-notice:hover, a.text-notice:focus {
  color: #ffce4c !important;
}

.text-body {
  color: #2b2b2b !important;
}

.text-muted {
  color: rgba(35, 81, 94, 0.6) !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #ffffff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
body a {
  transition: color 0.15s;
  color: #23515e;
}
body a:hover {
  color: #388196;
}
body a label {
  cursor: pointer;
}
body a h2,
body a h3,
body a h4,
body a h5 {
  color: #23515e;
}

strong {
  font-weight: 800;
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("/images/expand_more_icon.svg");
  background-position: 98% 50%;
  background-repeat: no-repeat;
  background-size: 20px;
}

button.close {
  opacity: 1;
}

@font-face {
  font-family: "Barlow";
  font-display: block;
  font-style: normal;
  font-weight: 400;
  src: url("~@assets/fonts/barlow/barlow-regular-webfont.eot?") format("eot"), url("~@assets/fonts/barlow/barlow-regular-webfont.woff2") format("woff2"), url("~@assets/fonts/barlow/barlow-regular-webfont.woff") format("woff"), url("~@assets/fonts/barlow/barlow-regular-webfont.ttf") format("truetype"), url("~@assets/fonts/barlow/barlow-regular-webfont.svg#Barlow") format("svg");
}
@font-face {
  font-family: "Barlow";
  font-display: block;
  font-style: normal;
  font-weight: 800;
  src: url("~@assets/fonts/barlow/barlow-bold-webfont.eot?") format("eot"), url("~@assets/fonts/barlow/barlow-bold-webfont.woff2") format("woff2"), url("~@assets/fonts/barlow/barlow-bold-webfont.woff") format("woff"), url("~@assets/fonts/barlow/barlow-bold-webfont.ttf") format("truetype"), url("~@assets/fonts/barlow/barlow-bold-webfont.svg#Barlow") format("svg");
}
@font-face {
  font-family: "Barlow";
  font-display: block;
  font-style: italic;
  font-weight: 400;
  src: url("~@assets/fonts/barlow/barlow-italic-webfont.eot?") format("eot"), url("~@assets/fonts/barlow/barlow-italic-webfont.woff2") format("woff2"), url("~@assets/fonts/barlow/barlow-italic-webfont.woff") format("woff"), url("~@assets/fonts/barlow/barlow-italic-webfont.ttf") format("truetype"), url("~@assets/fonts/barlow/barlow-italic-webfont.svg#Barlow") format("svg");
}
/*!
 * Font Awesome Pro 5.14.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
.fa,
.fas,
.far,
.fal,
.fad,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.fa-lg {
  font-size: 1.3333333333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.fa-xs {
  font-size: 0.75em;
}

.fa-sm {
  font-size: 0.875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}

.fa-border {
  border: solid 0.08em #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: 0.3em;
}
.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: 0.3em;
}

.fa-spin {
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1);
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  filter: none;
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "";
}

.fa-abacus:before {
  content: "";
}

.fa-accessible-icon:before {
  content: "";
}

.fa-accusoft:before {
  content: "";
}

.fa-acorn:before {
  content: "";
}

.fa-acquisitions-incorporated:before {
  content: "";
}

.fa-ad:before {
  content: "";
}

.fa-address-book:before {
  content: "";
}

.fa-address-card:before {
  content: "";
}

.fa-adjust:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-adobe:before {
  content: "";
}

.fa-adversal:before {
  content: "";
}

.fa-affiliatetheme:before {
  content: "";
}

.fa-air-conditioner:before {
  content: "";
}

.fa-air-freshener:before {
  content: "";
}

.fa-airbnb:before {
  content: "";
}

.fa-alarm-clock:before {
  content: "";
}

.fa-alarm-exclamation:before {
  content: "";
}

.fa-alarm-plus:before {
  content: "";
}

.fa-alarm-snooze:before {
  content: "";
}

.fa-album:before {
  content: "";
}

.fa-album-collection:before {
  content: "";
}

.fa-algolia:before {
  content: "";
}

.fa-alicorn:before {
  content: "";
}

.fa-alien:before {
  content: "";
}

.fa-alien-monster:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-align-slash:before {
  content: "";
}

.fa-alipay:before {
  content: "";
}

.fa-allergies:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-amazon-pay:before {
  content: "";
}

.fa-ambulance:before {
  content: "";
}

.fa-american-sign-language-interpreting:before {
  content: "";
}

.fa-amilia:before {
  content: "";
}

.fa-amp-guitar:before {
  content: "";
}

.fa-analytics:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-angel:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-angle-double-down:before {
  content: "";
}

.fa-angle-double-left:before {
  content: "";
}

.fa-angle-double-right:before {
  content: "";
}

.fa-angle-double-up:before {
  content: "";
}

.fa-angle-down:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-angry:before {
  content: "";
}

.fa-angrycreative:before {
  content: "";
}

.fa-angular:before {
  content: "";
}

.fa-ankh:before {
  content: "";
}

.fa-app-store:before {
  content: "";
}

.fa-app-store-ios:before {
  content: "";
}

.fa-apper:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-apple-alt:before {
  content: "";
}

.fa-apple-crate:before {
  content: "";
}

.fa-apple-pay:before {
  content: "";
}

.fa-archive:before {
  content: "";
}

.fa-archway:before {
  content: "";
}

.fa-arrow-alt-circle-down:before {
  content: "";
}

.fa-arrow-alt-circle-left:before {
  content: "";
}

.fa-arrow-alt-circle-right:before {
  content: "";
}

.fa-arrow-alt-circle-up:before {
  content: "";
}

.fa-arrow-alt-down:before {
  content: "";
}

.fa-arrow-alt-from-bottom:before {
  content: "";
}

.fa-arrow-alt-from-left:before {
  content: "";
}

.fa-arrow-alt-from-right:before {
  content: "";
}

.fa-arrow-alt-from-top:before {
  content: "";
}

.fa-arrow-alt-left:before {
  content: "";
}

.fa-arrow-alt-right:before {
  content: "";
}

.fa-arrow-alt-square-down:before {
  content: "";
}

.fa-arrow-alt-square-left:before {
  content: "";
}

.fa-arrow-alt-square-right:before {
  content: "";
}

.fa-arrow-alt-square-up:before {
  content: "";
}

.fa-arrow-alt-to-bottom:before {
  content: "";
}

.fa-arrow-alt-to-left:before {
  content: "";
}

.fa-arrow-alt-to-right:before {
  content: "";
}

.fa-arrow-alt-to-top:before {
  content: "";
}

.fa-arrow-alt-up:before {
  content: "";
}

.fa-arrow-circle-down:before {
  content: "";
}

.fa-arrow-circle-left:before {
  content: "";
}

.fa-arrow-circle-right:before {
  content: "";
}

.fa-arrow-circle-up:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-arrow-from-bottom:before {
  content: "";
}

.fa-arrow-from-left:before {
  content: "";
}

.fa-arrow-from-right:before {
  content: "";
}

.fa-arrow-from-top:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-arrow-square-down:before {
  content: "";
}

.fa-arrow-square-left:before {
  content: "";
}

.fa-arrow-square-right:before {
  content: "";
}

.fa-arrow-square-up:before {
  content: "";
}

.fa-arrow-to-bottom:before {
  content: "";
}

.fa-arrow-to-left:before {
  content: "";
}

.fa-arrow-to-right:before {
  content: "";
}

.fa-arrow-to-top:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-arrows:before {
  content: "";
}

.fa-arrows-alt:before {
  content: "";
}

.fa-arrows-alt-h:before {
  content: "";
}

.fa-arrows-alt-v:before {
  content: "";
}

.fa-arrows-h:before {
  content: "";
}

.fa-arrows-v:before {
  content: "";
}

.fa-artstation:before {
  content: "";
}

.fa-assistive-listening-systems:before {
  content: "";
}

.fa-asterisk:before {
  content: "";
}

.fa-asymmetrik:before {
  content: "";
}

.fa-at:before {
  content: "";
}

.fa-atlas:before {
  content: "";
}

.fa-atlassian:before {
  content: "";
}

.fa-atom:before {
  content: "";
}

.fa-atom-alt:before {
  content: "";
}

.fa-audible:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-autoprefixer:before {
  content: "";
}

.fa-avianex:before {
  content: "";
}

.fa-aviato:before {
  content: "";
}

.fa-award:before {
  content: "";
}

.fa-aws:before {
  content: "";
}

.fa-axe:before {
  content: "";
}

.fa-axe-battle:before {
  content: "";
}

.fa-baby:before {
  content: "";
}

.fa-baby-carriage:before {
  content: "";
}

.fa-backpack:before {
  content: "";
}

.fa-backspace:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-bacon:before {
  content: "";
}

.fa-bacteria:before {
  content: "";
}

.fa-bacterium:before {
  content: "";
}

.fa-badge:before {
  content: "";
}

.fa-badge-check:before {
  content: "";
}

.fa-badge-dollar:before {
  content: "";
}

.fa-badge-percent:before {
  content: "";
}

.fa-badge-sheriff:before {
  content: "";
}

.fa-badger-honey:before {
  content: "";
}

.fa-bags-shopping:before {
  content: "";
}

.fa-bahai:before {
  content: "";
}

.fa-balance-scale:before {
  content: "";
}

.fa-balance-scale-left:before {
  content: "";
}

.fa-balance-scale-right:before {
  content: "";
}

.fa-ball-pile:before {
  content: "";
}

.fa-ballot:before {
  content: "";
}

.fa-ballot-check:before {
  content: "";
}

.fa-ban:before {
  content: "";
}

.fa-band-aid:before {
  content: "";
}

.fa-bandcamp:before {
  content: "";
}

.fa-banjo:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-barcode-alt:before {
  content: "";
}

.fa-barcode-read:before {
  content: "";
}

.fa-barcode-scan:before {
  content: "";
}

.fa-bars:before {
  content: "";
}

.fa-baseball:before {
  content: "";
}

.fa-baseball-ball:before {
  content: "";
}

.fa-basketball-ball:before {
  content: "";
}

.fa-basketball-hoop:before {
  content: "";
}

.fa-bat:before {
  content: "";
}

.fa-bath:before {
  content: "";
}

.fa-battery-bolt:before {
  content: "";
}

.fa-battery-empty:before {
  content: "";
}

.fa-battery-full:before {
  content: "";
}

.fa-battery-half:before {
  content: "";
}

.fa-battery-quarter:before {
  content: "";
}

.fa-battery-slash:before {
  content: "";
}

.fa-battery-three-quarters:before {
  content: "";
}

.fa-battle-net:before {
  content: "";
}

.fa-bed:before {
  content: "";
}

.fa-bed-alt:before {
  content: "";
}

.fa-bed-bunk:before {
  content: "";
}

.fa-bed-empty:before {
  content: "";
}

.fa-beer:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-behance-square:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-bell-exclamation:before {
  content: "";
}

.fa-bell-on:before {
  content: "";
}

.fa-bell-plus:before {
  content: "";
}

.fa-bell-school:before {
  content: "";
}

.fa-bell-school-slash:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-bells:before {
  content: "";
}

.fa-betamax:before {
  content: "";
}

.fa-bezier-curve:before {
  content: "";
}

.fa-bible:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-biking:before {
  content: "";
}

.fa-biking-mountain:before {
  content: "";
}

.fa-bimobject:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-biohazard:before {
  content: "";
}

.fa-birthday-cake:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-bitcoin:before {
  content: "";
}

.fa-bity:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-blackberry:before {
  content: "";
}

.fa-blanket:before {
  content: "";
}

.fa-blender:before {
  content: "";
}

.fa-blender-phone:before {
  content: "";
}

.fa-blind:before {
  content: "";
}

.fa-blinds:before {
  content: "";
}

.fa-blinds-open:before {
  content: "";
}

.fa-blinds-raised:before {
  content: "";
}

.fa-blog:before {
  content: "";
}

.fa-blogger:before {
  content: "";
}

.fa-blogger-b:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-bolt:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-bone:before {
  content: "";
}

.fa-bone-break:before {
  content: "";
}

.fa-bong:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-book-alt:before {
  content: "";
}

.fa-book-dead:before {
  content: "";
}

.fa-book-heart:before {
  content: "";
}

.fa-book-medical:before {
  content: "";
}

.fa-book-open:before {
  content: "";
}

.fa-book-reader:before {
  content: "";
}

.fa-book-spells:before {
  content: "";
}

.fa-book-user:before {
  content: "";
}

.fa-bookmark:before {
  content: "";
}

.fa-books:before {
  content: "";
}

.fa-books-medical:before {
  content: "";
}

.fa-boombox:before {
  content: "";
}

.fa-boot:before {
  content: "";
}

.fa-booth-curtain:before {
  content: "";
}

.fa-bootstrap:before {
  content: "";
}

.fa-border-all:before {
  content: "";
}

.fa-border-bottom:before {
  content: "";
}

.fa-border-center-h:before {
  content: "";
}

.fa-border-center-v:before {
  content: "";
}

.fa-border-inner:before {
  content: "";
}

.fa-border-left:before {
  content: "";
}

.fa-border-none:before {
  content: "";
}

.fa-border-outer:before {
  content: "";
}

.fa-border-right:before {
  content: "";
}

.fa-border-style:before {
  content: "";
}

.fa-border-style-alt:before {
  content: "";
}

.fa-border-top:before {
  content: "";
}

.fa-bow-arrow:before {
  content: "";
}

.fa-bowling-ball:before {
  content: "";
}

.fa-bowling-pins:before {
  content: "";
}

.fa-box:before {
  content: "";
}

.fa-box-alt:before {
  content: "";
}

.fa-box-ballot:before {
  content: "";
}

.fa-box-check:before {
  content: "";
}

.fa-box-fragile:before {
  content: "";
}

.fa-box-full:before {
  content: "";
}

.fa-box-heart:before {
  content: "";
}

.fa-box-open:before {
  content: "";
}

.fa-box-tissue:before {
  content: "";
}

.fa-box-up:before {
  content: "";
}

.fa-box-usd:before {
  content: "";
}

.fa-boxes:before {
  content: "";
}

.fa-boxes-alt:before {
  content: "";
}

.fa-boxing-glove:before {
  content: "";
}

.fa-brackets:before {
  content: "";
}

.fa-brackets-curly:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-brain:before {
  content: "";
}

.fa-bread-loaf:before {
  content: "";
}

.fa-bread-slice:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-briefcase-medical:before {
  content: "";
}

.fa-bring-forward:before {
  content: "";
}

.fa-bring-front:before {
  content: "";
}

.fa-broadcast-tower:before {
  content: "";
}

.fa-broom:before {
  content: "";
}

.fa-browser:before {
  content: "";
}

.fa-brush:before {
  content: "";
}

.fa-btc:before {
  content: "";
}

.fa-buffer:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-bullseye-arrow:before {
  content: "";
}

.fa-bullseye-pointer:before {
  content: "";
}

.fa-burger-soda:before {
  content: "";
}

.fa-burn:before {
  content: "";
}

.fa-buromobelexperte:before {
  content: "";
}

.fa-burrito:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-bus-alt:before {
  content: "";
}

.fa-bus-school:before {
  content: "";
}

.fa-business-time:before {
  content: "";
}

.fa-buy-n-large:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-cabinet-filing:before {
  content: "";
}

.fa-cactus:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-calculator-alt:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-calendar-alt:before {
  content: "";
}

.fa-calendar-check:before {
  content: "";
}

.fa-calendar-day:before {
  content: "";
}

.fa-calendar-edit:before {
  content: "";
}

.fa-calendar-exclamation:before {
  content: "";
}

.fa-calendar-minus:before {
  content: "";
}

.fa-calendar-plus:before {
  content: "";
}

.fa-calendar-star:before {
  content: "";
}

.fa-calendar-times:before {
  content: "";
}

.fa-calendar-week:before {
  content: "";
}

.fa-camcorder:before {
  content: "";
}

.fa-camera:before {
  content: "";
}

.fa-camera-alt:before {
  content: "";
}

.fa-camera-home:before {
  content: "";
}

.fa-camera-movie:before {
  content: "";
}

.fa-camera-polaroid:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-campfire:before {
  content: "";
}

.fa-campground:before {
  content: "";
}

.fa-canadian-maple-leaf:before {
  content: "";
}

.fa-candle-holder:before {
  content: "";
}

.fa-candy-cane:before {
  content: "";
}

.fa-candy-corn:before {
  content: "";
}

.fa-cannabis:before {
  content: "";
}

.fa-capsules:before {
  content: "";
}

.fa-car:before {
  content: "";
}

.fa-car-alt:before {
  content: "";
}

.fa-car-battery:before {
  content: "";
}

.fa-car-building:before {
  content: "";
}

.fa-car-bump:before {
  content: "";
}

.fa-car-bus:before {
  content: "";
}

.fa-car-crash:before {
  content: "";
}

.fa-car-garage:before {
  content: "";
}

.fa-car-mechanic:before {
  content: "";
}

.fa-car-side:before {
  content: "";
}

.fa-car-tilt:before {
  content: "";
}

.fa-car-wash:before {
  content: "";
}

.fa-caravan:before {
  content: "";
}

.fa-caravan-alt:before {
  content: "";
}

.fa-caret-circle-down:before {
  content: "";
}

.fa-caret-circle-left:before {
  content: "";
}

.fa-caret-circle-right:before {
  content: "";
}

.fa-caret-circle-up:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-caret-square-down:before {
  content: "";
}

.fa-caret-square-left:before {
  content: "";
}

.fa-caret-square-right:before {
  content: "";
}

.fa-caret-square-up:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-carrot:before {
  content: "";
}

.fa-cars:before {
  content: "";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-cash-register:before {
  content: "";
}

.fa-cassette-tape:before {
  content: "";
}

.fa-cat:before {
  content: "";
}

.fa-cat-space:before {
  content: "";
}

.fa-cauldron:before {
  content: "";
}

.fa-cc-amazon-pay:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-cc-apple-pay:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-cctv:before {
  content: "";
}

.fa-centercode:before {
  content: "";
}

.fa-centos:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-chair:before {
  content: "";
}

.fa-chair-office:before {
  content: "";
}

.fa-chalkboard:before {
  content: "";
}

.fa-chalkboard-teacher:before {
  content: "";
}

.fa-charging-station:before {
  content: "";
}

.fa-chart-area:before {
  content: "";
}

.fa-chart-bar:before {
  content: "";
}

.fa-chart-line:before {
  content: "";
}

.fa-chart-line-down:before {
  content: "";
}

.fa-chart-network:before {
  content: "";
}

.fa-chart-pie:before {
  content: "";
}

.fa-chart-pie-alt:before {
  content: "";
}

.fa-chart-scatter:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-check-circle:before {
  content: "";
}

.fa-check-double:before {
  content: "";
}

.fa-check-square:before {
  content: "";
}

.fa-cheese:before {
  content: "";
}

.fa-cheese-swiss:before {
  content: "";
}

.fa-cheeseburger:before {
  content: "";
}

.fa-chess:before {
  content: "";
}

.fa-chess-bishop:before {
  content: "";
}

.fa-chess-bishop-alt:before {
  content: "";
}

.fa-chess-board:before {
  content: "";
}

.fa-chess-clock:before {
  content: "";
}

.fa-chess-clock-alt:before {
  content: "";
}

.fa-chess-king:before {
  content: "";
}

.fa-chess-king-alt:before {
  content: "";
}

.fa-chess-knight:before {
  content: "";
}

.fa-chess-knight-alt:before {
  content: "";
}

.fa-chess-pawn:before {
  content: "";
}

.fa-chess-pawn-alt:before {
  content: "";
}

.fa-chess-queen:before {
  content: "";
}

.fa-chess-queen-alt:before {
  content: "";
}

.fa-chess-rook:before {
  content: "";
}

.fa-chess-rook-alt:before {
  content: "";
}

.fa-chevron-circle-down:before {
  content: "";
}

.fa-chevron-circle-left:before {
  content: "";
}

.fa-chevron-circle-right:before {
  content: "";
}

.fa-chevron-circle-up:before {
  content: "";
}

.fa-chevron-double-down:before {
  content: "";
}

.fa-chevron-double-left:before {
  content: "";
}

.fa-chevron-double-right:before {
  content: "";
}

.fa-chevron-double-up:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-chevron-square-down:before {
  content: "";
}

.fa-chevron-square-left:before {
  content: "";
}

.fa-chevron-square-right:before {
  content: "";
}

.fa-chevron-square-up:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-chimney:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-chromecast:before {
  content: "";
}

.fa-church:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-circle-notch:before {
  content: "";
}

.fa-city:before {
  content: "";
}

.fa-clarinet:before {
  content: "";
}

.fa-claw-marks:before {
  content: "";
}

.fa-clinic-medical:before {
  content: "";
}

.fa-clipboard:before {
  content: "";
}

.fa-clipboard-check:before {
  content: "";
}

.fa-clipboard-list:before {
  content: "";
}

.fa-clipboard-list-check:before {
  content: "";
}

.fa-clipboard-prescription:before {
  content: "";
}

.fa-clipboard-user:before {
  content: "";
}

.fa-clock:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-closed-captioning:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-cloud-download:before {
  content: "";
}

.fa-cloud-download-alt:before {
  content: "";
}

.fa-cloud-drizzle:before {
  content: "";
}

.fa-cloud-hail:before {
  content: "";
}

.fa-cloud-hail-mixed:before {
  content: "";
}

.fa-cloud-meatball:before {
  content: "";
}

.fa-cloud-moon:before {
  content: "";
}

.fa-cloud-moon-rain:before {
  content: "";
}

.fa-cloud-music:before {
  content: "";
}

.fa-cloud-rain:before {
  content: "";
}

.fa-cloud-rainbow:before {
  content: "";
}

.fa-cloud-showers:before {
  content: "";
}

.fa-cloud-showers-heavy:before {
  content: "";
}

.fa-cloud-sleet:before {
  content: "";
}

.fa-cloud-snow:before {
  content: "";
}

.fa-cloud-sun:before {
  content: "";
}

.fa-cloud-sun-rain:before {
  content: "";
}

.fa-cloud-upload:before {
  content: "";
}

.fa-cloud-upload-alt:before {
  content: "";
}

.fa-clouds:before {
  content: "";
}

.fa-clouds-moon:before {
  content: "";
}

.fa-clouds-sun:before {
  content: "";
}

.fa-cloudscale:before {
  content: "";
}

.fa-cloudsmith:before {
  content: "";
}

.fa-cloudversify:before {
  content: "";
}

.fa-club:before {
  content: "";
}

.fa-cocktail:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-code-branch:before {
  content: "";
}

.fa-code-commit:before {
  content: "";
}

.fa-code-merge:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-coffee:before {
  content: "";
}

.fa-coffee-pot:before {
  content: "";
}

.fa-coffee-togo:before {
  content: "";
}

.fa-coffin:before {
  content: "";
}

.fa-coffin-cross:before {
  content: "";
}

.fa-cog:before {
  content: "";
}

.fa-cogs:before {
  content: "";
}

.fa-coin:before {
  content: "";
}

.fa-coins:before {
  content: "";
}

.fa-columns:before {
  content: "";
}

.fa-comet:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-comment-alt:before {
  content: "";
}

.fa-comment-alt-check:before {
  content: "";
}

.fa-comment-alt-dollar:before {
  content: "";
}

.fa-comment-alt-dots:before {
  content: "";
}

.fa-comment-alt-edit:before {
  content: "";
}

.fa-comment-alt-exclamation:before {
  content: "";
}

.fa-comment-alt-lines:before {
  content: "";
}

.fa-comment-alt-medical:before {
  content: "";
}

.fa-comment-alt-minus:before {
  content: "";
}

.fa-comment-alt-music:before {
  content: "";
}

.fa-comment-alt-plus:before {
  content: "";
}

.fa-comment-alt-slash:before {
  content: "";
}

.fa-comment-alt-smile:before {
  content: "";
}

.fa-comment-alt-times:before {
  content: "";
}

.fa-comment-check:before {
  content: "";
}

.fa-comment-dollar:before {
  content: "";
}

.fa-comment-dots:before {
  content: "";
}

.fa-comment-edit:before {
  content: "";
}

.fa-comment-exclamation:before {
  content: "";
}

.fa-comment-lines:before {
  content: "";
}

.fa-comment-medical:before {
  content: "";
}

.fa-comment-minus:before {
  content: "";
}

.fa-comment-music:before {
  content: "";
}

.fa-comment-plus:before {
  content: "";
}

.fa-comment-slash:before {
  content: "";
}

.fa-comment-smile:before {
  content: "";
}

.fa-comment-times:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-comments-alt:before {
  content: "";
}

.fa-comments-alt-dollar:before {
  content: "";
}

.fa-comments-dollar:before {
  content: "";
}

.fa-compact-disc:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-compass-slash:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-compress-alt:before {
  content: "";
}

.fa-compress-arrows-alt:before {
  content: "";
}

.fa-compress-wide:before {
  content: "";
}

.fa-computer-classic:before {
  content: "";
}

.fa-computer-speaker:before {
  content: "";
}

.fa-concierge-bell:before {
  content: "";
}

.fa-confluence:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-construction:before {
  content: "";
}

.fa-container-storage:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-conveyor-belt:before {
  content: "";
}

.fa-conveyor-belt-alt:before {
  content: "";
}

.fa-cookie:before {
  content: "";
}

.fa-cookie-bite:before {
  content: "";
}

.fa-copy:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-corn:before {
  content: "";
}

.fa-cotton-bureau:before {
  content: "";
}

.fa-couch:before {
  content: "";
}

.fa-cow:before {
  content: "";
}

.fa-cowbell:before {
  content: "";
}

.fa-cowbell-more:before {
  content: "";
}

.fa-cpanel:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-creative-commons-by:before {
  content: "";
}

.fa-creative-commons-nc:before {
  content: "";
}

.fa-creative-commons-nc-eu:before {
  content: "";
}

.fa-creative-commons-nc-jp:before {
  content: "";
}

.fa-creative-commons-nd:before {
  content: "";
}

.fa-creative-commons-pd:before {
  content: "";
}

.fa-creative-commons-pd-alt:before {
  content: "";
}

.fa-creative-commons-remix:before {
  content: "";
}

.fa-creative-commons-sa:before {
  content: "";
}

.fa-creative-commons-sampling:before {
  content: "";
}

.fa-creative-commons-sampling-plus:before {
  content: "";
}

.fa-creative-commons-share:before {
  content: "";
}

.fa-creative-commons-zero:before {
  content: "";
}

.fa-credit-card:before {
  content: "";
}

.fa-credit-card-blank:before {
  content: "";
}

.fa-credit-card-front:before {
  content: "";
}

.fa-cricket:before {
  content: "";
}

.fa-critical-role:before {
  content: "";
}

.fa-croissant:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-crop-alt:before {
  content: "";
}

.fa-cross:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-crow:before {
  content: "";
}

.fa-crown:before {
  content: "";
}

.fa-crutch:before {
  content: "";
}

.fa-crutches:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-css3-alt:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-curling:before {
  content: "";
}

.fa-cut:before {
  content: "";
}

.fa-cuttlefish:before {
  content: "";
}

.fa-d-and-d:before {
  content: "";
}

.fa-d-and-d-beyond:before {
  content: "";
}

.fa-dagger:before {
  content: "";
}

.fa-dailymotion:before {
  content: "";
}

.fa-dashcube:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-deaf:before {
  content: "";
}

.fa-debug:before {
  content: "";
}

.fa-deer:before {
  content: "";
}

.fa-deer-rudolph:before {
  content: "";
}

.fa-deezer:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-democrat:before {
  content: "";
}

.fa-deploydog:before {
  content: "";
}

.fa-deskpro:before {
  content: "";
}

.fa-desktop:before {
  content: "";
}

.fa-desktop-alt:before {
  content: "";
}

.fa-dev:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-dewpoint:before {
  content: "";
}

.fa-dharmachakra:before {
  content: "";
}

.fa-dhl:before {
  content: "";
}

.fa-diagnoses:before {
  content: "";
}

.fa-diamond:before {
  content: "";
}

.fa-diaspora:before {
  content: "";
}

.fa-dice:before {
  content: "";
}

.fa-dice-d10:before {
  content: "";
}

.fa-dice-d12:before {
  content: "";
}

.fa-dice-d20:before {
  content: "";
}

.fa-dice-d4:before {
  content: "";
}

.fa-dice-d6:before {
  content: "";
}

.fa-dice-d8:before {
  content: "";
}

.fa-dice-five:before {
  content: "";
}

.fa-dice-four:before {
  content: "";
}

.fa-dice-one:before {
  content: "";
}

.fa-dice-six:before {
  content: "";
}

.fa-dice-three:before {
  content: "";
}

.fa-dice-two:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-digging:before {
  content: "";
}

.fa-digital-ocean:before {
  content: "";
}

.fa-digital-tachograph:before {
  content: "";
}

.fa-diploma:before {
  content: "";
}

.fa-directions:before {
  content: "";
}

.fa-disc-drive:before {
  content: "";
}

.fa-discord:before {
  content: "";
}

.fa-discourse:before {
  content: "";
}

.fa-disease:before {
  content: "";
}

.fa-divide:before {
  content: "";
}

.fa-dizzy:before {
  content: "";
}

.fa-dna:before {
  content: "";
}

.fa-do-not-enter:before {
  content: "";
}

.fa-dochub:before {
  content: "";
}

.fa-docker:before {
  content: "";
}

.fa-dog:before {
  content: "";
}

.fa-dog-leashed:before {
  content: "";
}

.fa-dollar-sign:before {
  content: "";
}

.fa-dolly:before {
  content: "";
}

.fa-dolly-empty:before {
  content: "";
}

.fa-dolly-flatbed:before {
  content: "";
}

.fa-dolly-flatbed-alt:before {
  content: "";
}

.fa-dolly-flatbed-empty:before {
  content: "";
}

.fa-donate:before {
  content: "";
}

.fa-door-closed:before {
  content: "";
}

.fa-door-open:before {
  content: "";
}

.fa-dot-circle:before {
  content: "";
}

.fa-dove:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-draft2digital:before {
  content: "";
}

.fa-drafting-compass:before {
  content: "";
}

.fa-dragon:before {
  content: "";
}

.fa-draw-circle:before {
  content: "";
}

.fa-draw-polygon:before {
  content: "";
}

.fa-draw-square:before {
  content: "";
}

.fa-dreidel:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-dribbble-square:before {
  content: "";
}

.fa-drone:before {
  content: "";
}

.fa-drone-alt:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-drum:before {
  content: "";
}

.fa-drum-steelpan:before {
  content: "";
}

.fa-drumstick:before {
  content: "";
}

.fa-drumstick-bite:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-dryer:before {
  content: "";
}

.fa-dryer-alt:before {
  content: "";
}

.fa-duck:before {
  content: "";
}

.fa-dumbbell:before {
  content: "";
}

.fa-dumpster:before {
  content: "";
}

.fa-dumpster-fire:before {
  content: "";
}

.fa-dungeon:before {
  content: "";
}

.fa-dyalog:before {
  content: "";
}

.fa-ear:before {
  content: "";
}

.fa-ear-muffs:before {
  content: "";
}

.fa-earlybirds:before {
  content: "";
}

.fa-ebay:before {
  content: "";
}

.fa-eclipse:before {
  content: "";
}

.fa-eclipse-alt:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-edge-legacy:before {
  content: "";
}

.fa-edit:before {
  content: "";
}

.fa-egg:before {
  content: "";
}

.fa-egg-fried:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-elementor:before {
  content: "";
}

.fa-elephant:before {
  content: "";
}

.fa-ellipsis-h:before {
  content: "";
}

.fa-ellipsis-h-alt:before {
  content: "";
}

.fa-ellipsis-v:before {
  content: "";
}

.fa-ellipsis-v-alt:before {
  content: "";
}

.fa-ello:before {
  content: "";
}

.fa-ember:before {
  content: "";
}

.fa-empire:before {
  content: "";
}

.fa-empty-set:before {
  content: "";
}

.fa-engine-warning:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-envelope-open-dollar:before {
  content: "";
}

.fa-envelope-open-text:before {
  content: "";
}

.fa-envelope-square:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-equals:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-erlang:before {
  content: "";
}

.fa-ethereum:before {
  content: "";
}

.fa-ethernet:before {
  content: "";
}

.fa-etsy:before {
  content: "";
}

.fa-euro-sign:before {
  content: "";
}

.fa-evernote:before {
  content: "";
}

.fa-exchange:before {
  content: "";
}

.fa-exchange-alt:before {
  content: "";
}

.fa-exclamation:before {
  content: "";
}

.fa-exclamation-circle:before {
  content: "";
}

.fa-exclamation-square:before {
  content: "";
}

.fa-exclamation-triangle:before {
  content: "";
}

.fa-expand:before {
  content: "";
}

.fa-expand-alt:before {
  content: "";
}

.fa-expand-arrows:before {
  content: "";
}

.fa-expand-arrows-alt:before {
  content: "";
}

.fa-expand-wide:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-external-link:before {
  content: "";
}

.fa-external-link-alt:before {
  content: "";
}

.fa-external-link-square:before {
  content: "";
}

.fa-external-link-square-alt:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-eye-dropper:before {
  content: "";
}

.fa-eye-evil:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-facebook:before {
  content: "";
}

.fa-facebook-f:before {
  content: "";
}

.fa-facebook-messenger:before {
  content: "";
}

.fa-facebook-square:before {
  content: "";
}

.fa-fan:before {
  content: "";
}

.fa-fan-table:before {
  content: "";
}

.fa-fantasy-flight-games:before {
  content: "";
}

.fa-farm:before {
  content: "";
}

.fa-fast-backward:before {
  content: "";
}

.fa-fast-forward:before {
  content: "";
}

.fa-faucet:before {
  content: "";
}

.fa-faucet-drip:before {
  content: "";
}

.fa-fax:before {
  content: "";
}

.fa-feather:before {
  content: "";
}

.fa-feather-alt:before {
  content: "";
}

.fa-fedex:before {
  content: "";
}

.fa-fedora:before {
  content: "";
}

.fa-female:before {
  content: "";
}

.fa-field-hockey:before {
  content: "";
}

.fa-fighter-jet:before {
  content: "";
}

.fa-figma:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-file-alt:before {
  content: "";
}

.fa-file-archive:before {
  content: "";
}

.fa-file-audio:before {
  content: "";
}

.fa-file-certificate:before {
  content: "";
}

.fa-file-chart-line:before {
  content: "";
}

.fa-file-chart-pie:before {
  content: "";
}

.fa-file-check:before {
  content: "";
}

.fa-file-code:before {
  content: "";
}

.fa-file-contract:before {
  content: "";
}

.fa-file-csv:before {
  content: "";
}

.fa-file-download:before {
  content: "";
}

.fa-file-edit:before {
  content: "";
}

.fa-file-excel:before {
  content: "";
}

.fa-file-exclamation:before {
  content: "";
}

.fa-file-export:before {
  content: "";
}

.fa-file-image:before {
  content: "";
}

.fa-file-import:before {
  content: "";
}

.fa-file-invoice:before {
  content: "";
}

.fa-file-invoice-dollar:before {
  content: "";
}

.fa-file-medical:before {
  content: "";
}

.fa-file-medical-alt:before {
  content: "";
}

.fa-file-minus:before {
  content: "";
}

.fa-file-music:before {
  content: "";
}

.fa-file-pdf:before {
  content: "";
}

.fa-file-plus:before {
  content: "";
}

.fa-file-powerpoint:before {
  content: "";
}

.fa-file-prescription:before {
  content: "";
}

.fa-file-search:before {
  content: "";
}

.fa-file-signature:before {
  content: "";
}

.fa-file-spreadsheet:before {
  content: "";
}

.fa-file-times:before {
  content: "";
}

.fa-file-upload:before {
  content: "";
}

.fa-file-user:before {
  content: "";
}

.fa-file-video:before {
  content: "";
}

.fa-file-word:before {
  content: "";
}

.fa-files-medical:before {
  content: "";
}

.fa-fill:before {
  content: "";
}

.fa-fill-drip:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-film-alt:before {
  content: "";
}

.fa-film-canister:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-fingerprint:before {
  content: "";
}

.fa-fire:before {
  content: "";
}

.fa-fire-alt:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-fire-smoke:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-firefox-browser:before {
  content: "";
}

.fa-fireplace:before {
  content: "";
}

.fa-first-aid:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-first-order-alt:before {
  content: "";
}

.fa-firstdraft:before {
  content: "";
}

.fa-fish:before {
  content: "";
}

.fa-fish-cooked:before {
  content: "";
}

.fa-fist-raised:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-flag-alt:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-flag-usa:before {
  content: "";
}

.fa-flame:before {
  content: "";
}

.fa-flashlight:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-flask-poison:before {
  content: "";
}

.fa-flask-potion:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-flipboard:before {
  content: "";
}

.fa-flower:before {
  content: "";
}

.fa-flower-daffodil:before {
  content: "";
}

.fa-flower-tulip:before {
  content: "";
}

.fa-flushed:before {
  content: "";
}

.fa-flute:before {
  content: "";
}

.fa-flux-capacitor:before {
  content: "";
}

.fa-fly:before {
  content: "";
}

.fa-fog:before {
  content: "";
}

.fa-folder:before {
  content: "";
}

.fa-folder-download:before {
  content: "";
}

.fa-folder-minus:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-folder-plus:before {
  content: "";
}

.fa-folder-times:before {
  content: "";
}

.fa-folder-tree:before {
  content: "";
}

.fa-folder-upload:before {
  content: "";
}

.fa-folders:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-font-awesome:before {
  content: "";
}

.fa-font-awesome-alt:before {
  content: "";
}

.fa-font-awesome-flag:before {
  content: "";
}

.fa-font-awesome-logo-full:before {
  content: "";
}

.fa-font-case:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-fonticons-fi:before {
  content: "";
}

.fa-football-ball:before {
  content: "";
}

.fa-football-helmet:before {
  content: "";
}

.fa-forklift:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-fort-awesome-alt:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-fragile:before {
  content: "";
}

.fa-free-code-camp:before {
  content: "";
}

.fa-freebsd:before {
  content: "";
}

.fa-french-fries:before {
  content: "";
}

.fa-frog:before {
  content: "";
}

.fa-frosty-head:before {
  content: "";
}

.fa-frown:before {
  content: "";
}

.fa-frown-open:before {
  content: "";
}

.fa-fulcrum:before {
  content: "";
}

.fa-function:before {
  content: "";
}

.fa-funnel-dollar:before {
  content: "";
}

.fa-futbol:before {
  content: "";
}

.fa-galactic-republic:before {
  content: "";
}

.fa-galactic-senate:before {
  content: "";
}

.fa-galaxy:before {
  content: "";
}

.fa-game-board:before {
  content: "";
}

.fa-game-board-alt:before {
  content: "";
}

.fa-game-console-handheld:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-gamepad-alt:before {
  content: "";
}

.fa-garage:before {
  content: "";
}

.fa-garage-car:before {
  content: "";
}

.fa-garage-open:before {
  content: "";
}

.fa-gas-pump:before {
  content: "";
}

.fa-gas-pump-slash:before {
  content: "";
}

.fa-gavel:before {
  content: "";
}

.fa-gem:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-ghost:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-gift-card:before {
  content: "";
}

.fa-gifts:before {
  content: "";
}

.fa-gingerbread-man:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-git-alt:before {
  content: "";
}

.fa-git-square:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-github-square:before {
  content: "";
}

.fa-gitkraken:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-gitter:before {
  content: "";
}

.fa-glass:before {
  content: "";
}

.fa-glass-champagne:before {
  content: "";
}

.fa-glass-cheers:before {
  content: "";
}

.fa-glass-citrus:before {
  content: "";
}

.fa-glass-martini:before {
  content: "";
}

.fa-glass-martini-alt:before {
  content: "";
}

.fa-glass-whiskey:before {
  content: "";
}

.fa-glass-whiskey-rocks:before {
  content: "";
}

.fa-glasses:before {
  content: "";
}

.fa-glasses-alt:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-globe-africa:before {
  content: "";
}

.fa-globe-americas:before {
  content: "";
}

.fa-globe-asia:before {
  content: "";
}

.fa-globe-europe:before {
  content: "";
}

.fa-globe-snow:before {
  content: "";
}

.fa-globe-stand:before {
  content: "";
}

.fa-gofore:before {
  content: "";
}

.fa-golf-ball:before {
  content: "";
}

.fa-golf-club:before {
  content: "";
}

.fa-goodreads:before {
  content: "";
}

.fa-goodreads-g:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-google-drive:before {
  content: "";
}

.fa-google-pay:before {
  content: "";
}

.fa-google-play:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-google-plus-g:before {
  content: "";
}

.fa-google-plus-square:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-gopuram:before {
  content: "";
}

.fa-graduation-cap:before {
  content: "";
}

.fa-gramophone:before {
  content: "";
}

.fa-gratipay:before {
  content: "";
}

.fa-grav:before {
  content: "";
}

.fa-greater-than:before {
  content: "";
}

.fa-greater-than-equal:before {
  content: "";
}

.fa-grimace:before {
  content: "";
}

.fa-grin:before {
  content: "";
}

.fa-grin-alt:before {
  content: "";
}

.fa-grin-beam:before {
  content: "";
}

.fa-grin-beam-sweat:before {
  content: "";
}

.fa-grin-hearts:before {
  content: "";
}

.fa-grin-squint:before {
  content: "";
}

.fa-grin-squint-tears:before {
  content: "";
}

.fa-grin-stars:before {
  content: "";
}

.fa-grin-tears:before {
  content: "";
}

.fa-grin-tongue:before {
  content: "";
}

.fa-grin-tongue-squint:before {
  content: "";
}

.fa-grin-tongue-wink:before {
  content: "";
}

.fa-grin-wink:before {
  content: "";
}

.fa-grip-horizontal:before {
  content: "";
}

.fa-grip-lines:before {
  content: "";
}

.fa-grip-lines-vertical:before {
  content: "";
}

.fa-grip-vertical:before {
  content: "";
}

.fa-gripfire:before {
  content: "";
}

.fa-grunt:before {
  content: "";
}

.fa-guitar:before {
  content: "";
}

.fa-guitar-electric:before {
  content: "";
}

.fa-guitars:before {
  content: "";
}

.fa-gulp:before {
  content: "";
}

.fa-h-square:before {
  content: "";
}

.fa-h1:before {
  content: "";
}

.fa-h2:before {
  content: "";
}

.fa-h3:before {
  content: "";
}

.fa-h4:before {
  content: "";
}

.fa-hacker-news:before {
  content: "";
}

.fa-hacker-news-square:before {
  content: "";
}

.fa-hackerrank:before {
  content: "";
}

.fa-hamburger:before {
  content: "";
}

.fa-hammer:before {
  content: "";
}

.fa-hammer-war:before {
  content: "";
}

.fa-hamsa:before {
  content: "";
}

.fa-hand-heart:before {
  content: "";
}

.fa-hand-holding:before {
  content: "";
}

.fa-hand-holding-box:before {
  content: "";
}

.fa-hand-holding-heart:before {
  content: "";
}

.fa-hand-holding-magic:before {
  content: "";
}

.fa-hand-holding-medical:before {
  content: "";
}

.fa-hand-holding-seedling:before {
  content: "";
}

.fa-hand-holding-usd:before {
  content: "";
}

.fa-hand-holding-water:before {
  content: "";
}

.fa-hand-lizard:before {
  content: "";
}

.fa-hand-middle-finger:before {
  content: "";
}

.fa-hand-paper:before {
  content: "";
}

.fa-hand-peace:before {
  content: "";
}

.fa-hand-point-down:before {
  content: "";
}

.fa-hand-point-left:before {
  content: "";
}

.fa-hand-point-right:before {
  content: "";
}

.fa-hand-point-up:before {
  content: "";
}

.fa-hand-pointer:before {
  content: "";
}

.fa-hand-receiving:before {
  content: "";
}

.fa-hand-rock:before {
  content: "";
}

.fa-hand-scissors:before {
  content: "";
}

.fa-hand-sparkles:before {
  content: "";
}

.fa-hand-spock:before {
  content: "";
}

.fa-hands:before {
  content: "";
}

.fa-hands-heart:before {
  content: "";
}

.fa-hands-helping:before {
  content: "";
}

.fa-hands-usd:before {
  content: "";
}

.fa-hands-wash:before {
  content: "";
}

.fa-handshake:before {
  content: "";
}

.fa-handshake-alt:before {
  content: "";
}

.fa-handshake-alt-slash:before {
  content: "";
}

.fa-handshake-slash:before {
  content: "";
}

.fa-hanukiah:before {
  content: "";
}

.fa-hard-hat:before {
  content: "";
}

.fa-hashtag:before {
  content: "";
}

.fa-hat-chef:before {
  content: "";
}

.fa-hat-cowboy:before {
  content: "";
}

.fa-hat-cowboy-side:before {
  content: "";
}

.fa-hat-santa:before {
  content: "";
}

.fa-hat-winter:before {
  content: "";
}

.fa-hat-witch:before {
  content: "";
}

.fa-hat-wizard:before {
  content: "";
}

.fa-hdd:before {
  content: "";
}

.fa-head-side:before {
  content: "";
}

.fa-head-side-brain:before {
  content: "";
}

.fa-head-side-cough:before {
  content: "";
}

.fa-head-side-cough-slash:before {
  content: "";
}

.fa-head-side-headphones:before {
  content: "";
}

.fa-head-side-mask:before {
  content: "";
}

.fa-head-side-medical:before {
  content: "";
}

.fa-head-side-virus:before {
  content: "";
}

.fa-head-vr:before {
  content: "";
}

.fa-heading:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-headphones-alt:before {
  content: "";
}

.fa-headset:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-heart-broken:before {
  content: "";
}

.fa-heart-circle:before {
  content: "";
}

.fa-heart-rate:before {
  content: "";
}

.fa-heart-square:before {
  content: "";
}

.fa-heartbeat:before {
  content: "";
}

.fa-heat:before {
  content: "";
}

.fa-helicopter:before {
  content: "";
}

.fa-helmet-battle:before {
  content: "";
}

.fa-hexagon:before {
  content: "";
}

.fa-highlighter:before {
  content: "";
}

.fa-hiking:before {
  content: "";
}

.fa-hippo:before {
  content: "";
}

.fa-hips:before {
  content: "";
}

.fa-hire-a-helper:before {
  content: "";
}

.fa-history:before {
  content: "";
}

.fa-hockey-mask:before {
  content: "";
}

.fa-hockey-puck:before {
  content: "";
}

.fa-hockey-sticks:before {
  content: "";
}

.fa-holly-berry:before {
  content: "";
}

.fa-home:before {
  content: "";
}

.fa-home-alt:before {
  content: "";
}

.fa-home-heart:before {
  content: "";
}

.fa-home-lg:before {
  content: "";
}

.fa-home-lg-alt:before {
  content: "";
}

.fa-hood-cloak:before {
  content: "";
}

.fa-hooli:before {
  content: "";
}

.fa-horizontal-rule:before {
  content: "";
}

.fa-hornbill:before {
  content: "";
}

.fa-horse:before {
  content: "";
}

.fa-horse-head:before {
  content: "";
}

.fa-horse-saddle:before {
  content: "";
}

.fa-hospital:before {
  content: "";
}

.fa-hospital-alt:before {
  content: "";
}

.fa-hospital-symbol:before {
  content: "";
}

.fa-hospital-user:before {
  content: "";
}

.fa-hospitals:before {
  content: "";
}

.fa-hot-tub:before {
  content: "";
}

.fa-hotdog:before {
  content: "";
}

.fa-hotel:before {
  content: "";
}

.fa-hotjar:before {
  content: "";
}

.fa-hourglass:before {
  content: "";
}

.fa-hourglass-end:before {
  content: "";
}

.fa-hourglass-half:before {
  content: "";
}

.fa-hourglass-start:before {
  content: "";
}

.fa-house:before {
  content: "";
}

.fa-house-damage:before {
  content: "";
}

.fa-house-day:before {
  content: "";
}

.fa-house-flood:before {
  content: "";
}

.fa-house-leave:before {
  content: "";
}

.fa-house-night:before {
  content: "";
}

.fa-house-return:before {
  content: "";
}

.fa-house-signal:before {
  content: "";
}

.fa-house-user:before {
  content: "";
}

.fa-houzz:before {
  content: "";
}

.fa-hryvnia:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-hubspot:before {
  content: "";
}

.fa-humidity:before {
  content: "";
}

.fa-hurricane:before {
  content: "";
}

.fa-i-cursor:before {
  content: "";
}

.fa-ice-cream:before {
  content: "";
}

.fa-ice-skate:before {
  content: "";
}

.fa-icicles:before {
  content: "";
}

.fa-icons:before {
  content: "";
}

.fa-icons-alt:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-id-card:before {
  content: "";
}

.fa-id-card-alt:before {
  content: "";
}

.fa-ideal:before {
  content: "";
}

.fa-igloo:before {
  content: "";
}

.fa-image:before {
  content: "";
}

.fa-image-polaroid:before {
  content: "";
}

.fa-images:before {
  content: "";
}

.fa-imdb:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-inbox-in:before {
  content: "";
}

.fa-inbox-out:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-industry-alt:before {
  content: "";
}

.fa-infinity:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-info-circle:before {
  content: "";
}

.fa-info-square:before {
  content: "";
}

.fa-inhaler:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-instagram-square:before {
  content: "";
}

.fa-integral:before {
  content: "";
}

.fa-intercom:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-intersection:before {
  content: "";
}

.fa-inventory:before {
  content: "";
}

.fa-invision:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-island-tropical:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-itch-io:before {
  content: "";
}

.fa-itunes:before {
  content: "";
}

.fa-itunes-note:before {
  content: "";
}

.fa-jack-o-lantern:before {
  content: "";
}

.fa-java:before {
  content: "";
}

.fa-jedi:before {
  content: "";
}

.fa-jedi-order:before {
  content: "";
}

.fa-jenkins:before {
  content: "";
}

.fa-jira:before {
  content: "";
}

.fa-joget:before {
  content: "";
}

.fa-joint:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-journal-whills:before {
  content: "";
}

.fa-joystick:before {
  content: "";
}

.fa-js:before {
  content: "";
}

.fa-js-square:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-jug:before {
  content: "";
}

.fa-kaaba:before {
  content: "";
}

.fa-kaggle:before {
  content: "";
}

.fa-kazoo:before {
  content: "";
}

.fa-kerning:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-key-skeleton:before {
  content: "";
}

.fa-keybase:before {
  content: "";
}

.fa-keyboard:before {
  content: "";
}

.fa-keycdn:before {
  content: "";
}

.fa-keynote:before {
  content: "";
}

.fa-khanda:before {
  content: "";
}

.fa-kickstarter:before {
  content: "";
}

.fa-kickstarter-k:before {
  content: "";
}

.fa-kidneys:before {
  content: "";
}

.fa-kiss:before {
  content: "";
}

.fa-kiss-beam:before {
  content: "";
}

.fa-kiss-wink-heart:before {
  content: "";
}

.fa-kite:before {
  content: "";
}

.fa-kiwi-bird:before {
  content: "";
}

.fa-knife-kitchen:before {
  content: "";
}

.fa-korvue:before {
  content: "";
}

.fa-lambda:before {
  content: "";
}

.fa-lamp:before {
  content: "";
}

.fa-lamp-desk:before {
  content: "";
}

.fa-lamp-floor:before {
  content: "";
}

.fa-landmark:before {
  content: "";
}

.fa-landmark-alt:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-laptop-code:before {
  content: "";
}

.fa-laptop-house:before {
  content: "";
}

.fa-laptop-medical:before {
  content: "";
}

.fa-laravel:before {
  content: "";
}

.fa-lasso:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-lastfm-square:before {
  content: "";
}

.fa-laugh:before {
  content: "";
}

.fa-laugh-beam:before {
  content: "";
}

.fa-laugh-squint:before {
  content: "";
}

.fa-laugh-wink:before {
  content: "";
}

.fa-layer-group:before {
  content: "";
}

.fa-layer-minus:before {
  content: "";
}

.fa-layer-plus:before {
  content: "";
}

.fa-leaf:before {
  content: "";
}

.fa-leaf-heart:before {
  content: "";
}

.fa-leaf-maple:before {
  content: "";
}

.fa-leaf-oak:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-lemon:before {
  content: "";
}

.fa-less:before {
  content: "";
}

.fa-less-than:before {
  content: "";
}

.fa-less-than-equal:before {
  content: "";
}

.fa-level-down:before {
  content: "";
}

.fa-level-down-alt:before {
  content: "";
}

.fa-level-up:before {
  content: "";
}

.fa-level-up-alt:before {
  content: "";
}

.fa-life-ring:before {
  content: "";
}

.fa-light-ceiling:before {
  content: "";
}

.fa-light-switch:before {
  content: "";
}

.fa-light-switch-off:before {
  content: "";
}

.fa-light-switch-on:before {
  content: "";
}

.fa-lightbulb:before {
  content: "";
}

.fa-lightbulb-dollar:before {
  content: "";
}

.fa-lightbulb-exclamation:before {
  content: "";
}

.fa-lightbulb-on:before {
  content: "";
}

.fa-lightbulb-slash:before {
  content: "";
}

.fa-lights-holiday:before {
  content: "";
}

.fa-line:before {
  content: "";
}

.fa-line-columns:before {
  content: "";
}

.fa-line-height:before {
  content: "";
}

.fa-link:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-linkedin-in:before {
  content: "";
}

.fa-linode:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-lips:before {
  content: "";
}

.fa-lira-sign:before {
  content: "";
}

.fa-list:before {
  content: "";
}

.fa-list-alt:before {
  content: "";
}

.fa-list-music:before {
  content: "";
}

.fa-list-ol:before {
  content: "";
}

.fa-list-ul:before {
  content: "";
}

.fa-location:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-location-circle:before {
  content: "";
}

.fa-location-slash:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-lock-alt:before {
  content: "";
}

.fa-lock-open:before {
  content: "";
}

.fa-lock-open-alt:before {
  content: "";
}

.fa-long-arrow-alt-down:before {
  content: "";
}

.fa-long-arrow-alt-left:before {
  content: "";
}

.fa-long-arrow-alt-right:before {
  content: "";
}

.fa-long-arrow-alt-up:before {
  content: "";
}

.fa-long-arrow-down:before {
  content: "";
}

.fa-long-arrow-left:before {
  content: "";
}

.fa-long-arrow-right:before {
  content: "";
}

.fa-long-arrow-up:before {
  content: "";
}

.fa-loveseat:before {
  content: "";
}

.fa-low-vision:before {
  content: "";
}

.fa-luchador:before {
  content: "";
}

.fa-luggage-cart:before {
  content: "";
}

.fa-lungs:before {
  content: "";
}

.fa-lungs-virus:before {
  content: "";
}

.fa-lyft:before {
  content: "";
}

.fa-mace:before {
  content: "";
}

.fa-magento:before {
  content: "";
}

.fa-magic:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-mail-bulk:before {
  content: "";
}

.fa-mailbox:before {
  content: "";
}

.fa-mailchimp:before {
  content: "";
}

.fa-male:before {
  content: "";
}

.fa-mandalorian:before {
  content: "";
}

.fa-mandolin:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-map-marked:before {
  content: "";
}

.fa-map-marked-alt:before {
  content: "";
}

.fa-map-marker:before {
  content: "";
}

.fa-map-marker-alt:before {
  content: "";
}

.fa-map-marker-alt-slash:before {
  content: "";
}

.fa-map-marker-check:before {
  content: "";
}

.fa-map-marker-edit:before {
  content: "";
}

.fa-map-marker-exclamation:before {
  content: "";
}

.fa-map-marker-minus:before {
  content: "";
}

.fa-map-marker-plus:before {
  content: "";
}

.fa-map-marker-question:before {
  content: "";
}

.fa-map-marker-slash:before {
  content: "";
}

.fa-map-marker-smile:before {
  content: "";
}

.fa-map-marker-times:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-map-signs:before {
  content: "";
}

.fa-markdown:before {
  content: "";
}

.fa-marker:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-mars-stroke-h:before {
  content: "";
}

.fa-mars-stroke-v:before {
  content: "";
}

.fa-mask:before {
  content: "";
}

.fa-mastodon:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-mdb:before {
  content: "";
}

.fa-meat:before {
  content: "";
}

.fa-medal:before {
  content: "";
}

.fa-medapps:before {
  content: "";
}

.fa-medium:before {
  content: "";
}

.fa-medium-m:before {
  content: "";
}

.fa-medkit:before {
  content: "";
}

.fa-medrt:before {
  content: "";
}

.fa-meetup:before {
  content: "";
}

.fa-megaphone:before {
  content: "";
}

.fa-megaport:before {
  content: "";
}

.fa-meh:before {
  content: "";
}

.fa-meh-blank:before {
  content: "";
}

.fa-meh-rolling-eyes:before {
  content: "";
}

.fa-memory:before {
  content: "";
}

.fa-mendeley:before {
  content: "";
}

.fa-menorah:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-meteor:before {
  content: "";
}

.fa-microblog:before {
  content: "";
}

.fa-microchip:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-microphone-alt:before {
  content: "";
}

.fa-microphone-alt-slash:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-microphone-stand:before {
  content: "";
}

.fa-microscope:before {
  content: "";
}

.fa-microsoft:before {
  content: "";
}

.fa-microwave:before {
  content: "";
}

.fa-mind-share:before {
  content: "";
}

.fa-minus:before {
  content: "";
}

.fa-minus-circle:before {
  content: "";
}

.fa-minus-hexagon:before {
  content: "";
}

.fa-minus-octagon:before {
  content: "";
}

.fa-minus-square:before {
  content: "";
}

.fa-mistletoe:before {
  content: "";
}

.fa-mitten:before {
  content: "";
}

.fa-mix:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-mixer:before {
  content: "";
}

.fa-mizuni:before {
  content: "";
}

.fa-mobile:before {
  content: "";
}

.fa-mobile-alt:before {
  content: "";
}

.fa-mobile-android:before {
  content: "";
}

.fa-mobile-android-alt:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-monero:before {
  content: "";
}

.fa-money-bill:before {
  content: "";
}

.fa-money-bill-alt:before {
  content: "";
}

.fa-money-bill-wave:before {
  content: "";
}

.fa-money-bill-wave-alt:before {
  content: "";
}

.fa-money-check:before {
  content: "";
}

.fa-money-check-alt:before {
  content: "";
}

.fa-money-check-edit:before {
  content: "";
}

.fa-money-check-edit-alt:before {
  content: "";
}

.fa-monitor-heart-rate:before {
  content: "";
}

.fa-monkey:before {
  content: "";
}

.fa-monument:before {
  content: "";
}

.fa-moon:before {
  content: "";
}

.fa-moon-cloud:before {
  content: "";
}

.fa-moon-stars:before {
  content: "";
}

.fa-mortar-pestle:before {
  content: "";
}

.fa-mosque:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-mountain:before {
  content: "";
}

.fa-mountains:before {
  content: "";
}

.fa-mouse:before {
  content: "";
}

.fa-mouse-alt:before {
  content: "";
}

.fa-mouse-pointer:before {
  content: "";
}

.fa-mp3-player:before {
  content: "";
}

.fa-mug:before {
  content: "";
}

.fa-mug-hot:before {
  content: "";
}

.fa-mug-marshmallows:before {
  content: "";
}

.fa-mug-tea:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-music-alt:before {
  content: "";
}

.fa-music-alt-slash:before {
  content: "";
}

.fa-music-slash:before {
  content: "";
}

.fa-napster:before {
  content: "";
}

.fa-narwhal:before {
  content: "";
}

.fa-neos:before {
  content: "";
}

.fa-network-wired:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-newspaper:before {
  content: "";
}

.fa-nimblr:before {
  content: "";
}

.fa-node:before {
  content: "";
}

.fa-node-js:before {
  content: "";
}

.fa-not-equal:before {
  content: "";
}

.fa-notes-medical:before {
  content: "";
}

.fa-npm:before {
  content: "";
}

.fa-ns8:before {
  content: "";
}

.fa-nutritionix:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-octagon:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-odnoklassniki-square:before {
  content: "";
}

.fa-oil-can:before {
  content: "";
}

.fa-oil-temp:before {
  content: "";
}

.fa-old-republic:before {
  content: "";
}

.fa-om:before {
  content: "";
}

.fa-omega:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-orcid:before {
  content: "";
}

.fa-ornament:before {
  content: "";
}

.fa-osi:before {
  content: "";
}

.fa-otter:before {
  content: "";
}

.fa-outdent:before {
  content: "";
}

.fa-outlet:before {
  content: "";
}

.fa-oven:before {
  content: "";
}

.fa-overline:before {
  content: "";
}

.fa-page-break:before {
  content: "";
}

.fa-page4:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-pager:before {
  content: "";
}

.fa-paint-brush:before {
  content: "";
}

.fa-paint-brush-alt:before {
  content: "";
}

.fa-paint-roller:before {
  content: "";
}

.fa-palette:before {
  content: "";
}

.fa-palfed:before {
  content: "";
}

.fa-pallet:before {
  content: "";
}

.fa-pallet-alt:before {
  content: "";
}

.fa-paper-plane:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-parachute-box:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-paragraph-rtl:before {
  content: "";
}

.fa-parking:before {
  content: "";
}

.fa-parking-circle:before {
  content: "";
}

.fa-parking-circle-slash:before {
  content: "";
}

.fa-parking-slash:before {
  content: "";
}

.fa-passport:before {
  content: "";
}

.fa-pastafarianism:before {
  content: "";
}

.fa-paste:before {
  content: "";
}

.fa-patreon:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-pause-circle:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-paw-alt:before {
  content: "";
}

.fa-paw-claws:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-peace:before {
  content: "";
}

.fa-pegasus:before {
  content: "";
}

.fa-pen:before {
  content: "";
}

.fa-pen-alt:before {
  content: "";
}

.fa-pen-fancy:before {
  content: "";
}

.fa-pen-nib:before {
  content: "";
}

.fa-pen-square:before {
  content: "";
}

.fa-pencil:before {
  content: "";
}

.fa-pencil-alt:before {
  content: "";
}

.fa-pencil-paintbrush:before {
  content: "";
}

.fa-pencil-ruler:before {
  content: "";
}

.fa-pennant:before {
  content: "";
}

.fa-penny-arcade:before {
  content: "";
}

.fa-people-arrows:before {
  content: "";
}

.fa-people-carry:before {
  content: "";
}

.fa-pepper-hot:before {
  content: "";
}

.fa-percent:before {
  content: "";
}

.fa-percentage:before {
  content: "";
}

.fa-periscope:before {
  content: "";
}

.fa-person-booth:before {
  content: "";
}

.fa-person-carry:before {
  content: "";
}

.fa-person-dolly:before {
  content: "";
}

.fa-person-dolly-empty:before {
  content: "";
}

.fa-person-sign:before {
  content: "";
}

.fa-phabricator:before {
  content: "";
}

.fa-phoenix-framework:before {
  content: "";
}

.fa-phoenix-squadron:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-phone-alt:before {
  content: "";
}

.fa-phone-laptop:before {
  content: "";
}

.fa-phone-office:before {
  content: "";
}

.fa-phone-plus:before {
  content: "";
}

.fa-phone-rotary:before {
  content: "";
}

.fa-phone-slash:before {
  content: "";
}

.fa-phone-square:before {
  content: "";
}

.fa-phone-square-alt:before {
  content: "";
}

.fa-phone-volume:before {
  content: "";
}

.fa-photo-video:before {
  content: "";
}

.fa-php:before {
  content: "";
}

.fa-pi:before {
  content: "";
}

.fa-piano:before {
  content: "";
}

.fa-piano-keyboard:before {
  content: "";
}

.fa-pie:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-pied-piper-hat:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-pied-piper-square:before {
  content: "";
}

.fa-pig:before {
  content: "";
}

.fa-piggy-bank:before {
  content: "";
}

.fa-pills:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-pinterest-square:before {
  content: "";
}

.fa-pizza:before {
  content: "";
}

.fa-pizza-slice:before {
  content: "";
}

.fa-place-of-worship:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-plane-alt:before {
  content: "";
}

.fa-plane-arrival:before {
  content: "";
}

.fa-plane-departure:before {
  content: "";
}

.fa-plane-slash:before {
  content: "";
}

.fa-planet-moon:before {
  content: "";
}

.fa-planet-ringed:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-play-circle:before {
  content: "";
}

.fa-playstation:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-plus:before {
  content: "";
}

.fa-plus-circle:before {
  content: "";
}

.fa-plus-hexagon:before {
  content: "";
}

.fa-plus-octagon:before {
  content: "";
}

.fa-plus-square:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-podium:before {
  content: "";
}

.fa-podium-star:before {
  content: "";
}

.fa-police-box:before {
  content: "";
}

.fa-poll:before {
  content: "";
}

.fa-poll-h:before {
  content: "";
}

.fa-poll-people:before {
  content: "";
}

.fa-poo:before {
  content: "";
}

.fa-poo-storm:before {
  content: "";
}

.fa-poop:before {
  content: "";
}

.fa-popcorn:before {
  content: "";
}

.fa-portal-enter:before {
  content: "";
}

.fa-portal-exit:before {
  content: "";
}

.fa-portrait:before {
  content: "";
}

.fa-pound-sign:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-pray:before {
  content: "";
}

.fa-praying-hands:before {
  content: "";
}

.fa-prescription:before {
  content: "";
}

.fa-prescription-bottle:before {
  content: "";
}

.fa-prescription-bottle-alt:before {
  content: "";
}

.fa-presentation:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-print-search:before {
  content: "";
}

.fa-print-slash:before {
  content: "";
}

.fa-procedures:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-project-diagram:before {
  content: "";
}

.fa-projector:before {
  content: "";
}

.fa-pump-medical:before {
  content: "";
}

.fa-pump-soap:before {
  content: "";
}

.fa-pumpkin:before {
  content: "";
}

.fa-pushed:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-python:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-qrcode:before {
  content: "";
}

.fa-question:before {
  content: "";
}

.fa-question-circle:before {
  content: "";
}

.fa-question-square:before {
  content: "";
}

.fa-quidditch:before {
  content: "";
}

.fa-quinscape:before {
  content: "";
}

.fa-quora:before {
  content: "";
}

.fa-quote-left:before {
  content: "";
}

.fa-quote-right:before {
  content: "";
}

.fa-quran:before {
  content: "";
}

.fa-r-project:before {
  content: "";
}

.fa-rabbit:before {
  content: "";
}

.fa-rabbit-fast:before {
  content: "";
}

.fa-racquet:before {
  content: "";
}

.fa-radar:before {
  content: "";
}

.fa-radiation:before {
  content: "";
}

.fa-radiation-alt:before {
  content: "";
}

.fa-radio:before {
  content: "";
}

.fa-radio-alt:before {
  content: "";
}

.fa-rainbow:before {
  content: "";
}

.fa-raindrops:before {
  content: "";
}

.fa-ram:before {
  content: "";
}

.fa-ramp-loading:before {
  content: "";
}

.fa-random:before {
  content: "";
}

.fa-raspberry-pi:before {
  content: "";
}

.fa-ravelry:before {
  content: "";
}

.fa-raygun:before {
  content: "";
}

.fa-react:before {
  content: "";
}

.fa-reacteurope:before {
  content: "";
}

.fa-readme:before {
  content: "";
}

.fa-rebel:before {
  content: "";
}

.fa-receipt:before {
  content: "";
}

.fa-record-vinyl:before {
  content: "";
}

.fa-rectangle-landscape:before {
  content: "";
}

.fa-rectangle-portrait:before {
  content: "";
}

.fa-rectangle-wide:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-red-river:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-reddit-square:before {
  content: "";
}

.fa-redhat:before {
  content: "";
}

.fa-redo:before {
  content: "";
}

.fa-redo-alt:before {
  content: "";
}

.fa-refrigerator:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-remove-format:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-repeat:before {
  content: "";
}

.fa-repeat-1:before {
  content: "";
}

.fa-repeat-1-alt:before {
  content: "";
}

.fa-repeat-alt:before {
  content: "";
}

.fa-reply:before {
  content: "";
}

.fa-reply-all:before {
  content: "";
}

.fa-replyd:before {
  content: "";
}

.fa-republican:before {
  content: "";
}

.fa-researchgate:before {
  content: "";
}

.fa-resolving:before {
  content: "";
}

.fa-restroom:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-retweet-alt:before {
  content: "";
}

.fa-rev:before {
  content: "";
}

.fa-ribbon:before {
  content: "";
}

.fa-ring:before {
  content: "";
}

.fa-rings-wedding:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-robot:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-rocket-launch:before {
  content: "";
}

.fa-rocketchat:before {
  content: "";
}

.fa-rockrms:before {
  content: "";
}

.fa-route:before {
  content: "";
}

.fa-route-highway:before {
  content: "";
}

.fa-route-interstate:before {
  content: "";
}

.fa-router:before {
  content: "";
}

.fa-rss:before {
  content: "";
}

.fa-rss-square:before {
  content: "";
}

.fa-ruble-sign:before {
  content: "";
}

.fa-ruler:before {
  content: "";
}

.fa-ruler-combined:before {
  content: "";
}

.fa-ruler-horizontal:before {
  content: "";
}

.fa-ruler-triangle:before {
  content: "";
}

.fa-ruler-vertical:before {
  content: "";
}

.fa-running:before {
  content: "";
}

.fa-rupee-sign:before {
  content: "";
}

.fa-rust:before {
  content: "";
}

.fa-rv:before {
  content: "";
}

.fa-sack:before {
  content: "";
}

.fa-sack-dollar:before {
  content: "";
}

.fa-sad-cry:before {
  content: "";
}

.fa-sad-tear:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-salad:before {
  content: "";
}

.fa-salesforce:before {
  content: "";
}

.fa-sandwich:before {
  content: "";
}

.fa-sass:before {
  content: "";
}

.fa-satellite:before {
  content: "";
}

.fa-satellite-dish:before {
  content: "";
}

.fa-sausage:before {
  content: "";
}

.fa-save:before {
  content: "";
}

.fa-sax-hot:before {
  content: "";
}

.fa-saxophone:before {
  content: "";
}

.fa-scalpel:before {
  content: "";
}

.fa-scalpel-path:before {
  content: "";
}

.fa-scanner:before {
  content: "";
}

.fa-scanner-image:before {
  content: "";
}

.fa-scanner-keyboard:before {
  content: "";
}

.fa-scanner-touchscreen:before {
  content: "";
}

.fa-scarecrow:before {
  content: "";
}

.fa-scarf:before {
  content: "";
}

.fa-schlix:before {
  content: "";
}

.fa-school:before {
  content: "";
}

.fa-screwdriver:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-scroll:before {
  content: "";
}

.fa-scroll-old:before {
  content: "";
}

.fa-scrubber:before {
  content: "";
}

.fa-scythe:before {
  content: "";
}

.fa-sd-card:before {
  content: "";
}

.fa-search:before {
  content: "";
}

.fa-search-dollar:before {
  content: "";
}

.fa-search-location:before {
  content: "";
}

.fa-search-minus:before {
  content: "";
}

.fa-search-plus:before {
  content: "";
}

.fa-searchengin:before {
  content: "";
}

.fa-seedling:before {
  content: "";
}

.fa-sellcast:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-send-back:before {
  content: "";
}

.fa-send-backward:before {
  content: "";
}

.fa-sensor:before {
  content: "";
}

.fa-sensor-alert:before {
  content: "";
}

.fa-sensor-fire:before {
  content: "";
}

.fa-sensor-on:before {
  content: "";
}

.fa-sensor-smoke:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-servicestack:before {
  content: "";
}

.fa-shapes:before {
  content: "";
}

.fa-share:before {
  content: "";
}

.fa-share-all:before {
  content: "";
}

.fa-share-alt:before {
  content: "";
}

.fa-share-alt-square:before {
  content: "";
}

.fa-share-square:before {
  content: "";
}

.fa-sheep:before {
  content: "";
}

.fa-shekel-sign:before {
  content: "";
}

.fa-shield:before {
  content: "";
}

.fa-shield-alt:before {
  content: "";
}

.fa-shield-check:before {
  content: "";
}

.fa-shield-cross:before {
  content: "";
}

.fa-shield-virus:before {
  content: "";
}

.fa-ship:before {
  content: "";
}

.fa-shipping-fast:before {
  content: "";
}

.fa-shipping-timed:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-shish-kebab:before {
  content: "";
}

.fa-shoe-prints:before {
  content: "";
}

.fa-shopify:before {
  content: "";
}

.fa-shopping-bag:before {
  content: "";
}

.fa-shopping-basket:before {
  content: "";
}

.fa-shopping-cart:before {
  content: "";
}

.fa-shopware:before {
  content: "";
}

.fa-shovel:before {
  content: "";
}

.fa-shovel-snow:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-shredder:before {
  content: "";
}

.fa-shuttle-van:before {
  content: "";
}

.fa-shuttlecock:before {
  content: "";
}

.fa-sickle:before {
  content: "";
}

.fa-sigma:before {
  content: "";
}

.fa-sign:before {
  content: "";
}

.fa-sign-in:before {
  content: "";
}

.fa-sign-in-alt:before {
  content: "";
}

.fa-sign-language:before {
  content: "";
}

.fa-sign-out:before {
  content: "";
}

.fa-sign-out-alt:before {
  content: "";
}

.fa-signal:before {
  content: "";
}

.fa-signal-1:before {
  content: "";
}

.fa-signal-2:before {
  content: "";
}

.fa-signal-3:before {
  content: "";
}

.fa-signal-4:before {
  content: "";
}

.fa-signal-alt:before {
  content: "";
}

.fa-signal-alt-1:before {
  content: "";
}

.fa-signal-alt-2:before {
  content: "";
}

.fa-signal-alt-3:before {
  content: "";
}

.fa-signal-alt-slash:before {
  content: "";
}

.fa-signal-slash:before {
  content: "";
}

.fa-signal-stream:before {
  content: "";
}

.fa-signature:before {
  content: "";
}

.fa-sim-card:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-sink:before {
  content: "";
}

.fa-siren:before {
  content: "";
}

.fa-siren-on:before {
  content: "";
}

.fa-sistrix:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-sith:before {
  content: "";
}

.fa-skating:before {
  content: "";
}

.fa-skeleton:before {
  content: "";
}

.fa-sketch:before {
  content: "";
}

.fa-ski-jump:before {
  content: "";
}

.fa-ski-lift:before {
  content: "";
}

.fa-skiing:before {
  content: "";
}

.fa-skiing-nordic:before {
  content: "";
}

.fa-skull:before {
  content: "";
}

.fa-skull-cow:before {
  content: "";
}

.fa-skull-crossbones:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-slack:before {
  content: "";
}

.fa-slack-hash:before {
  content: "";
}

.fa-slash:before {
  content: "";
}

.fa-sledding:before {
  content: "";
}

.fa-sleigh:before {
  content: "";
}

.fa-sliders-h:before {
  content: "";
}

.fa-sliders-h-square:before {
  content: "";
}

.fa-sliders-v:before {
  content: "";
}

.fa-sliders-v-square:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-smile:before {
  content: "";
}

.fa-smile-beam:before {
  content: "";
}

.fa-smile-plus:before {
  content: "";
}

.fa-smile-wink:before {
  content: "";
}

.fa-smog:before {
  content: "";
}

.fa-smoke:before {
  content: "";
}

.fa-smoking:before {
  content: "";
}

.fa-smoking-ban:before {
  content: "";
}

.fa-sms:before {
  content: "";
}

.fa-snake:before {
  content: "";
}

.fa-snapchat:before {
  content: "";
}

.fa-snapchat-ghost:before {
  content: "";
}

.fa-snapchat-square:before {
  content: "";
}

.fa-snooze:before {
  content: "";
}

.fa-snow-blowing:before {
  content: "";
}

.fa-snowboarding:before {
  content: "";
}

.fa-snowflake:before {
  content: "";
}

.fa-snowflakes:before {
  content: "";
}

.fa-snowman:before {
  content: "";
}

.fa-snowmobile:before {
  content: "";
}

.fa-snowplow:before {
  content: "";
}

.fa-soap:before {
  content: "";
}

.fa-socks:before {
  content: "";
}

.fa-solar-panel:before {
  content: "";
}

.fa-solar-system:before {
  content: "";
}

.fa-sort:before {
  content: "";
}

.fa-sort-alpha-down:before {
  content: "";
}

.fa-sort-alpha-down-alt:before {
  content: "";
}

.fa-sort-alpha-up:before {
  content: "";
}

.fa-sort-alpha-up-alt:before {
  content: "";
}

.fa-sort-alt:before {
  content: "";
}

.fa-sort-amount-down:before {
  content: "";
}

.fa-sort-amount-down-alt:before {
  content: "";
}

.fa-sort-amount-up:before {
  content: "";
}

.fa-sort-amount-up-alt:before {
  content: "";
}

.fa-sort-circle:before {
  content: "";
}

.fa-sort-circle-down:before {
  content: "";
}

.fa-sort-circle-up:before {
  content: "";
}

.fa-sort-down:before {
  content: "";
}

.fa-sort-numeric-down:before {
  content: "";
}

.fa-sort-numeric-down-alt:before {
  content: "";
}

.fa-sort-numeric-up:before {
  content: "";
}

.fa-sort-numeric-up-alt:before {
  content: "";
}

.fa-sort-shapes-down:before {
  content: "";
}

.fa-sort-shapes-down-alt:before {
  content: "";
}

.fa-sort-shapes-up:before {
  content: "";
}

.fa-sort-shapes-up-alt:before {
  content: "";
}

.fa-sort-size-down:before {
  content: "";
}

.fa-sort-size-down-alt:before {
  content: "";
}

.fa-sort-size-up:before {
  content: "";
}

.fa-sort-size-up-alt:before {
  content: "";
}

.fa-sort-up:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-soup:before {
  content: "";
}

.fa-sourcetree:before {
  content: "";
}

.fa-spa:before {
  content: "";
}

.fa-space-shuttle:before {
  content: "";
}

.fa-space-station-moon:before {
  content: "";
}

.fa-space-station-moon-alt:before {
  content: "";
}

.fa-spade:before {
  content: "";
}

.fa-sparkles:before {
  content: "";
}

.fa-speakap:before {
  content: "";
}

.fa-speaker:before {
  content: "";
}

.fa-speaker-deck:before {
  content: "";
}

.fa-speakers:before {
  content: "";
}

.fa-spell-check:before {
  content: "";
}

.fa-spider:before {
  content: "";
}

.fa-spider-black-widow:before {
  content: "";
}

.fa-spider-web:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-spinner-third:before {
  content: "";
}

.fa-splotch:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-spray-can:before {
  content: "";
}

.fa-sprinkler:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-square-full:before {
  content: "";
}

.fa-square-root:before {
  content: "";
}

.fa-square-root-alt:before {
  content: "";
}

.fa-squarespace:before {
  content: "";
}

.fa-squirrel:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-stackpath:before {
  content: "";
}

.fa-staff:before {
  content: "";
}

.fa-stamp:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-star-and-crescent:before {
  content: "";
}

.fa-star-christmas:before {
  content: "";
}

.fa-star-exclamation:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-star-half-alt:before {
  content: "";
}

.fa-star-of-david:before {
  content: "";
}

.fa-star-of-life:before {
  content: "";
}

.fa-star-shooting:before {
  content: "";
}

.fa-starfighter:before {
  content: "";
}

.fa-starfighter-alt:before {
  content: "";
}

.fa-stars:before {
  content: "";
}

.fa-starship:before {
  content: "";
}

.fa-starship-freighter:before {
  content: "";
}

.fa-staylinked:before {
  content: "";
}

.fa-steak:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-steam-square:before {
  content: "";
}

.fa-steam-symbol:before {
  content: "";
}

.fa-steering-wheel:before {
  content: "";
}

.fa-step-backward:before {
  content: "";
}

.fa-step-forward:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-sticker-mule:before {
  content: "";
}

.fa-sticky-note:before {
  content: "";
}

.fa-stocking:before {
  content: "";
}

.fa-stomach:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-stop-circle:before {
  content: "";
}

.fa-stopwatch:before {
  content: "";
}

.fa-stopwatch-20:before {
  content: "";
}

.fa-store:before {
  content: "";
}

.fa-store-alt:before {
  content: "";
}

.fa-store-alt-slash:before {
  content: "";
}

.fa-store-slash:before {
  content: "";
}

.fa-strava:before {
  content: "";
}

.fa-stream:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-stretcher:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-stripe:before {
  content: "";
}

.fa-stripe-s:before {
  content: "";
}

.fa-stroopwafel:before {
  content: "";
}

.fa-studiovinari:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-subway:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-suitcase-rolling:before {
  content: "";
}

.fa-sun:before {
  content: "";
}

.fa-sun-cloud:before {
  content: "";
}

.fa-sun-dust:before {
  content: "";
}

.fa-sun-haze:before {
  content: "";
}

.fa-sunglasses:before {
  content: "";
}

.fa-sunrise:before {
  content: "";
}

.fa-sunset:before {
  content: "";
}

.fa-superpowers:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-supple:before {
  content: "";
}

.fa-surprise:before {
  content: "";
}

.fa-suse:before {
  content: "";
}

.fa-swatchbook:before {
  content: "";
}

.fa-swift:before {
  content: "";
}

.fa-swimmer:before {
  content: "";
}

.fa-swimming-pool:before {
  content: "";
}

.fa-sword:before {
  content: "";
}

.fa-sword-laser:before {
  content: "";
}

.fa-sword-laser-alt:before {
  content: "";
}

.fa-swords:before {
  content: "";
}

.fa-swords-laser:before {
  content: "";
}

.fa-symfony:before {
  content: "";
}

.fa-synagogue:before {
  content: "";
}

.fa-sync:before {
  content: "";
}

.fa-sync-alt:before {
  content: "";
}

.fa-syringe:before {
  content: "";
}

.fa-table:before {
  content: "";
}

.fa-table-tennis:before {
  content: "";
}

.fa-tablet:before {
  content: "";
}

.fa-tablet-alt:before {
  content: "";
}

.fa-tablet-android:before {
  content: "";
}

.fa-tablet-android-alt:before {
  content: "";
}

.fa-tablet-rugged:before {
  content: "";
}

.fa-tablets:before {
  content: "";
}

.fa-tachometer:before {
  content: "";
}

.fa-tachometer-alt:before {
  content: "";
}

.fa-tachometer-alt-average:before {
  content: "";
}

.fa-tachometer-alt-fast:before {
  content: "";
}

.fa-tachometer-alt-fastest:before {
  content: "";
}

.fa-tachometer-alt-slow:before {
  content: "";
}

.fa-tachometer-alt-slowest:before {
  content: "";
}

.fa-tachometer-average:before {
  content: "";
}

.fa-tachometer-fast:before {
  content: "";
}

.fa-tachometer-fastest:before {
  content: "";
}

.fa-tachometer-slow:before {
  content: "";
}

.fa-tachometer-slowest:before {
  content: "";
}

.fa-taco:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-tally:before {
  content: "";
}

.fa-tanakh:before {
  content: "";
}

.fa-tape:before {
  content: "";
}

.fa-tasks:before {
  content: "";
}

.fa-tasks-alt:before {
  content: "";
}

.fa-taxi:before {
  content: "";
}

.fa-teamspeak:before {
  content: "";
}

.fa-teeth:before {
  content: "";
}

.fa-teeth-open:before {
  content: "";
}

.fa-telegram:before {
  content: "";
}

.fa-telegram-plane:before {
  content: "";
}

.fa-telescope:before {
  content: "";
}

.fa-temperature-down:before {
  content: "";
}

.fa-temperature-frigid:before {
  content: "";
}

.fa-temperature-high:before {
  content: "";
}

.fa-temperature-hot:before {
  content: "";
}

.fa-temperature-low:before {
  content: "";
}

.fa-temperature-up:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-tenge:before {
  content: "";
}

.fa-tennis-ball:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-text:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-text-size:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-th:before {
  content: "";
}

.fa-th-large:before {
  content: "";
}

.fa-th-list:before {
  content: "";
}

.fa-the-red-yeti:before {
  content: "";
}

.fa-theater-masks:before {
  content: "";
}

.fa-themeco:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-thermometer:before {
  content: "";
}

.fa-thermometer-empty:before {
  content: "";
}

.fa-thermometer-full:before {
  content: "";
}

.fa-thermometer-half:before {
  content: "";
}

.fa-thermometer-quarter:before {
  content: "";
}

.fa-thermometer-three-quarters:before {
  content: "";
}

.fa-theta:before {
  content: "";
}

.fa-think-peaks:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-thumbtack:before {
  content: "";
}

.fa-thunderstorm:before {
  content: "";
}

.fa-thunderstorm-moon:before {
  content: "";
}

.fa-thunderstorm-sun:before {
  content: "";
}

.fa-ticket:before {
  content: "";
}

.fa-ticket-alt:before {
  content: "";
}

.fa-tiktok:before {
  content: "";
}

.fa-tilde:before {
  content: "";
}

.fa-times:before {
  content: "";
}

.fa-times-circle:before {
  content: "";
}

.fa-times-hexagon:before {
  content: "";
}

.fa-times-octagon:before {
  content: "";
}

.fa-times-square:before {
  content: "";
}

.fa-tint:before {
  content: "";
}

.fa-tint-slash:before {
  content: "";
}

.fa-tire:before {
  content: "";
}

.fa-tire-flat:before {
  content: "";
}

.fa-tire-pressure-warning:before {
  content: "";
}

.fa-tire-rugged:before {
  content: "";
}

.fa-tired:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-toilet:before {
  content: "";
}

.fa-toilet-paper:before {
  content: "";
}

.fa-toilet-paper-alt:before {
  content: "";
}

.fa-toilet-paper-slash:before {
  content: "";
}

.fa-tombstone:before {
  content: "";
}

.fa-tombstone-alt:before {
  content: "";
}

.fa-toolbox:before {
  content: "";
}

.fa-tools:before {
  content: "";
}

.fa-tooth:before {
  content: "";
}

.fa-toothbrush:before {
  content: "";
}

.fa-torah:before {
  content: "";
}

.fa-torii-gate:before {
  content: "";
}

.fa-tornado:before {
  content: "";
}

.fa-tractor:before {
  content: "";
}

.fa-trade-federation:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-traffic-cone:before {
  content: "";
}

.fa-traffic-light:before {
  content: "";
}

.fa-traffic-light-go:before {
  content: "";
}

.fa-traffic-light-slow:before {
  content: "";
}

.fa-traffic-light-stop:before {
  content: "";
}

.fa-trailer:before {
  content: "";
}

.fa-train:before {
  content: "";
}

.fa-tram:before {
  content: "";
}

.fa-transgender:before {
  content: "";
}

.fa-transgender-alt:before {
  content: "";
}

.fa-transporter:before {
  content: "";
}

.fa-transporter-1:before {
  content: "";
}

.fa-transporter-2:before {
  content: "";
}

.fa-transporter-3:before {
  content: "";
}

.fa-transporter-empty:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-trash-alt:before {
  content: "";
}

.fa-trash-restore:before {
  content: "";
}

.fa-trash-restore-alt:before {
  content: "";
}

.fa-trash-undo:before {
  content: "";
}

.fa-trash-undo-alt:before {
  content: "";
}

.fa-treasure-chest:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-tree-alt:before {
  content: "";
}

.fa-tree-christmas:before {
  content: "";
}

.fa-tree-decorated:before {
  content: "";
}

.fa-tree-large:before {
  content: "";
}

.fa-tree-palm:before {
  content: "";
}

.fa-trees:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-triangle:before {
  content: "";
}

.fa-triangle-music:before {
  content: "";
}

.fa-tripadvisor:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-trophy-alt:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-truck-container:before {
  content: "";
}

.fa-truck-couch:before {
  content: "";
}

.fa-truck-loading:before {
  content: "";
}

.fa-truck-monster:before {
  content: "";
}

.fa-truck-moving:before {
  content: "";
}

.fa-truck-pickup:before {
  content: "";
}

.fa-truck-plow:before {
  content: "";
}

.fa-truck-ramp:before {
  content: "";
}

.fa-trumpet:before {
  content: "";
}

.fa-tshirt:before {
  content: "";
}

.fa-tty:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-tumblr-square:before {
  content: "";
}

.fa-turkey:before {
  content: "";
}

.fa-turntable:before {
  content: "";
}

.fa-turtle:before {
  content: "";
}

.fa-tv:before {
  content: "";
}

.fa-tv-alt:before {
  content: "";
}

.fa-tv-music:before {
  content: "";
}

.fa-tv-retro:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-twitter-square:before {
  content: "";
}

.fa-typewriter:before {
  content: "";
}

.fa-typo3:before {
  content: "";
}

.fa-uber:before {
  content: "";
}

.fa-ubuntu:before {
  content: "";
}

.fa-ufo:before {
  content: "";
}

.fa-ufo-beam:before {
  content: "";
}

.fa-uikit:before {
  content: "";
}

.fa-umbraco:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-umbrella-beach:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-undo:before {
  content: "";
}

.fa-undo-alt:before {
  content: "";
}

.fa-unicorn:before {
  content: "";
}

.fa-union:before {
  content: "";
}

.fa-uniregistry:before {
  content: "";
}

.fa-unity:before {
  content: "";
}

.fa-universal-access:before {
  content: "";
}

.fa-university:before {
  content: "";
}

.fa-unlink:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-unlock-alt:before {
  content: "";
}

.fa-unsplash:before {
  content: "";
}

.fa-untappd:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-ups:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-usb-drive:before {
  content: "";
}

.fa-usd-circle:before {
  content: "";
}

.fa-usd-square:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-user-alien:before {
  content: "";
}

.fa-user-alt:before {
  content: "";
}

.fa-user-alt-slash:before {
  content: "";
}

.fa-user-astronaut:before {
  content: "";
}

.fa-user-chart:before {
  content: "";
}

.fa-user-check:before {
  content: "";
}

.fa-user-circle:before {
  content: "";
}

.fa-user-clock:before {
  content: "";
}

.fa-user-cog:before {
  content: "";
}

.fa-user-cowboy:before {
  content: "";
}

.fa-user-crown:before {
  content: "";
}

.fa-user-edit:before {
  content: "";
}

.fa-user-friends:before {
  content: "";
}

.fa-user-graduate:before {
  content: "";
}

.fa-user-hard-hat:before {
  content: "";
}

.fa-user-headset:before {
  content: "";
}

.fa-user-injured:before {
  content: "";
}

.fa-user-lock:before {
  content: "";
}

.fa-user-md:before {
  content: "";
}

.fa-user-md-chat:before {
  content: "";
}

.fa-user-minus:before {
  content: "";
}

.fa-user-music:before {
  content: "";
}

.fa-user-ninja:before {
  content: "";
}

.fa-user-nurse:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-user-robot:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-user-shield:before {
  content: "";
}

.fa-user-slash:before {
  content: "";
}

.fa-user-tag:before {
  content: "";
}

.fa-user-tie:before {
  content: "";
}

.fa-user-times:before {
  content: "";
}

.fa-user-unlock:before {
  content: "";
}

.fa-user-visor:before {
  content: "";
}

.fa-users:before {
  content: "";
}

.fa-users-class:before {
  content: "";
}

.fa-users-cog:before {
  content: "";
}

.fa-users-crown:before {
  content: "";
}

.fa-users-medical:before {
  content: "";
}

.fa-users-slash:before {
  content: "";
}

.fa-usps:before {
  content: "";
}

.fa-ussunnah:before {
  content: "";
}

.fa-utensil-fork:before {
  content: "";
}

.fa-utensil-knife:before {
  content: "";
}

.fa-utensil-spoon:before {
  content: "";
}

.fa-utensils:before {
  content: "";
}

.fa-utensils-alt:before {
  content: "";
}

.fa-vaadin:before {
  content: "";
}

.fa-vacuum:before {
  content: "";
}

.fa-vacuum-robot:before {
  content: "";
}

.fa-value-absolute:before {
  content: "";
}

.fa-vector-square:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-vhs:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-viadeo-square:before {
  content: "";
}

.fa-vial:before {
  content: "";
}

.fa-vials:before {
  content: "";
}

.fa-viber:before {
  content: "";
}

.fa-video:before {
  content: "";
}

.fa-video-plus:before {
  content: "";
}

.fa-video-slash:before {
  content: "";
}

.fa-vihara:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-vimeo-square:before {
  content: "";
}

.fa-vimeo-v:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-violin:before {
  content: "";
}

.fa-virus:before {
  content: "";
}

.fa-virus-slash:before {
  content: "";
}

.fa-viruses:before {
  content: "";
}

.fa-vk:before {
  content: "";
}

.fa-vnv:before {
  content: "";
}

.fa-voicemail:before {
  content: "";
}

.fa-volcano:before {
  content: "";
}

.fa-volleyball-ball:before {
  content: "";
}

.fa-volume:before {
  content: "";
}

.fa-volume-down:before {
  content: "";
}

.fa-volume-mute:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-volume-slash:before {
  content: "";
}

.fa-volume-up:before {
  content: "";
}

.fa-vote-nay:before {
  content: "";
}

.fa-vote-yea:before {
  content: "";
}

.fa-vr-cardboard:before {
  content: "";
}

.fa-vuejs:before {
  content: "";
}

.fa-wagon-covered:before {
  content: "";
}

.fa-walker:before {
  content: "";
}

.fa-walkie-talkie:before {
  content: "";
}

.fa-walking:before {
  content: "";
}

.fa-wallet:before {
  content: "";
}

.fa-wand:before {
  content: "";
}

.fa-wand-magic:before {
  content: "";
}

.fa-warehouse:before {
  content: "";
}

.fa-warehouse-alt:before {
  content: "";
}

.fa-washer:before {
  content: "";
}

.fa-watch:before {
  content: "";
}

.fa-watch-calculator:before {
  content: "";
}

.fa-watch-fitness:before {
  content: "";
}

.fa-water:before {
  content: "";
}

.fa-water-lower:before {
  content: "";
}

.fa-water-rise:before {
  content: "";
}

.fa-wave-sine:before {
  content: "";
}

.fa-wave-square:before {
  content: "";
}

.fa-wave-triangle:before {
  content: "";
}

.fa-waveform:before {
  content: "";
}

.fa-waveform-path:before {
  content: "";
}

.fa-waze:before {
  content: "";
}

.fa-webcam:before {
  content: "";
}

.fa-webcam-slash:before {
  content: "";
}

.fa-weebly:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-weight:before {
  content: "";
}

.fa-weight-hanging:before {
  content: "";
}

.fa-weixin:before {
  content: "";
}

.fa-whale:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-whatsapp-square:before {
  content: "";
}

.fa-wheat:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-whistle:before {
  content: "";
}

.fa-whmcs:before {
  content: "";
}

.fa-wifi:before {
  content: "";
}

.fa-wifi-1:before {
  content: "";
}

.fa-wifi-2:before {
  content: "";
}

.fa-wifi-slash:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-wind:before {
  content: "";
}

.fa-wind-turbine:before {
  content: "";
}

.fa-wind-warning:before {
  content: "";
}

.fa-window:before {
  content: "";
}

.fa-window-alt:before {
  content: "";
}

.fa-window-close:before {
  content: "";
}

.fa-window-frame:before {
  content: "";
}

.fa-window-frame-open:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-windsock:before {
  content: "";
}

.fa-wine-bottle:before {
  content: "";
}

.fa-wine-glass:before {
  content: "";
}

.fa-wine-glass-alt:before {
  content: "";
}

.fa-wix:before {
  content: "";
}

.fa-wizards-of-the-coast:before {
  content: "";
}

.fa-wolf-pack-battalion:before {
  content: "";
}

.fa-won-sign:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-wordpress-simple:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-wpexplorer:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-wpressr:before {
  content: "";
}

.fa-wreath:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-x-ray:before {
  content: "";
}

.fa-xbox:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-xing-square:before {
  content: "";
}

.fa-y-combinator:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-yammer:before {
  content: "";
}

.fa-yandex:before {
  content: "";
}

.fa-yandex-international:before {
  content: "";
}

.fa-yarn:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-yen-sign:before {
  content: "";
}

.fa-yin-yang:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-youtube-square:before {
  content: "";
}

.fa-zhihu:before {
  content: "";
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/*!
 * Font Awesome Pro 5.14.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("~@fortawesome/fontawesome-pro/webfonts/fa-solid-900.eot");
  src: url("~@fortawesome/fontawesome-pro/webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("~@fortawesome/fontawesome-pro/webfonts/fa-solid-900.woff2") format("woff2"), url("~@fortawesome/fontawesome-pro/webfonts/fa-solid-900.woff") format("woff"), url("~@fortawesome/fontawesome-pro/webfonts/fa-solid-900.ttf") format("truetype"), url("~@fortawesome/fontawesome-pro/webfonts/fa-solid-900.svg#fontawesome") format("svg");
}
.fa,
.fas {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}

/*!
 * Font Awesome Pro 5.14.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("~@fortawesome/fontawesome-pro/webfonts/fa-light-300.eot");
  src: url("~@fortawesome/fontawesome-pro/webfonts/fa-light-300.eot?#iefix") format("embedded-opentype"), url("~@fortawesome/fontawesome-pro/webfonts/fa-light-300.woff2") format("woff2"), url("~@fortawesome/fontawesome-pro/webfonts/fa-light-300.woff") format("woff"), url("~@fortawesome/fontawesome-pro/webfonts/fa-light-300.ttf") format("truetype"), url("~@fortawesome/fontawesome-pro/webfonts/fa-light-300.svg#fontawesome") format("svg");
}
.fal {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
}

:focus {
  outline: none;
}

html {
  height: 100%;
}

body {
  color: #2b2b2b;
  position: relative;
  min-height: 100%;
}
@media (max-width: 991.98px) {
  body {
    padding-bottom: 75px;
    width: 100%;
  }
  body.menu-opened, body.search-opened {
    overflow: hidden;
  }
}

#app {
  min-height: 100%;
  overflow: visible;
}
@media (min-width: 1400px) {
  #app > .container {
    border-color: #d2dadc;
    border-style: solid;
    border-width: 0 1px;
  }
}

.page.container {
  min-height: 650px;
  padding-bottom: 30px;
  padding-top: 10px;
}

@media (min-width: 992px) {
  #content {
    width: calc(100% - 212px);
  }
}

@media (min-width: 1400px) {
  #content-inner {
    width: calc(100% - 212px);
  }
}

#left-sidebar {
  background-color: white;
  border-right: 1px solid #d2dadc;
  position: relative;
  width: 212px;
  z-index: 3;
}
@media (max-width: 991.98px) {
  #left-sidebar {
    border: none;
    width: auto;
  }
}

#right-sidebar {
  border-color: #d2dadc;
  border-style: solid;
  border-width: 1px 0 0 1px;
}
@media (min-width: 1400px) {
  #right-sidebar {
    border-top: none;
    width: 212px;
  }
}

#footer__sidebar {
  width: 212px;
}

@media (max-width: 575.98px) {
  #simple-page .page-body table td {
    display: block;
    width: 100% !important;
  }
  #simple-page .page-body table td img {
    width: 100%;
  }
  #simple-page .page-body iframe {
    width: 100%;
  }
}

img,
svg {
  max-width: 100%;
  height: auto;
}

@media (max-width: 991.98px) {
  .content iframe {
    width: 100%;
  }
}

#top-header-wrapper {
  position: relative;
  z-index: 1040;
  left: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

#header__bar__top .btn-icon, .btn-rounded-bordered-icon {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  height: 30px;
  line-height: 1.8;
  margin: -4px 0.3rem 0;
  padding: 0;
  width: 30px;
}

#header__bar__top {
  position: relative;
  z-index: 1040;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
  height: 75px;
  padding: 20px 0;
  position: relative;
  width: 100%;
}
#header__bar__top .nav-link {
  padding: 0.3rem;
}
#header__bar__top .divider {
  display: inline-block;
}
#header__bar__top .dropdown-menu {
  background-color: #f5dc7a;
  border: none;
  border-radius: 0;
  left: auto !important;
  margin: 0;
  overflow: auto;
  right: -10px !important;
  top: 55px !important;
  transform: none !important;
}
#header__bar__top .dropdown-menu:after {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.08), transparent);
  content: "";
  height: 10px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
@media (min-width: 992px) {
  .configure #header__bar__top .dropdown-menu {
    display: block;
    height: 100%;
    position: fixed !important;
    right: -300px !important;
    top: 0 !important;
    transition: right 0.2s;
  }
  .configure #header__bar__top .dropdown-menu .dropdown-item {
    min-width: 250px;
  }
  .configure #header__bar__top .dropdown-menu.show {
    right: 0 !important;
  }
}
@media (max-width: 991.98px) {
  #header__bar__top .dropdown-menu {
    max-height: calc(100% - 40px);
    position: fixed !important;
    right: 0 !important;
    top: 40px !important;
  }
}
@media (max-width: 991.98px) {
  #header__bar__top {
    height: 40px;
    padding: 4px 0;
  }
  #header__bar__top .divider-2 {
    display: none;
  }
}

#header__bar__logo {
  margin-top: -14px;
  width: 212px;
}
@media (max-width: 991.98px) {
  #header__bar__logo {
    margin-top: -1px;
    width: 90px;
  }
}

.navbar-brand__logo {
  display: block;
  width: 110px;
}
@media (max-width: 991.98px) {
  .navbar-brand__logo {
    padding: 0;
    width: 60px;
  }
}

@media (max-width: 991.98px) {
  #header__bar__search {
    bottom: 0;
    left: 80px;
    padding: 0;
    position: fixed;
  }
  .search-opened #header__bar__search {
    left: 20px;
    max-width: 100%;
    width: calc(100vw - 40px);
  }
}

#header__bar__bottom {
  height: 75px;
  padding-bottom: 20px;
  padding-top: 20px;
}
@media (max-width: 991.98px) {
  #header__bar__bottom {
    box-shadow: 0 -0.2rem 0.5rem rgba(0, 0, 0, 0.2);
    background-color: white;
    bottom: 0;
    padding: 0 6px;
    position: fixed;
    top: auto;
    width: 100%;
    z-index: 1030;
  }
}
#header__bar__bottom .header__bar__account__item {
  width: 50px;
}

#cart-wrapper.btn-icon svg {
  margin-top: -4px;
}
@media (min-width: 992px) {
  #cart-wrapper.btn-icon svg {
    width: 14px;
  }
}
@media (max-width: 991.98px) {
  .search-opened #cart-wrapper.btn-icon {
    display: none;
  }
}
@media (max-width: 991.98px) {
  #cart-wrapper.btn-icon {
    border: none;
    bottom: 0;
    height: 75px;
    position: fixed;
    left: 10px;
    line-height: 75px;
    margin: 0;
    width: 60px;
  }
  #cart-wrapper.btn-icon .counter {
    left: auto;
    right: 8px;
    top: 15px;
  }
}

#favorites-wrapper {
  background-color: #23515e;
}
#favorites-wrapper .icon {
  color: white;
}

.authenticated #favorites-wrapper,
.authenticated #header__bar__top .divider-1 {
  display: none;
}

#cart-wrapper,
#favorites-wrapper {
  position: relative;
}
#cart-wrapper form i,
#favorites-wrapper form i {
  color: #000000;
}
#cart-wrapper .counter,
#favorites-wrapper .counter {
  background-color: #f5dc7a;
  border-radius: 18px;
  color: #000000;
  opacity: 1;
  height: 18px;
  line-height: 18px;
  width: 18px;
  position: absolute;
  top: -8px;
  left: 18px;
  z-index: 1;
  display: none;
}
#cart-wrapper .counter.hidden,
#favorites-wrapper .counter.hidden {
  display: none;
}
#cart-wrapper .counter.visible,
#favorites-wrapper .counter.visible {
  display: block;
}
#cart-wrapper .counter[v-cloak],
#favorites-wrapper .counter[v-cloak] {
  display: none;
}

.mm-navbars_top {
  display: none;
}
.mm-navbars_top .mm-navbar {
  border-bottom: none;
}

.motivational-message__body {
  border-radius: 3px;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.2);
  display: none;
  font-size: 0.9rem;
  font-weight: normal;
  margin: 15px;
  padding: 3px 15px;
  text-align: left;
}
.motivational-message__body .graphic {
  padding: 3px 0 3px 10px;
  width: 120px;
}
.motivational-message__body.primary {
  background-color: #23515e;
  color: white;
}
.motivational-message__body.secondary {
  background-color: #f5dc7a;
  color: #23515e;
}

.buy_online .mm-navbars_top {
  display: block;
}
.buy_online #motivational-buy_online {
  display: flex;
}

.advice_from_specialist .mm-navbars_top {
  display: block;
}
.advice_from_specialist #motivational-advice_from_specialist {
  display: flex;
}

.footer {
  background-color: #f5dc7a;
  bottom: 0;
  color: #303030;
  content-visibility: auto;
  font-size: 16px;
  left: 0;
  overflow: hidden;
  padding-top: 40px;
  position: relative;
  width: 100%;
}
.footer:after {
  background-color: #f5dc7a;
  content: "";
  height: 100%;
  left: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
.footer .footer-copyright-wrapper {
  border-top: solid 1px rgba(35, 81, 94, 0.2);
}
.footer hr {
  border-color: rgba(35, 81, 94, 0.2);
  margin-bottom: 0;
}
.footer .footer__menu__column {
  margin-bottom: 20px;
}
@media (max-width: 575.98px) {
  .footer .footer__menu__column {
    border-bottom: 1px solid rgba(35, 81, 94, 0.2);
    margin-bottom: 0;
  }
}
.footer .footer__menu__title {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 20px;
}
@media (min-width: 992px) {
  .footer .footer__menu__title {
    color: #2b2b2b;
  }
}
@media (min-width: 576px) {
  .footer .footer__menu__title {
    pointer-events: none;
  }
}
@media (min-width: 576px) {
  .footer .footer__menu__title .accordion-icon {
    display: none !important;
  }
}
@media (max-width: 575.98px) {
  .footer .footer__menu__title {
    cursor: pointer;
    margin: 15px 0;
  }
}
@media (min-width: 576px) {
  .footer .footer__menu__body {
    display: block !important;
  }
}
.footer .menu__item {
  font-weight: bold;
  margin-bottom: 5px;
  margin-bottom: 8px;
  line-height: 22px;
}
@media (min-width: 576px) {
  .footer .payment-methods__icons {
    float: left;
  }
  .footer .new-line {
    clear: left;
  }
}
@media (max-width: 991.98px) {
  .footer {
    border-top: 1px solid rgba(35, 81, 94, 0.2);
  }
  .footer a {
    display: block;
    padding: 5px 0;
  }
}
@media (max-width: 575.98px) {
  .footer {
    padding-top: 0;
  }
}

#footer__social {
  padding-bottom: 22px;
}
#footer__social #bottom-region__facebook {
  margin-right: 110px;
}
@media (max-width: 991.98px) {
  #footer__social {
    padding-bottom: 20px;
  }
}

#footer__copyright {
  font-size: 14px;
  font-style: italic;
  line-height: 2.5em;
  padding: 10px 0;
}
#footer__copyright > * {
  margin: 0;
  padding: 0;
}

#footer__newsletter p {
  font-size: 14px;
  margin-bottom: 0.5rem;
}
#footer__newsletter button {
  margin-bottom: 25px;
}
@media (max-width: 991.98px) {
  #footer__newsletter button {
    margin-bottom: 0;
  }
}

h1,
h2,
h3,
h4,
h5 {
  color: #2b2b2b;
  font-weight: bold;
  margin-bottom: 1.2rem;
}

h1.page-title {
  margin: 2rem 0 1rem;
}

.sub-title {
  font-size: 1.1rem;
}

.small-title {
  font-size: 18px;
}

.date-field-formatted {
  color: rgba(35, 81, 94, 0.6);
}

.comment-posted {
  color: rgba(35, 81, 94, 0.6);
  font-style: italic;
}

.image-left-align {
  float: left;
}

.image-right-align {
  float: right;
}

.image-center-align {
  text-align: center;
}

.image-center-align > figure {
  display: inline-block;
}

figure.image {
  margin: 5px 22px 22px;
  text-align: center;
}
figure.image img {
  margin-bottom: -25px;
}
figure.image figcaption {
  background: #ffffff;
  border-radius: 6px;
  display: table;
  margin: 0 auto;
  padding: 10px 20px;
  position: relative;
}
figure.image.image-left-align {
  margin-right: 20px;
  margin-left: 14%;
}
figure.image.image-left-align img {
  float: none !important;
}
@media (max-width: 1199.98px) {
  figure.image.image-left-align {
    margin-left: 0;
  }
}
@media (max-width: 767.98px) {
  figure.image.image-left-align {
    float: none !important;
    margin-left: 0;
    margin-right: 0;
  }
}
figure.image.image-right-align {
  margin-left: 20px;
  margin-right: 14%;
}
figure.image.image-right-align img {
  float: none !important;
}
@media (max-width: 1199.98px) {
  figure.image.image-right-align {
    margin-right: 0;
  }
}
@media (max-width: 767.98px) {
  figure.image.image-right-align {
    float: none !important;
    margin-left: 0;
    margin-right: 0;
  }
}

.btn.btn-secondary, .btn.btn-secondary:not(:disabled):not(.disabled):active, .btn.btn-secondary:not(:disabled):not(.disabled).active, .btn.btn-primary {
  box-shadow: 0 12px 24px 0 rgba(28, 68, 78, 0.25);
}

button:focus {
  outline: none;
}

.btn {
  font-weight: bold;
}
.btn.btn-primary {
  background-color: #23515e;
  height: 50px;
  line-height: 2.3em;
  padding-left: 2em;
  padding-right: 2em;
  color: white;
  border: none;
}
@media all and (max-width: 870px) and (min-width: 768px) {
  .btn.btn-primary {
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media all and (max-width: 870px) and (min-width: 768px) {
  .btn.btn-primary {
    font-size: 15px;
    line-height: 2.5em;
  }
}
.btn.btn-primary:hover, .btn.btn-primary:not(:disabled):not(.disabled):active, .btn.btn-primary:not(:disabled):not(.disabled).active {
  background-color: #1f4753;
}
.btn.btn-primary.btn-sm, .btn-group-sm > .btn.btn-primary {
  height: auto;
  padding: 0;
}
.btn.btn-secondary, .btn.btn-secondary:not(:disabled):not(.disabled):active, .btn.btn-secondary:not(:disabled):not(.disabled).active {
  background-color: #f5dc7a;
  border: none;
  color: #23515e;
  height: 50px;
  line-height: 2.3em;
  padding-left: 2em;
  padding-right: 2em;
}
.btn.btn-secondary:hover, .btn.btn-secondary:not(:disabled):not(.disabled):active, .btn.btn-secondary:not(:disabled):not(.disabled).active, .btn.btn-secondary:not(:disabled):not(.disabled):active:hover, .btn.btn-secondary:not(:disabled):not(.disabled):active:not(:disabled):not(.disabled):active, .btn.btn-secondary:not(:disabled):not(.disabled):active:not(:disabled):not(.disabled).active, .btn.btn-secondary:not(:disabled):not(.disabled).active:hover, .btn.btn-secondary:not(:disabled):not(.disabled).active:not(:disabled):not(.disabled):active, .btn.btn-secondary:not(:disabled):not(.disabled).active:not(:disabled):not(.disabled).active {
  background-color: #f4d86c;
}
.btn.btn-secondary.btn-sm, .btn-group-sm > .btn.btn-secondary, .btn.btn-secondary:not(:disabled):not(.disabled):active.btn-sm, .btn-group-sm > .btn.btn-secondary:not(:disabled):not(.disabled):active, .btn.btn-secondary:not(:disabled):not(.disabled).active.btn-sm, .btn-group-sm > .btn.btn-secondary:not(:disabled):not(.disabled).active {
  height: auto;
  line-height: 2em;
}
.btn:focus, .btn.focus, .btn:active, .btn.active, .btn.btn-primary:focus, .btn.btn-primary.focus, .btn.btn-primary:active, .btn.btn-primary.active, .btn.btn-secondary:focus, .btn.btn-secondary.focus, .btn.btn-secondary:active, .btn.btn-secondary.active {
  box-shadow: none;
  outline: none;
}

.btn-link {
  color: #23515e;
}
.btn-link:hover {
  color: #1f4753;
}

.fa-btn {
  margin-right: 6px;
}

.btn-icon {
  background-color: transparent;
}
form .btn-icon {
  min-width: auto;
}

.action-button {
  position: relative;
  z-index: 2;
  display: block;
  margin-top: -0.5rem;
  width: 200px;
}

.expand-button {
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  z-index: 10;
}
@media (max-width: 991.98px) {
  .expand-button {
    box-sizing: border-box;
    position: absolute;
    transform: rotate(-45deg);
    right: 20px;
    top: 24px;
    height: 20px;
    width: 20px;
  }
  .expand-button::before {
    content: "";
    width: 100%;
    height: 100%;
    border-width: 2px 2px 0 0;
    border-style: solid;
    border-color: #23515e;
    display: block;
  }
  .expand-button:after {
    content: "";
    float: left;
    position: relative;
    top: -100%;
    width: 100%;
    height: 100%;
    border-width: 0;
    border-style: solid;
    border-color: #23515e;
  }
  .collapsed .expand-button {
    top: 30px;
  }
  .collapsed .expand-button::before {
    border-width: 0 0 2px 2px;
  }
}
@media (min-width: 992px) {
  .expand-button {
    box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.2);
    background-color: #f5dc7a;
    height: 54px;
    width: 54px;
    top: 52%;
    right: 0px;
  }
  .expand-button::after {
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    content: "";
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    position: absolute;
    right: 24px;
    top: 20px;
    width: 16px;
    height: 16px;
  }
  .expanded .expand-button::after {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    right: 18px;
  }
  .main-category-right .expand-button {
    left: 0px;
  }
  .main-category-right .expand-button::after {
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    content: "";
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    position: absolute;
    right: 18px;
    top: 20px;
    width: 16px;
    height: 16px;
  }
  .main-category-right.expanded .expand-button::after {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    left: 19px;
  }
}

.accordion-title {
  color: #23515e;
  margin-top: 30px;
  padding-left: 25px;
  position: relative;
}

.accordion-body {
  display: block;
  padding-left: 25px;
}

.accordion-icon {
  color: #23515e;
  float: right;
  position: relative;
  top: 3px;
}
.accordion-icon.accordion-icon-down {
  display: none;
}
.accordion-icon.accordion-icon-up {
  display: inline-block;
}
.collapsed .accordion-icon.accordion-icon-down {
  display: inline-block;
}
.collapsed .accordion-icon.accordion-icon-up {
  display: none;
}

.btn.back-button-icon {
  border-radius: 100%;
  box-shadow: none;
  height: 28px;
  line-height: 28px;
  padding: 0;
  width: 28px;
}

.collapsable .when-expanded {
  display: block;
}
.collapsable .when-collapsed {
  display: none;
}
.collapsable.collapsed .when-expanded {
  display: none;
}
.collapsable.collapsed .when-collapsed {
  display: block;
}

.icon-play {
  color: #ffffff;
  left: calc(50% - 20px);
  position: absolute;
  top: calc(50% - 20px);
  z-index: 100;
}

.radio-color {
  border-radius: 20px;
  box-shadow: 0 0px 0px 1px #b5c2c6, 0 0px 0px 2px white inset;
  height: 20px;
  left: -3px;
  position: absolute;
  top: 1px;
  width: 20px;
}
.active > .radio-color {
  border: 2px solid #23515e;
}
.active > .radio-color ~ span {
  font-weight: bold;
}
.radio-color.default {
  background-color: white;
}
.active > .radio-color.default {
  background-color: #23515e;
  box-shadow: 0 0px 0px 4px white inset;
}

.radio-stack .radio-text {
  padding-left: 5px;
}
.radio-stack .radio-text img {
  margin: -2px 5px 0 0;
}

.radio-wrapper {
  width: 100%;
}
.radio-wrapper input {
  margin-right: 3px;
}
.radio-wrapper .radio__hide-option {
  display: none;
}
.radio-wrapper .radio__hide-option + label {
  display: none;
}
.radio-wrapper label {
  margin-right: 18px;
  position: relative;
}
.radio-wrapper label.btn {
  background-color: #eff2f2;
  border: 1px solid rgba(35, 81, 94, 0.6);
  border-radius: 3px;
  height: 50px;
  line-height: 2.5em;
  margin: 0;
}
.radio-wrapper label.btn .radio-color {
  display: none;
}
.radio-wrapper label.btn.active {
  background-color: #e4e9e9;
}
.radio-stack .radio-wrapper {
  display: block;
}
.radio-stack .radio-wrapper label {
  display: block;
}

.inline-radio-buttons {
  float: left;
  width: 100%;
}
.inline-radio-buttons .radio-color {
  display: none;
}
.inline-radio-buttons .radio-wrapper {
  float: left;
  margin: 0 0.5em;
  position: relative;
  width: auto;
}
.inline-radio-buttons .radio-wrapper:after {
  background-color: #eff2f2;
  border: 1px solid #23515e;
  border-radius: 20px;
  content: "";
  height: calc(100% - 4px);
  left: 2px;
  position: absolute;
  top: 2px;
  width: calc(100% - 4px);
  z-index: 0;
}
.inline-radio-buttons label {
  background-color: transparent;
  border: 1px solid transparent;
  color: #666666;
  margin: 0 -10px;
  padding: 0.5em 1.5em;
  position: relative;
  z-index: 1;
}
.inline-radio-buttons label:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.inline-radio-buttons label:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.inline-radio-buttons label.active {
  background-color: #ffffff;
  border-color: #23515e;
  border-radius: 20px;
  color: #2b2b2b;
}
.inline-radio-buttons input[type=radio] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.nav-tabs {
  border-bottom: none;
  font-size: 18px;
  margin-bottom: 2rem;
}
.nav-tabs .nav-item {
  margin: 0 1.5rem -3px 0;
}
.nav-tabs .nav-item .title {
  font-size: 18px;
  font-weight: normal;
  margin: 0;
}
.nav-tabs .nav-link {
  border: none;
  padding: 0.5rem 0;
}
.nav-tabs .nav-link.active {
  background-color: transparent;
  color: #23515e;
  font-weight: bold;
  position: relative;
}
.nav-tabs .nav-link.active:after {
  background-color: #f5dc7a;
  bottom: 0;
  content: "";
  height: 3px;
  left: 0;
  position: absolute;
  width: 100%;
}

table td {
  word-break: break-word;
}

.el-table td,
.el-table th {
  padding: 6px 0;
}
.el-table .el-table__expanded-cell[class*=cell] {
  padding: 0;
}
.el-table .el-table__header {
  width: 100% !important;
}
.el-table .el-table__body table {
  margin: 0;
}
.el-table .el-table__empty-block {
  width: 100% !important;
}
.no-word-break .el-table .cell {
  word-break: normal;
}

.el-table__row.default {
  background-color: #eff2f2;
}

.stripped-table .el-table th.is-leaf {
  border: 0;
}
.stripped-table .el-table td {
  border-bottom: 0;
  padding: 0;
}
.stripped-table .el-table td table th,
.stripped-table .el-table td table td {
  padding: 3px;
}
.stripped-table .el-table .cell {
  padding-bottom: 20px;
}
.stripped-table .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: transparent;
}
.stripped-table .card .table thead th {
  border-top: 0;
}

.cell-slim td {
  border: none;
  padding-bottom: 0.2rem;
  padding-top: 0.2rem;
}

.flash {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: fixed;
  top: 85px;
  right: 10px;
  max-height: 400px;
  width: 300px;
  -webkit-perspective: 400px;
  perspective: 400px;
  z-index: 1080;
}
@media (max-width: 991.98px) {
  .flash {
    bottom: 85px;
    top: auto;
    width: calc(100% - 20px);
  }
}
.flash .flash__message {
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.2);
  color: #2b2b2b !important;
  background-color: white !important;
  border: none !important;
  width: 300px;
  -webkit-transition: all 500ms;
  -o-transition: all 500ms;
  transition: all 500ms;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: "Oxygen", sans-serif;
  font-size: 13px;
  line-height: 130%;
  position: relative;
  padding-left: 54px;
}
.flash .flash__message::before {
  border-radius: 2px;
  content: "";
  height: calc(100% - 10px);
  left: 5px;
  position: absolute;
  top: 5px;
  width: 4px;
}
.flash .flash__message::after {
  border-radius: 50%;
  color: white;
  display: inline-block;
  font-family: "Font Awesome 5 Pro";
  font-size: 11px;
  height: 20px;
  left: 22px;
  line-height: 22px;
  margin-top: -10px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 20px;
}
.flash .flash__message.success::after {
  content: "";
}
.flash .flash__message.error::after {
  content: "";
  font-weight: bold;
}
.flash .flash__message.info::after {
  content: "";
}
.flash .flash__message.success::before, .flash .flash__message.success::after {
  background-color: #59cca8;
}
.flash .flash__message.error::before, .flash .flash__message.error::after {
  background-color: #dc3545;
}
.flash .flash__message.info::before, .flash .flash__message.info::after {
  background-color: #facf5a;
}
@media (max-width: 991.98px) {
  .flash .flash__message {
    width: 100%;
  }
}
.flash .flash__close-button {
  color: #2b2b2b;
  height: 26px;
  margin-top: -14px;
  top: 50%;
}

.flash-enter,
.flash-leave-to {
  opacity: 0;
  -webkit-transform: rotateX(-30deg) scale(0.88) translateY(-30px);
  transform: rotateX(-30deg) scale(0.88) translateY(-30px);
}

.flash-leave-active {
  position: absolute;
}

.form-tiny-layout button[type=submit] {
  width: 100%;
}

.form-group {
  clear: both;
  position: relative;
}
.form-group label.control-label {
  display: none;
}
.form-group.form-group-treeselect label.control-label {
  display: inline-block;
}

.form-control[type=number] {
  padding-right: 0.75rem;
}
.form-control.valid {
  border-color: #c5edbe;
  background-image: none;
}
.form-control.invalid {
  border-color: #dc3545;
  background-image: none;
}
@media (max-width: 991.98px) {
  .form-control {
    font-size: 16px !important;
  }
}

.invalid .form-control,
.radio-wrapper.invalid label.btn {
  border-color: #dc3545;
}

.valid .form-control {
  border-color: #c5edbe;
}

input[type=text],
input[type=email],
input[type=password],
input[type=number],
input[type=file],
input[type=url],
select {
  height: 50px;
}

label.required-field:after {
  content: " * ";
  color: #dc3545;
}

.form-group-inner {
  position: relative;
}
.form-group-hidden .form-group-inner + .required-indicator {
  display: none !important;
}
.form-group-inner + .required-indicator {
  display: none;
  color: #facf5a;
  position: absolute;
  right: 10px;
  top: 13px;
}
.form-group-textarea .form-group-inner + .required-indicator {
  top: 40px;
}
.form-group-radio .form-group-inner + .required-indicator {
  top: 0px;
}
.form-group-inner + .required-indicator .required-indicator___valid {
  color: #c5edbe;
}
.form-group-inner + .required-indicator .required-indicator___invalid {
  color: #dc3545;
}
.display-required-indicator .form-group-inner {
  width: calc(100% - 50px);
}
.display-required-indicator .form-group-inner.validate + .required-indicator {
  display: block;
}

textarea {
  padding: 12px;
  border: solid 1px #000000;
}
textarea.form-control {
  padding-bottom: 0.85rem;
}

.form-group-textarea.no-label > div > label {
  display: none;
}

.form-group-hidden {
  margin: 0;
}
.form-group-hidden .vfl-label {
  display: none;
}

.vfl-has-label .vfl-label {
  font-size: 1em;
  text-align: left;
  top: 1em;
  transition-duration: 0.1s;
}
.vfl-has-label .vfl-label-on-input, .form-group-date .vfl-has-label .vfl-label {
  color: rgba(35, 81, 94, 0.6);
  font-size: 0.8em;
  opacity: 1;
  padding-left: 0.75rem;
  top: 0.4em;
}
.vfl-has-label .vfl-label + input {
  font-size: 100%;
}
.vfl-has-label .vfl-label-on-input + input {
  padding-bottom: 0rem;
  padding-top: 1em;
}
.vfl-has-label .vfl-label-on-input + textarea {
  padding-bottom: 0rem;
  padding-top: 1.4em;
}

.invalid-feedback {
  bottom: -12px;
  left: 0;
  line-height: 1em;
  overflow: hidden;
  position: absolute;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.form-group-treeselect .invalid-feedback, .form-group-select .invalid-feedback, .form-group-number .invalid-feedback, .form-group-text .invalid-feedback, .form-group-email .invalid-feedback, .form-group-url .invalid-feedback {
  bottom: auto;
  margin-top: 0.1rem;
  top: 50px;
}

vue-ckeditor {
  height: 180px;
  font-size: 0.9em;
}

.form-group-file label.custom-file-label {
  border-color: #23515e;
  border-width: 1px;
  line-height: 2.5em;
  text-align: center;
  width: 108px;
}
.form-group-file label.custom-file-label:after {
  display: none;
}
.form-group-file .vfl-has-label .vfl-label {
  background-color: #ffffff;
  opacity: 1;
  padding-left: 110px;
  top: 0;
  height: 100%;
  border: 1px solid rgba(35, 81, 94, 0.6);
  border-radius: 3px;
  line-height: 50px;
}
.form-group-file .vfl-has-label .vfl-label-on-input {
  background-color: transparent;
}
.form-group-file input[type=file] {
  opacity: 0;
}

.boxed-image img {
  border: 1px solid rgba(35, 81, 94, 0.6);
  border-radius: 3px;
  padding: 10px;
  margin: 5px 0;
}

.form-group .mx-datepicker {
  width: 100%;
}
.form-group .mx-datepicker input.form-control {
  background-color: rgba(255, 255, 255, 0.05);
  padding-bottom: 0rem;
  padding-top: 1em;
}
.form-group .mx-datepicker .mx-input-append {
  background-color: rgba(255, 255, 255, 0.05);
}
.form-group .mx-datepicker .mx-calendar-icon {
  display: none;
}

.form-group-address-wrapper {
  position: relative;
}

.form-group-address {
  background-color: #eff2f2;
  margin-bottom: 9px;
  padding: 9px;
  position: relative;
}
.form-group-address .form-group-address__inner {
  max-width: 250px;
}
.form-group-address .form-group-address__action-button {
  left: 280px;
  position: absolute;
  top: 9px;
}
.form-group-address .form-group-address__change-button {
  left: 250px;
}
.form-group-address .form-group-address__create-button {
  display: block;
  margin-top: 9px;
}
.form-group-address .form-group-hidden {
  position: static;
}

.form-field__address {
  position: static;
}
.form-field__address .form-group-inner {
  margin-top: -59px;
  z-index: -1;
}

.form-group-submit {
  margin-top: 20px;
  text-align: center;
}

.helper__text {
  font-size: 0.85em;
  margin: 0;
}
.helper__text.near-field {
  line-height: 1.2em;
  margin-bottom: 15px;
  margin-top: -5px;
}

.hide-clear-button .vue-treeselect__x-container {
  display: none;
}

.fake-dropdown-wrapper {
  position: relative;
}
.fake-dropdown-wrapper .fake-dropdown {
  border-radius: 3px;
  box-shadow: 0px 1px 0 0 rgba(35, 81, 94, 0.6);
  left: 0;
  max-height: 300px;
  overflow: auto;
  position: absolute;
  top: 50px;
  width: 100%;
  z-index: 100;
}

@media (max-width: 575.98px) {
  .recaptcha-wrapper {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

#form-field__ordered-images {
  margin-top: -50px;
  z-index: -1;
}
#form-field__ordered-images input[type=text] {
  opacity: 0;
}

#reset-email-form button {
  line-height: 1;
  vertical-align: baseline;
}

.form-group-select .form-group-inner > label {
  color: rgba(35, 81, 94, 0.6);
  font-size: 0.8em;
  opacity: 1;
  padding-left: 0.75rem;
  top: 0.4em;
  display: block;
  position: absolute;
}
.form-group-select select {
  padding-bottom: 0rem;
  padding-top: 1em;
}

.form-control.vue-treeselect {
  padding: 0;
}
.form-control.vue-treeselect .vue-treeselect__control {
  border: none;
  height: 48px;
}

.vue-treeselect--single .vue-treeselect__control {
  background-color: transparent;
}
.vue-treeselect--single .vue-treeselect__control .vue-treeselect__single-value,
.vue-treeselect--single .vue-treeselect__control .vue-treeselect__placeholder {
  height: 50px;
  line-height: 50px;
}
.vue-treeselect--single .vue-treeselect__control .vue-treeselect__input {
  box-shadow: none;
  height: 50px;
}

.vue-treeselect.vue-treeselect--focused:not(.vue-treeselect--open) .vue-treeselect__control {
  border-color: transparent;
  box-shadow: none;
}
.vue-treeselect .vue-treeselect__option--selected {
  background-color: #d3dbdb !important;
}
.vue-treeselect .image {
  display: inline-block;
  height: 48px;
  line-height: 36px;
  padding: 5px 0;
  text-align: center;
  vertical-align: top;
  width: 80px;
}
.vue-treeselect .image img {
  max-height: 100%;
}
.vue-treeselect .text {
  display: inline-block;
  height: 48px;
  line-height: 48px;
  margin-left: 9px;
  vertical-align: top;
}

#product-page__shop .vue-treeselect--single .vue-treeselect__control .vue-treeselect__input {
  padding-left: 90px;
}

.dropdown-menu {
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.2);
  margin-top: 0.5rem;
}

.dropdown-item {
  color: #23515e;
}

.dropdown-item-other hr {
  margin: 0.25rem 0rem;
}
.dropdown-item-other .title {
  font-weight: bold;
  margin: 10px 0 3px;
}

.nav-item > a {
  color: #23515e;
}

.quick-add-product__button a {
  background-color: #23515e;
  border-left: 1px solid #696969;
  color: #ffffff;
  line-height: 1em;
  opacity: 0.8;
  padding-left: 6px;
  padding-right: 6px;
}

#main-menu {
  display: none;
}
#main-menu .dropdown-menu {
  width: 100vw;
}
#main-menu .nav-link {
  padding-left: 9px;
  padding-right: 9px;
}
#main-menu .main-link {
  border-bottom: 1px solid #d2dadc;
  font-size: 0.9rem;
  overflow: hidden;
  margin-bottom: 0;
  text-overflow: ellipsis;
}
#main-menu .main-link a {
  display: block;
  padding: 16px 10px;
}
#main-menu .sub-link {
  font-size: 0.9rem;
  margin-bottom: 1.2rem;
}
@media (max-width: 991.98px) {
  #main-menu {
    position: relative;
    z-index: 1020;
    background-color: #ffffff;
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 40px;
    width: 100%;
    overflow: hidden;
  }
  .menu-opened #main-menu {
    display: block;
  }
  #main-menu #category-menu {
    padding-bottom: 115px;
  }
}

.mobile_menu_hamburger_wrapper {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1040;
}
.mobile_menu_hamburger_wrapper #main-menu__hamburger__mobile {
  color: #dc917f;
  display: none;
  font-weight: bold;
  float: right;
  text-align: center;
}
.mobile_menu_hamburger_wrapper #main-menu__hamburger__mobile .text {
  display: inline-block;
  position: relative;
  right: -20px;
  top: 2px;
}
.mobile_menu_hamburger_wrapper #main-menu__hamburger__mobile .icon {
  display: inline-block;
  height: 75px;
  line-height: 75px;
  width: 75px;
}
.mobile_menu_hamburger_wrapper #main-menu__hamburger__mobile i {
  vertical-align: middle;
}
@media (max-width: 991.98px) {
  .mobile_menu_hamburger_wrapper #main-menu__hamburger__mobile {
    display: block;
  }
}
.mobile_menu_hamburger_wrapper #main-menu__hamburger__mobile .main-menu__hamburger__mobile__opened {
  display: none;
}
.menu-opened .mobile_menu_hamburger_wrapper #main-menu__hamburger__mobile .main-menu__hamburger__mobile__opened {
  display: inline-block;
}
.menu-opened .mobile_menu_hamburger_wrapper #main-menu__hamburger__mobile .main-menu__hamburger__mobile__closed {
  display: none;
}

#secondary-menu {
  display: inline-block;
  margin-top: 0px;
}
#secondary-menu .dropdown-item {
  min-width: 230px;
}
#secondary-menu .dropdown-toggle::after {
  display: none;
}
#secondary-menu i {
  color: #23515e;
}
#secondary-menu .profile-image {
  position: relative;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
}
#secondary-menu #profile-default-image {
  background-color: #23515e;
}
#secondary-menu #profile-default-image .icon {
  color: #ffffff;
}

#lang-menu {
  display: inline-block;
}
#lang-menu .dropdown-toggle::after {
  content: none;
}

.menu-opened #app {
  overflow: visible;
}
.menu-opened .page {
  overflow: hidden;
}
.menu-opened #main-menu {
  display: block;
}
.menu-opened #checkout__steps {
  display: none;
}

#admin-menu {
  position: relative;
  z-index: 1;
  background-color: rgba(242, 242, 242, 0.6);
  height: 30px;
  position: absolute;
  left: 0;
  width: 100%;
}
#admin-menu .nav {
  float: right;
}
#admin-menu .nav .nav-item.dropdown > a {
  line-height: 1em;
}
.menu-opened #admin-menu {
  display: none;
}
@media (max-width: 991.98px) {
  #admin-menu {
    top: 40px;
  }
}

#main-menu .mm-menu a,
#main-menu .mm-menu a span {
  color: #23515e;
  font-weight: bold;
}
#main-menu .mm-menu .mm-btn_next:after {
  border-color: #23515e;
  height: 4px;
  width: 4px;
}
#main-menu .mm-menu .mm-btn_prev:before {
  border-color: #23515e;
}
#main-menu .mm-menu.mm-menu_multiline .mm-listitem__text {
  font-size: 0.95em;
}
#main-menu .mm-menu .mm-navbar__btn {
  width: 35px;
}
#main-menu .mm-menu .mm-navbar__btn::before {
  left: 15px;
}
#main-menu .mm-menu .mm-navbar__title {
  font-size: 1.2em;
  font-weight: bold;
}
#main-menu .mm-menu .mm-panel:first-of-type .mm-navbar__title span {
  color: #2b2b2b;
}
#main-menu .mm-menu .mm-panel:not(:first-of-type) .mm-navbar__title {
  justify-content: left;
}
#main-menu .mm-menu .mm-panel:not(:first-of-type) .mm-listitem:first-of-type {
  float: right;
  margin-top: -45px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}
#main-menu .mm-menu .mm-panel:not(:first-of-type) .mm-listitem:first-of-type::after {
  content: none;
}
#main-menu .mm-menu .mm-panel:not(:first-of-type) .mm-navbar__btn:not(.mm-hidden) + .mm-navbar__title:last-child {
  padding-right: 120px;
}

.modal-outer-wrapper > div {
  z-index: auto !important;
  color: #2b2b2b;
}

.modal {
  padding: 0 !important;
  z-index: 1070;
}

.modal-backdrop {
  background-color: black;
  opacity: 0.6;
  z-index: 1060;
}

.plane-modal-close-button {
  font-size: 1.2rem;
  position: absolute;
  right: 17px;
  top: 1.2rem;
  text-shadow: none;
  z-index: 1;
}
.plane-modal-close-button i {
  background-color: #23515e;
  border-radius: 100%;
  box-shadow: none;
  color: white;
  font-weight: normal;
  height: 30px;
  line-height: 30px;
  width: 30px;
}
@media (max-width: 991.98px) {
  .plane-modal-close-button i {
    color: #ffffff;
  }
}
@media (max-width: 991.98px) {
  .plane-modal-close-button {
    position: relative;
    right: 0px;
    top: 0px;
  }
}

@media (min-width: 992px) {
  .modal-dialog {
    margin: 1.7rem auto;
  }
  .modal-dialog.modal-dialog-center {
    min-height: calc(100% - 3.4rem);
  }
}
.modal.show .modal-dialog > div {
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 3px;
}
.modal.show .modal-dialog button[type=submit],
.modal.show .modal-dialog button[type=button],
.modal.show .modal-dialog button[type=button]:active,
.modal.show .modal-dialog .form-group-button {
  margin-bottom: 0;
}
.modal.show .modal-dialog button.close {
  margin-top: -22px;
  opacity: 1;
  z-index: 1;
}
@media (max-width: 991.98px) {
  .modal.show .modal-dialog {
    align-items: start;
    border-radius: 0;
    box-shadow: none;
    max-width: none;
    margin: 0;
    width: 100vw;
  }
  .modal.show .modal-dialog > div {
    border-radius: 0;
    box-shadow: none;
  }
  .modal.show .modal-dialog .modal-header {
    color: #ffffff;
    width: 100vw;
  }
  .modal.show .modal-dialog .modal-body {
    width: 100vw;
  }
}

@media (max-width: 991.98px) {
  #cart-popup {
    bottom: 40px;
    overflow: hidden;
    z-index: 1061;
  }
  #cart-popup.fade {
    opacity: 1;
  }
  #cart-popup.fade .modal-dialog {
    transform: translate(0, 100%);
  }
  #cart-popup.show .modal-dialog {
    transform: translate(0, 0);
  }
  #cart-popup .modal-dialog {
    align-items: flex-end;
    height: 100%;
    margin: 0;
  }
  #cart-popup .modal-dialog > div {
    border-radius: 3px 3px 0 0;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  }
  #cart-popup ~ .modal-backdrop.show {
    bottom: 40px;
    opacity: 0.9;
    z-index: 1025;
  }
}

.modal-dialog header {
  border-bottom: none;
}
.modal-dialog header .modal-title {
  font-size: 20px;
  line-height: 1.8em;
  text-align: center;
  width: 100%;
}
.modal-dialog .media-swiper-thumbnails {
  border-top: 1px solid #d2dadc;
  padding: 10px 0 5px;
}
@media (max-width: 991.98px) {
  .modal-dialog .modal-header {
    border: none;
    height: 50px;
  }
  .modal-dialog .modal-header .modal-title {
    display: none;
  }
}

.product__header-prices {
  display: none;
  margin: -60px -11px 10px;
  padding: 10px;
  text-align: left;
}
.product__header-prices .product__header-prices__title {
  font-size: 18px;
  margin: 0;
}
.product__header-prices .product__header-prices__description {
  color: rgba(35, 81, 94, 0.6);
  font-style: italic;
  margin-bottom: 3px;
}
@media (max-width: 575.98px) {
  .product__header-prices .product__header-prices__description {
    transform: scale(0.85);
    transform-origin: top left;
  }
}
@media (max-width: 575.98px) {
  .product__header-prices .product-price-wrapper {
    transform: scale(0.85);
    transform-origin: top left;
  }
}
@media (max-width: 991.98px) {
  .product__header-prices {
    display: block;
  }
}

.ellipsis-fix-two-lines, .block-ellipsis {
  display: block;
  display: -webkit-box;
  font-size: 16px;
  max-height: 48px;
  line-height: 1.5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.block-ellipsis {
  max-width: 90%;
  -webkit-line-clamp: 1;
}

.ellipsis-one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis-fix-two-lines {
  height: 48px;
  -webkit-line-clamp: 2;
}

.cookie-consent {
  width: 100%;
  padding: 0.7rem;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
}
.cookie-consent .cookie-consent-wrapper {
  max-width: 1640px;
  margin: 0 auto;
}
.cookie-consent .cookie-consent-wrapper .cookie-consent__message {
  float: left;
  width: 80%;
  text-align: left;
  color: #fff;
  font-size: 11px;
  font-weight: 100;
}
@media (max-width: 991.98px) {
  .cookie-consent .cookie-consent-wrapper .cookie-consent__message {
    width: 100%;
  }
}
.cookie-consent .cookie-consent-wrapper .cookie-consent__message p {
  margin: 0;
}
.cookie-consent .cookie-consent-wrapper .cookie-consent-buttons {
  float: left;
  text-align: right;
  width: 20%;
}
@media (max-width: 991.98px) {
  .cookie-consent .cookie-consent-wrapper .cookie-consent-buttons {
    float: none;
    text-align: center;
    width: 100%;
  }
}
.cookie-consent .cookie-consent-wrapper .cookie-consent-buttons .cookie-consent__agree, .cookie-consent .cookie-consent-wrapper .cookie-consent-buttons .cookie-consent__agree:active,
.cookie-consent .cookie-consent-wrapper .cookie-consent-buttons .cookie-consent__decline,
.cookie-consent .cookie-consent-wrapper .cookie-consent-buttons .cookie-consent__decline:active {
  margin: 0.5rem 0.5rem 0;
  width: 110px;
  font-size: 12px;
  line-height: 21px;
  height: 35px;
  padding-top: 0.375rem;
}

.highlighted-block-rounded, .highlighted-block {
  background-color: #eff2f2;
  margin-bottom: 9px;
  padding: 1rem;
  transition: background-color 0.2s;
}
@media (max-width: 991.98px) {
  .mobile-full-width.highlighted-block-rounded, .mobile-full-width.highlighted-block {
    border-radius: 0;
    margin-left: -10px;
    margin-right: -10px;
  }
}

.highlighted-block-rounded {
  border-radius: 3px;
}

.email__tokens {
  word-break: break-word;
}

.accordion-styled .accordion-title {
  border-bottom: 1px solid #d2dadc;
  font-weight: bold;
  margin: 0;
  padding: 0;
}
.accordion-styled .accordion-title a {
  color: #dc917f;
  display: block;
  padding: 15px;
}
.accordion-styled .accordion-title a.collapsed {
  color: #23515e;
}
.accordion-styled .accordion-body {
  background-color: #eff2f2;
  padding: 15px;
}

.u-normal-text {
  font-size: 1rem !important;
  line-height: 1.6 !important;
}

.text-default {
  color: #2b2b2b;
}

[v-cloak] {
  display: none;
}

@media (min-width: 340px) {
  .d-xs-inline {
    display: inline !important;
  }
}

.gradient-background {
  background-image: linear-gradient(to bottom, white, #eff2f2 99%);
  border-bottom: 1px solid #dee2e6;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slidetoggle-enter-active,
.slidetoggle-leave-active {
  -webkit-transition: max-height 0.5s ease-in-out;
  transition: max-height 0.5s ease-in-out;
  max-height: 3000px;
  overflow: hidden;
}

.slidetoggle-enter,
.slidetoggle-leave-to {
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
  max-height: 0;
}

.product__brand {
  background-color: rgba(255, 255, 255, 0.85);
  border: 1px solid #d2dadc;
  border-radius: 14px;
  display: inline-block;
  font-size: 12px;
  height: 28px;
  left: 4px;
  line-height: 1.7rem;
  max-width: 70%;
  position: absolute;
  top: 4px;
  z-index: 1;
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .thumbnail .product__brand {
    transform-origin: top left;
    transform: scale(0.85);
  }
}
.product__brand .product__brand__logo {
  border: 1px solid #d2dadc;
  left: -1px;
  top: -1px;
}

.product__brand__logo {
  border-radius: 50%;
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  float: left;
  height: 28px;
  position: relative;
  width: 28px;
}

.product__brand__name {
  padding: 0 10px 0 7px;
}

.product-image {
  height: auto;
  border-radius: 2px;
  width: 100%;
}

.thumbnail-brand-image {
  pointer-events: none;
  background-color: white;
  position: absolute;
  left: 16px;
  top: 19px;
  max-width: 150px;
  width: 25%;
  z-index: 1;
}

.product_price_wrapper .product__price {
  font-size: 1.35em;
  font-weight: bold;
  margin: 0 6px 0 0;
}
.product_price_wrapper .product__price .currency {
  font-size: 0.8em;
}
.product_price_wrapper .product__discount {
  border-radius: 3px;
  background-color: #59cca8;
  display: inline-block;
  font-size: 0.9em;
  font-weight: bold;
  line-height: 1rem;
  margin: 0 6px 0 0;
  padding: 4px 6px;
  vertical-align: middle;
  margin-top: 0;
  color: white;
}
.product_price_wrapper .product__original-price {
  background-color: white;
  display: inline-block;
  font-size: 16px;
  vertical-align: middle;
}
.product_price_wrapper .product__original-price .value {
  text-decoration: line-through;
}
.product-image-wrapper {
  border-radius: 3px;
  position: relative;
}

.product-badges {
  bottom: 2px;
  left: -2px;
  pointer-events: none;
  position: absolute;
  z-index: 1;
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .thumbnail .product-badges {
    transform-origin: bottom left;
    transform: scale(0.85);
  }
}

.badge-product-label {
  border-radius: 0 3px 3px 0;
  color: white;
  clear: both;
  float: left;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 5px;
  padding: 3px 10px;
  position: relative;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
}
.badge-product-label:before {
  width: 0;
  height: 0;
  border-top: 2px solid transparent;
  border-right: 2px solid #ac472f;
  border-bottom: 2px solid transparent;
  content: "";
  height: 2px;
  position: absolute;
  width: 2px;
  left: 0;
  top: -2px;
  z-index: -1;
}

.top-product-label {
  background-color: #dc917f;
}

.multi-variation-product-label {
  background-color: #23515e;
}
.multi-variation-product-label:before {
  border-right-color: #000101;
}

.product-discount-label {
  background-color: #59cca8;
}
.product-discount-label:before {
  border-right-color: #277f63;
}

#seller-badges {
  left: -2px;
  margin-bottom: -10px;
  margin-top: -5px;
  top: 10px;
  z-index: 99;
}

.advice-specialist-product-label {
  background-color: #dc917f;
}

.add-to-cart-icon {
  float: right;
  margin-top: -5px;
}
.add-to-cart-icon .product__add-to-cart__button-wrapper button {
  background-color: #23515e;
  border-radius: 100%;
  height: 40px;
  width: 40px;
}
.add-to-cart-icon .product__add-to-cart__button-wrapper button i {
  color: #dc917f;
  margin: 10px 2px 0 0;
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .thumbnail .add-to-cart-icon .product__add-to-cart__button-wrapper button {
    transform-origin: top right;
    transform: scale(0.85);
  }
}

.favorites-icon {
  right: 4px;
  position: absolute;
  top: 4px;
  z-index: 1;
}
.favorites-icon button[type=submit] {
  border-radius: 50%;
  background-color: #23515e;
  border: none;
  height: 28px;
  margin: 0;
  min-width: auto;
  width: 28px;
}
.favorites-icon i {
  color: white;
  margin: 2px auto 0;
  display: block;
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .thumbnail .favorites-icon {
    right: 5px;
    top: 5px;
  }
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .thumbnail .favorites-icon {
    transform-origin: top right;
    transform: scale(0.85);
  }
}

.el-table td .cell {
  overflow: visible;
}
.el-table td .cell .product__brand__logo {
  border: 1px solid #d2dadc;
  left: -1px;
  top: -1px;
}

.product.thumbnail {
  margin: 10px 0 40px;
  position: relative;
}
.product.thumbnail .product__price {
  font-size: 1.3em;
  font-weight: bold;
}
.product.thumbnail .product__title {
  display: block;
  font-size: 1.15rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin-top: 8px;
  position: relative;
}
.product.thumbnail .product__title a {
  color: #23515e;
}
.product.thumbnail .product__short-description {
  color: rgba(35, 81, 94, 0.6);
  font-size: 0.9em;
  font-style: italic;
}
.product.thumbnail .product__discount {
  font-size: 0.8em;
  padding: 3px 4px;
}
.product.thumbnail .product__original-price {
  font-size: 0.8em;
}
.product.thumbnail .product__add-to-cart__button-wrapper button svg {
  display: inline-block;
  margin: 0;
  vertical-align: text-bottom;
}

.product-form-title {
  text-align: center;
}

#product__category-field__wrapper > label {
  display: none;
}

.product__property {
  margin: 6px 0;
}

.product__property__check-icon {
  color: #59cca8;
  display: inline-block;
  margin-right: 3px;
}

.product__details {
  margin: 1rem 0;
}
.product__details .product__attribute__color {
  margin-bottom: -4px;
}

.product__details__label {
  font-weight: bold;
}

.inline {
  margin-bottom: 0;
}
.inline .product__details__label {
  font-weight: normal;
}
.inline .product__details__value {
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
}

.additional {
  margin-top: 0;
}
.additional .product__details__label {
  display: none;
}

.product__files {
  margin: 9px 0;
  padding: 0;
}
.product__files li {
  display: block;
  list-style: none;
  margin-bottom: 6px;
}

.product__attribute__color {
  border-radius: 50%;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  border: 1px solid rgba(90, 90, 90, 0.4);
  height: 30px;
  position: absolute;
  left: -2px;
  top: -1px;
  width: 30px;
}

.product__attribute {
  margin-bottom: 35px;
}
.product__attribute .placeholder-graphics {
  background-color: #eff2f2 !important;
  color: #eff2f2 !important;
  text-indent: -9999px;
  width: 130px;
}
.product__attribute .product__attribute__label {
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
}
.product__attribute .product__attribute__option {
  background-color: transparent;
  border: 1px solid #d2dadc;
  border-radius: 30px;
  display: inline-block;
  height: 30px;
  line-height: 1.2rem;
  margin-bottom: 3px;
  margin-right: 12px;
  padding: 5px 25px;
  position: relative;
}
.product__attribute .product__attribute__option.active {
  background-color: #23515e;
  border-color: #071114;
  color: white;
}
.product__attribute .product__attribute__option.disabled {
  opacity: 0.25;
}
.product__attribute .product__attribute__option.prefix:not(.active) {
  padding-left: 0;
  text-indent: -9999px;
  width: 30px;
}
.product__attribute .product__attribute__option.prefix.active {
  background-color: white;
  border-color: #d2dadc;
  color: #2b2b2b;
  padding-left: 45px;
}

.price___suffix {
  font-size: 0.8em;
  font-weight: normal;
}

#product-tabs {
  margin-top: 9px;
}
#product-tabs .tab-content .tab-pane {
  display: block;
  margin-bottom: 9px;
}
#product-tabs .tab-content .tab-pane .sub-title {
  margin-bottom: 9px;
  padding: 6px 0;
}

.small-image-thumbnail {
  width: 80px;
}

.seasonal-switch-wrapper {
  display: inline-block;
}
.seasonal-switch-wrapper label.switch {
  display: inline-block;
  vertical-align: top;
  margin: 0;
}
.seasonal-switch-wrapper .text {
  font-size: 13px;
  vertical-align: middle;
}

.modal-content .seasonal-discounts-wrapper {
  display: flex;
}
.modal-content .seasonal-discounts-wrapper .form-group {
  padding-left: 5px;
  padding-right: 5px;
}

.season-discount-block-wrapper {
  margin-bottom: 15px;
}
.season-discount-block-wrapper:last-child {
  margin-bottom: 0;
}

.product__inline .thumbnail .product__image-inner {
  display: inline-block;
  width: 30%;
}
.product__inline .thumbnail .product__info {
  display: inline-block;
  vertical-align: middle;
  width: 70%;
}
.product__inline .thumbnail .product__price {
  float: left;
}
.product__inline .thumbnail .product__discount,
.product__inline .thumbnail .product__original-price {
  vertical-align: text-top;
}

.product__goto-seller__buttons {
  float: right;
  margin-right: 5px;
  margin-top: -5px;
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .thumbnail .product__goto-seller__buttons {
    transform-origin: top right;
    transform: scale(0.85);
  }
}
.product__goto-seller__buttons a {
  border-radius: 50%;
  background-color: #f5dc7a;
  display: block;
  height: 40px;
  width: 40px;
}

#cart-popup .modal-body {
  padding: 0;
  height: 100%;
}
@media (max-width: 991.98px) {
  #cart-popup .modal-header .modal-title {
    display: inline-block;
    line-height: 1.1em;
  }
}

#cart-list {
  overflow: hidden;
}
#cart-list #cart-list__inner {
  max-height: calc(100vh - 350px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 1rem 0;
  text-align: left;
}
#cart-list .cart-list__row {
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
}
#cart-list .cart-list__details__item {
  font-size: 0.9em;
}
#cart-list .cart-list__details__title {
  display: block;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.4em;
  white-space: pre-wrap;
}
#cart-list .cart-list__details__description {
  color: rgba(35, 81, 94, 0.6);
  font-style: italic;
  font-size: 0.8rem;
}
#cart-list .cart-list__details__quantity-price {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 10px;
}
#cart-list .product__price {
  font-size: 1.3rem;
}
#cart-list .cart-list__image {
  width: 100%;
}
#cart-list .cart-list__remove-button {
  border: 1px solid #d2dadc;
  border-radius: 50%;
  height: 28px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: -10px;
  width: 28px;
}
#cart-list .cart-list__remove-button i {
  color: #23515e;
  margin: 0;
}
#cart-list .cart-list__details__seller {
  left: 0;
  margin: 0;
  position: relative;
  top: 0;
  transform: scale(0.75);
  transform-origin: top left;
}

#cart-list__empty {
  padding: 1rem;
}

#cart-list__total {
  font-weight: bold;
}

#cart-list__form {
  padding: 0 1rem 1rem;
  text-align: center;
}
#cart-list__form .payment-methods__block {
  margin-top: 10px;
}
#cart-list__form .form-group {
  margin: 0;
}
#cart-list__form button {
  margin: 0;
  text-transform: uppercase;
  width: 100%;
}
#cart-list__form button svg {
  margin-right: 5px;
  vertical-align: sub;
}
@media (max-width: 991.98px) {
  #cart-list__form {
    border-radius: 3px 3px 0 0;
  }
}

#checkout__cart-preview {
  box-shadow: 0 -0.2rem 0.5rem rgba(0, 0, 0, 0.2);
  border-radius: 3px 3px 0 0;
  bottom: 40px;
  left: 0;
  margin: 0 !important;
  padding: 6px 9px;
  position: fixed;
  width: 100%;
  z-index: 1000;
}
#checkout__cart-preview .label,
#checkout__cart-preview .value {
  display: inline-block;
}
@media (min-width: 992px) {
  #checkout__cart-preview {
    display: none;
  }
}

.product__add-to-cart__button-wrapper .btn,
.product__add-to-cart__secondary .btn {
  line-height: 2.3em;
  min-width: auto;
  padding-bottom: 0;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0;
  white-space: normal;
  width: 100%;
}
.product__add-to-cart__button-wrapper .btn div,
.product__add-to-cart__secondary .btn div {
  display: table;
  margin: auto;
}
.product__add-to-cart__button-wrapper .btn span,
.product__add-to-cart__secondary .btn span {
  display: table-cell;
  line-height: 1.2em;
  vertical-align: middle;
}
.product__add-to-cart__button-wrapper .btn svg,
.product__add-to-cart__secondary .btn svg {
  display: table-cell;
  height: 20px;
  margin-right: 5px;
  vertical-align: text-top;
  width: 20px;
}
.product__add-to-cart__button-wrapper .btn .description,
.product__add-to-cart__secondary .btn .description {
  color: #2b2b2b;
  font-size: 0.7rem;
  font-weight: normal;
  height: auto;
  line-height: 1em;
  margin-top: -4px;
  padding-bottom: 6px;
}
.product__add-to-cart__button-wrapper .btn .description span,
.product__add-to-cart__secondary .btn .description span {
  display: inline;
  vertical-align: top;
}

.product__add-to-cart__button-wrapper span,
.product__goto-seller__secondary span {
  font-size: 14px;
  text-transform: uppercase;
}

#search__toggle-button {
  color: #dc917f;
  cursor: pointer;
  display: none;
  height: 75px;
  line-height: 75px;
  text-align: center;
  width: 44px;
}
#search__toggle-button .search__toggle-button__opened {
  display: none;
}

@media (max-width: 991.98px) {
  .search-liquid {
    width: auto;
    right: 96px;
    max-width: initial;
    top: 4px;
  }
}

#search {
  position: relative;
  z-index: 1030;
}
#search #search__inner {
  border: 1px solid #dc917f;
  border-radius: 5px;
  height: 42px;
  margin-top: -4px;
}
#search input[type=text] {
  border: none;
  height: 40px;
  overflow: hidden;
  padding-bottom: 6px;
  padding-left: 44px;
  padding-right: 50px;
  padding-top: 6px;
  text-overflow: ellipsis;
}
#search input[type=text]::-webkit-input-placeholder {
  color: #a9a9a9;
  white-space: nowrap;
}
#search input[type=text]:-moz-placeholder {
  color: #a9a9a9;
  white-space: nowrap;
}
#search input[type=text]::-moz-placeholder {
  color: #a9a9a9;
  white-space: nowrap;
}
#search input[type=text]:-ms-input-placeholder {
  color: #a9a9a9;
  white-space: nowrap;
}
#search .search-icon {
  color: #dc917f;
  height: 30px;
  left: 9px;
  position: absolute;
  text-align: center;
  top: 8px;
  width: 30px;
}
#search .search-icon i {
  vertical-align: middle;
}
#search .loading-icon,
#search .action-icon {
  background-color: transparent;
  border-radius: 100%;
  box-shadow: none;
  color: #23515e;
  height: 30px;
  left: auto;
  line-height: 30px;
  min-width: auto;
  padding: 0;
  position: absolute;
  right: 6px;
  top: 6px;
  width: 30px;
}
#search .loading-icon {
  color: #666666;
  right: 6%;
  top: 6px;
  left: auto;
  line-height: 28px;
}
@media (max-width: 991.98px) {
  #search #search__toggle-button {
    display: block;
  }
  .search-opened #search #search__toggle-button {
    bottom: 0;
    position: absolute;
    right: 0;
  }
  .search-opened #search #search__toggle-button .search__toggle-button__opened {
    display: inline-block;
    vertical-align: middle;
  }
  .search-opened #search #search__toggle-button .search__toggle-button__closed {
    display: none;
  }
  #search #search__inner {
    display: none;
    bottom: 16px;
    left: 0;
    position: absolute;
    width: calc(100% - 60px);
  }
  .search-opened #search #search__inner {
    display: block;
  }
}

.search__results__section .section-title {
  font-size: 1em;
  margin: 6px 0;
  padding: 6px;
}

.search__results__product {
  border-radius: 3px;
  cursor: pointer;
  padding: 6px;
}
.search__results__product:hover {
  background-color: #eff2f2;
}

.search__results__title {
  font-size: 0.95em;
  font-weight: bold;
  line-height: 1.15em;
  margin-bottom: 3px;
}

.product-category, #search__results .search__results__product-category {
  color: rgba(35, 81, 94, 0.6);
  font-size: 0.8em;
  font-style: italic;
  margin-bottom: 3px;
}

#search__results__sellers .search__results__seller-logo {
  border: 1px solid #d2dadc;
  margin-right: 9px;
}

#search__results {
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 0 0 5px 5px;
  display: none;
  left: 0;
  overflow: auto;
  padding-bottom: 10px;
  padding-top: 25px;
  position: absolute;
  top: 30px;
  width: 100%;
  z-index: 1029;
}
#search__results .search__results__product-description {
  color: #2b2b2b;
  font-size: 0.8em;
  line-height: 1.3em;
}
#search__results .search__results__product-image {
  width: 70px;
}
#search__results .search__results__product-image img {
  height: 50px;
}
.search-opened #search__results {
  display: block;
}
@media (max-width: 991.98px) {
  #search__results {
    height: 100%;
    padding-bottom: 115px;
    position: fixed;
    top: 40px;
  }
}

#search__results__products {
  border-right: 1px solid #d2dadc;
}

@media (max-width: 767.98px) {
  #search__results__products,
#search__results__categories {
    border-bottom: 1px solid #d2dadc;
    border-right: none;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
}

#social-connect {
  position: relative;
  z-index: 1000;
  position: fixed;
  right: 0;
  top: 50%;
}
#checkout-account-page-wrapper #social-connect, #checkout-review-page-wrapper #social-connect, #checkout-complete-page-wrapper #social-connect {
  display: none;
}
#social-connect .social-connect__button {
  border-radius: 3px 0 0 3px;
  box-shadow: 0 12px 24px 0 rgba(28, 68, 78, 0.25);
  display: block;
  height: 40px;
  margin-bottom: 5px;
  padding: 7px 10px 5px 10px;
}
#social-connect .label {
  display: inline-block;
  height: 40px;
  margin-right: 3px;
  overflow: hidden;
  transition: all 0.2s;
  vertical-align: top;
  max-width: 300px;
}
#social-connect.collapsed .label {
  margin: 0;
  max-width: 0;
}
@media (max-width: 991.98px) {
  #shop-page-wrapper #social-connect {
    bottom: 69px;
  }
  #shop-page-wrapper #social-connect #social-connect__collapse {
    display: none;
  }
  #shop-page-wrapper #social-connect .label {
    display: none;
  }
}
@media (max-width: 991.98px) and (max-width: 300px) {
  #shop-page-wrapper #social-connect {
    bottom: 115px;
  }
}
@media (max-width: 340px) {
  #social-connect #social-connect__collapse {
    display: none;
  }
  #social-connect .label {
    display: none;
  }
}

#social-connect__collapse {
  color: #23515e;
  display: block;
  margin-bottom: 6px;
  padding: 3px 0;
  text-align: right;
}
.collapsed #social-connect__collapse {
  display: none;
}

#social-connect__whatsapp {
  background: #25d366;
}

#social-connect__facebook-messenger {
  background: white;
}

.payment-methods__block {
  vertical-align: middle;
}

.payment-methods__icons {
  background: none no-repeat scroll center center;
  background-color: rgba(0, 0, 0, 0.1);
  background-size: contain;
  border-radius: 4px;
  display: inline-block;
  height: 25px;
  margin-right: 3px;
  margin-top: 5px;
  vertical-align: top;
  width: 33px;
}

.payment-methods__icon__text {
  font-size: 11px;
  padding: 5px;
  width: auto;
}

@media (max-width: 991.98px) {
  #consultify-widget .widget-container {
    bottom: 100px;
    right: 10px;
  }
  #shop-page-wrapper #consultify-widget .widget-container {
    left: 10px;
    right: auto;
  }
}
/*/
@import 'objects/vue-slider';
@import 'objects/modals';
@import 'objects/pagination';

@import 'components/info';
@import 'components/slider';
@import 'components/swiper';
@import 'components/seller-promo-block';
@import 'components/filters';
@import 'components/product-modals';

@import 'pages/shop-page';
@import 'pages/product-page';
/*/
.checkbox__label__wrapper {
  cursor: pointer;
  margin-bottom: 0;
  position: relative;
}

label.checked {
  font-weight: bold;
}

@media (max-width: 991.98px) {
  #left-sidebar {
    z-index: auto;
  }
}
#left-sidebar .card {
  border: none;
  border-bottom: 1px solid #d2dadc;
  border-radius: 0;
  margin: 0 0 1rem;
}
#left-sidebar .card-header {
  border: none;
  color: #23515e;
  font-weight: bold;
}
#left-sidebar .card-header .icon__collapsed {
  display: none;
}
#left-sidebar .card-header.collapsed .icon__expanded {
  display: none;
}
#left-sidebar .card-header.collapsed .icon__collapsed {
  display: inline-block;
}
#left-sidebar .card-body {
  padding: 0.5rem 0.8rem;
}
#left-sidebar .block-content .hidden-wrapper {
  transition: 0.1s max-height ease-in-out;
  max-height: 0;
  display: none !important;
}
#left-sidebar .block-content .limit-label {
  cursor: pointer;
  color: #23515e;
  position: relative;
}
#left-sidebar .block-content .limit-label::after {
  content: "";
  position: absolute;
  transform: rotate(-45deg) translate(-7px, 1px);
  margin-left: 15px;
  width: 9px;
  height: 9px;
  border-bottom: 2px solid #23515e;
  border-left: 2px solid #23515e;
  right: -20px;
  top: 0;
  transform-origin: center;
}
#left-sidebar .block-content [id^=limit-link]:checked ~ .hidden-wrapper {
  max-height: 10000px;
  display: block !important;
}
#left-sidebar .block-content [id^=limit-link]:checked ~ .limit-label::after {
  margin-left: 5px;
  transform: rotate(-45deg) translate(-4px, 10px);
  border-bottom: none;
  border-left: none;
  border-top: 2px solid #23515e;
  border-right: 2px solid #23515e;
}

#shop__filters__toggle {
  border-radius: 4px 0 0 4px;
  bottom: 100px;
  display: none;
  line-height: 2.5em;
  padding-left: 1em;
  position: fixed;
  right: 0;
  transition: all 0.2s;
  width: 60px;
  z-index: 1001;
}
#shop__filters__toggle.collapse {
  right: -50px;
}

#shop__filters__apply {
  border-radius: 4px;
  width: calc(100% - 30px);
  bottom: 90px;
  display: none;
  line-height: 3.2em;
  padding: 0;
  position: fixed;
  right: 15px;
  z-index: 1001;
}
#shop__filters__apply.swa {
  display: none !important;
  box-shadow: none;
  right: 80px;
  top: 10px;
  z-index: 1050;
}

#shop__filters__close {
  border-radius: 50%;
  bottom: auto;
  box-shadow: none;
  display: none;
  height: 30px;
  line-height: 30px;
  padding: 0;
  position: absolute;
  right: 6px;
  top: 47px;
  width: 30px;
  z-index: 1026;
}

#shop__filters__toggle__counter {
  background-color: #23515e;
  border-radius: 3px;
  color: white;
  font-weight: normal;
  height: 18px;
  left: 31px;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 2px;
  width: 20px;
}

.filter-block .checkbox__label {
  clear: both;
  font-size: 0.85rem;
  line-height: 1.4em;
  padding: 0.6rem 3px;
}
.filter-block .checkbox__label i {
  display: none;
}
.filter-block .checkbox__label__value {
  color: #23515e;
}
.filter-block .checkbox__label__wrapper:hover .checkbox__label {
  background-color: #f7f9f9;
}
.filter-block input[type=checkbox] {
  display: none;
}
.filter-block input[type=checkbox]:checked + .checkbox__label {
  background-color: #eff2f2;
  font-weight: normal;
}
.filter-block input[type=checkbox]:checked + .checkbox__label .checkbox__label__value {
  width: calc(100% - 60px);
}
.filter-block .checkbox__label__suffix {
  color: rgba(35, 81, 94, 0.6);
  float: right;
}
.filter-block .product__attribute__color {
  left: -3px;
  top: 3px;
}
@media (min-width: 992px) {
  .filter-block .checkbox__label__value {
    display: inline-block;
    width: calc(100% - 40px);
  }
}

.filter-block-slider .input-from {
  padding-right: 4px;
}
.filter-block-slider .input-to {
  padding-left: 4px;
}
.filter-block-slider .form-control {
  border-color: #d2dadc;
  font-size: 0.8em;
  height: 24px;
  padding: 0 0.75rem;
  text-align: center;
}
.filter-block-slider .checkbox__label__wrapper {
  display: none !important;
}
.filter-block-slider .range__wrapper {
  display: flex;
}
@media (max-width: 991.98px) {
  .filter-block-slider .input-from,
.filter-block-slider .input-to {
    margin-bottom: 6px;
  }
}

#filter-block-color input[type=checkbox] {
  display: none;
}
#filter-block-color .checkbox__label {
  padding-left: 32px;
}

#home #filter_accordion_category .card-body {
  padding-top: 5px;
}
body:not(#home) #filter_accordion_category .card-body {
  padding-top: 0;
}
#filter_accordion_category .checkbox__label__wrapper {
  border-bottom: 1px solid #d2dadc;
  margin-bottom: 0;
  padding: 0;
}
#filter_accordion_category .checkbox__label__wrapper:last-of-type {
  border-bottom: none;
}
@media (max-width: 991.98px) {
  #filter_accordion_category .card-body {
    display: none;
  }
}

.category-menu__parent {
  background-color: #f5dc7a;
  border-bottom: 1px solid #a7b7bb;
  font-weight: bold;
  margin-left: -0.8rem;
  margin-right: -0.8rem;
}
.category-menu__parent a {
  line-height: 1.4em;
  padding: 0.6rem 0.8rem;
}
.category-menu__parent:last-of-type {
  background-color: #d2dadc;
  font-weight: normal;
}
.category-menu__parent:last-of-type i {
  font-weight: normal;
}
.category-menu__parent i {
  margin-right: 3px;
}

.category-menu__parent__back {
  border-right: 1px solid #a7b7bb;
  text-align: center;
  width: 40px;
}

#main-title {
  width: 200px;
}
#main-title .title {
  margin-bottom: 0.5rem;
  text-align: center;
}
@media (max-width: 767.98px) {
  #main-title .title {
    font-size: 1.7rem;
    margin-bottom: 0;
  }
}

#arrow-graphics {
  width: 70px;
}
#arrow-graphics svg {
  display: block;
  height: 63px;
  padding-right: 10px;
}
@media (max-width: 767.98px) {
  #arrow-graphics svg {
    float: right;
    height: 45px;
    padding: 5px;
    transform: rotate(135deg);
    width: 50%;
  }
}
#arrow-graphics svg:first-of-type {
  padding-bottom: 20px;
}
@media (max-width: 767.98px) {
  #arrow-graphics svg:first-of-type {
    padding-bottom: 4px;
    transform: rotate(45deg);
  }
}
@media (max-width: 767.98px) {
  #arrow-graphics {
    margin-bottom: 10px;
  }
}

.motivational-block__header {
  border-radius: 3px;
  height: 53px;
  overflow: hidden;
  position: relative;
  z-index: 3;
}
@media (max-width: 767.98px) {
  .motivational-block__header {
    height: auto;
    min-height: 85px;
    overflow: visible;
    padding-top: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .motivational-block__header {
    min-height: 105px;
  }
}

.motivational-block__body {
  border-radius: 3px;
  left: 0;
  margin: 0px;
  position: absolute;
  top: 50px;
  z-index: 2;
}

.motivational-block__dim {
  display: none;
  background-color: #000000c9;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.2s;
  width: 100%;
  z-index: 1;
}
.motivational-block__body.collapsing + .motivational-block__dim {
  display: block;
  opacity: 0;
}
.motivational-block__body.show + .motivational-block__dim {
  display: block;
  opacity: 1;
}

.motivational-block__arrow {
  left: -80px;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  top: 12px;
  transform: rotate(230deg) scale(1.7);
  transition: all 0.2s;
  z-index: 1;
}
.secondary .motivational-block__arrow {
  top: -50px;
}
.collapsed .motivational-block__arrow {
  left: 0;
  opacity: 0;
  top: 50px;
  transform: rotate(179deg);
}

.motivational-block {
  border-radius: 3px;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  position: relative;
  z-index: 2;
}
.motivational-block .collapsing {
  transition-duration: 0.2s;
}
.motivational-block.collapsed {
  z-index: 1;
}
.motivational-block.collapsed .btn-icon {
  right: 0;
}
.motivational-block.collapsed .text {
  left: 0;
  transform: translateX(0);
}
.motivational-block .graphic {
  margin-bottom: -8px;
  overflow: hidden;
  padding-bottom: 5px;
  width: 150px;
}
.motivational-block .graphic svg {
  margin-bottom: -28px;
}
.motivational-block .show .graphic {
  overflow: visible;
}
.motivational-block .text {
  display: inline-block;
  position: relative;
  line-height: 2.3rem;
  transition: all 0.2s;
  left: 105%;
  transform: translateX(-100%);
}
@media (max-width: 767.98px) {
  .motivational-block .text {
    display: inline-block;
    font-size: 0.9rem;
    line-height: 1.1rem;
    padding-left: 10px;
    vertical-align: bottom;
    width: calc(100% - 10px);
  }
}
.motivational-block .btn-primary,
.motivational-block .btn-secondary {
  background-color: white !important;
  border: none;
  box-shadow: 0px 2px 10px 0 #1c444e40 !important;
  color: #2b2b2b !important;
  float: left;
  height: auto !important;
  line-height: 1.7em !important;
  margin-right: 10px;
  padding: 5px 10px 2px !important;
}
@media (max-width: 767.98px) {
  .motivational-block .btn-primary,
.motivational-block .btn-secondary {
    display: table;
    margin: -3px 10px 10px;
    box-shadow: none !important;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    padding-bottom: 7px !important;
    padding-top: 0 !important;
    line-height: 1.3em !important;
    width: calc(100% - 20px);
  }
  .motivational-block .btn-primary span,
.motivational-block .btn-secondary span {
    display: table-cell;
    vertical-align: middle;
  }
}
@media (max-width: 575.98px) {
  .motivational-block .btn-primary,
.motivational-block .btn-secondary {
    min-height: 50px;
  }
}
.motivational-block .btn-icon {
  border-radius: 50%;
  border: none;
  float: right;
  margin: 3px 0 0;
  padding: 0;
  position: relative;
  right: -50px;
  transition: right 0.2s;
  width: 30px;
}
.motivational-block .btn-icon,
.motivational-block .btn-icon:hover {
  background-color: white;
  height: 30px;
  line-height: 30px;
}
.motivational-block.primary {
  color: white;
  z-index: 2;
}
.motivational-block.primary .motivational-block__header {
  background-color: #23515e;
}
.motivational-block.primary .btn-primary {
  border-bottom: 3px solid #f5dc7a;
}
.motivational-block.primary .btn-icon {
  background-color: white;
  color: #23515e;
}
.motivational-block.primary .motivational-block__body {
  background-color: #23515e;
}
.motivational-block.secondary .motivational-block__header {
  background-color: #f5dc7a;
  color: #23515e;
}
.motivational-block.secondary .btn-secondary {
  border-bottom: 3px solid #23515e;
}
.motivational-block.secondary .btn-icon {
  background-color: #23515e;
  color: white;
}
.motivational-block.secondary .motivational-block__body {
  background-color: #f5dc7a;
}
@media (max-width: 767.98px) {
  .motivational-block {
    display: inline-block;
    width: 49%;
    margin-left: 1%;
  }
  .motivational-block:first-of-type {
    float: left;
    margin-left: 0;
    margin-right: 1%;
  }
}

#motivationals-wizard {
  margin-bottom: 15px;
  margin-top: 10px;
}

.home-page__title {
  padding-bottom: 10px;
  padding-top: 20px;
}
.home-page__title svg {
  height: 30px;
}
.home-page__title .title {
  display: inline-block;
  font-size: 20px;
  margin: auto 5px;
  vertical-align: middle;
}

#home-page {
  min-height: auto;
}
#home-page h1.page-title {
  display: none;
}
#home-page > .container {
  padding: 0;
}
#home-page #blog {
  padding: 10px;
}

.blog__image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  content-visibility: auto;
  display: block;
  height: 200px;
}
@media (max-width: 767.98px) {
  .blog__image {
    height: auto;
    padding: 15%;
  }
}

#special-price-banner {
  background-color: #f5dc7a;
  line-height: 21px;
}
#special-price-banner .row {
  padding: 3px;
}
#special-price-banner .navbar-brand__logo {
  display: inline-block;
  margin-top: -3px;
  padding: 0 0 1px;
  width: 36px;
}
#special-price-banner .text {
  font-size: 0.85em;
}

#close-home-promo-banner {
  position: absolute;
  right: 3px;
  top: 5px;
}